interface Props {
  fill: string;
  classname: string;
}

const TickCircle = ({ fill, classname }: Props) => {
  return (
    <svg
      className={classname}
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15 0C12.0333 0 9.13319 0.879735 6.66645 2.52796C4.19972 4.17618 2.27713 6.51886 1.14181 9.25975C0.00649926 12.0006 -0.290551 15.0166 0.288228 17.9264C0.867006 20.8361 2.29562 23.5088 4.39341 25.6066C6.49119 27.7044 9.16394 29.133 12.0737 29.7118C14.9834 30.2906 17.9994 29.9935 20.7403 28.8582C23.4812 27.7229 25.8238 25.8003 27.4721 23.3336C29.1203 20.8668 30 17.9667 30 15C29.9955 11.0231 28.4136 7.21048 25.6016 4.39842C22.7895 1.58636 18.9769 0.00454965 15 0ZM22.8105 11.4199L13.5293 20.7012C13.39 20.8405 13.2247 20.951 13.0427 21.0264C12.8607 21.1018 12.6657 21.1406 12.4687 21.1406C12.2717 21.1406 12.0767 21.1018 11.8947 21.0264C11.7127 20.951 11.5474 20.8405 11.4082 20.7012L7.18951 16.4824C6.91115 16.2006 6.75562 15.82 6.75685 15.4239C6.75809 15.0277 6.916 14.6482 7.19612 14.368C7.47623 14.0879 7.85579 13.93 8.25193 13.9287C8.64807 13.9275 9.02863 14.083 9.31051 14.3613L12.4687 17.5195L20.6895 9.29883C20.9714 9.02049 21.3519 8.86497 21.7481 8.86622C22.1442 8.86748 22.5238 9.0254 22.8039 9.30553C23.084 9.58565 23.2419 9.96522 23.2432 10.3614C23.2444 10.7575 23.0889 11.1381 22.8105 11.4199Z"
        fill={fill}
      />
    </svg>
  );
};

export default TickCircle;
