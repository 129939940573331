interface Props {
  fill: string;
}

const SortUpIcon = ({ fill }: Props) => {
  return (
    <svg
      width="13"
      height="8"
      viewBox="0 0 13 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.10886 1.32841C4.5062 0.885989 5.19952 0.885989 5.59686 1.32841L9.00081 5.11858C9.57897 5.76234 9.12209 6.78676 8.25681 6.78676H1.44891C0.583633 6.78676 0.126749 5.76234 0.70491 5.11858L4.10886 1.32841Z"
        fill={fill}
      />
    </svg>
  );
};

export default SortUpIcon;
