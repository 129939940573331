import './index.scss';
import { useNavigate, useLocation } from "react-router-dom";
import { Button, Paragraph, Card } from '@opsdti-global-component-library/amgen-design-system';
import React from 'react';

type Props = {
    title: string;
    summary: string;
    buttonLink: string;
    imageSrc: string;
    imageAlt: string;
}

export const MoreIntelligenceCard: React.FC<Props> = ({ title, summary, buttonLink, imageSrc, imageAlt}: Props) => {
    const navigate = useNavigate();
    const currentRoute = useLocation();

    return (
        <Card 
            style={{flex: 1, display: 'flex', flexDirection: 'column'}}
            title={title}
            // classNames={body: 'ext-more-intelligence-card-body'}
            footer={
                <div>
                    <div className="ext-more-intelligence-image-container">
                        <img src={imageSrc} alt={imageAlt} className='ext-more-intelligence-image' />
                    </div>
                    <div className="ext-more-intelligence-btn-container">
                        <Button
                        text={title}
                        type="secondary"
                        className="ext-page-navigation-footer-btn"
                        onClick={() => {
                            navigate(buttonLink, {
                            state: {
                                callingPath: currentRoute,
                            },
                            });
                        }}
                        />
                    </div>
                </div>
            }>
            <div className='ext-more-intelligence-card-content-container'>
                <Paragraph>{summary}</Paragraph>
            </div>
        </Card>
    );
}