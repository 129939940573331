import React, { useCallback } from 'react';
import { scaleLog } from '@visx/scale';
import Wordcloud from '@visx/wordcloud/lib/Wordcloud';
import { Popover, Paragraph, Text } from '@opsdti-global-component-library/amgen-design-system';
import { Text as WordCloudText } from '@visx/text';
import './index.scss'
import { Loader } from '@gitlab-rtsensing/component-library';

interface WordCloudProps {
 /** Whether the word cloud is loading */
 isLoading?: boolean;
 /** Whether the word cloud has an error */
 errorMessage?: string;
 /** An array of words to be displayed in the word cloud */
 words: Word[];
 /** An optional array of categories for grouping and coloring words */
 categories?: Categories;
 /** The width of the word cloud container */
 width?: number;
 /** The height of the word cloud container */
 height?: number;
 /** Whether to show the category legend */
 showLegend?: boolean;
 /** Whether to use a deterministic layout for consistent word positions */
 deterministic?: boolean;
 /** The type of spiral layout to use */
 spiralType?: 'archimedean' | 'rectangular';
 /** Whether to apply rotation to the words */
 withRotation?: boolean;
 /** The range of rotation to apply to the words */
 rotationRange?: [number, number];
 /** The font family to use for the words */
 fontFamily?: string;
 /** The range of font sizes to use for the words */
 fontSizes?: [number, number];
 /** The padding between words */
 padding?: number;
 /** Bold the wordcloud words or not */
 bold?: boolean;
 /** Callback function triggered when a word is hovered */
 onWordHover?: (word: Word, event: React.MouseEvent<SVGTextElement>) => void;
 /** Callback function triggered when a word is clicked */
 onWordClick?: (word: Word, event: React.MouseEvent<SVGTextElement>) => void;
}

export type Categories = {
    [key: string]: CategoryData
}
export type CategoryData = {
    /** The name of the category */
    name: string;
    /** The color assigned to the category */
    color: string;
    /** The color shown when another word is hovered */
    lightColor: string;
};

export type Word = {
  text: string;
  value: number;
  category: string;
  detail: string
}

export default function WordCloud({
    isLoading,
    errorMessage,
    words,
    categories,
    width = 600,
    height = 400,
    showLegend = true,
    deterministic = true,
    spiralType = 'archimedean',
    withRotation = false,
    rotationRange = [0, 0],
    fontFamily = 'Inter',
    fontSizes = [13, 67],
    padding = 2,
    onWordClick,
    onWordHover,
    bold = false,
  }: WordCloudProps) {
    const [hoveredWord, setHoveredWord] = React.useState<string | undefined>();
    const fontScale = scaleLog({
      domain: [
        Math.min(...words.map(w => w.value)),
        Math.max(...words.map(w => w.value)),
      ],
      range: fontSizes,
    });
  
    const valueScale = scaleLog({
      domain: [
        Math.min(...words.map(w => w.value)),
        Math.max(...words.map(w => w.value)),
      ],
      range: [0, 100],
    });
  
    const valueScaler = useCallback(
      (datum: Word) => valueScale(datum.value),
      [valueScale],
    );
  
    const fontSizeSetter = useCallback(
      (datum: Word) => fontScale(datum.value),
      [fontScale],
    );
  
    const getRotationDegree = useCallback(() => {
      const rand = Math.random();
      const degree = rand > 0.5 ? rotationRange[1] : rotationRange[0];
      return rand * degree;
    }, [rotationRange]);
  
    const getWordColorCallback = useCallback(
      (word: Word) => {
        if(categories !== undefined){
          if (hoveredWord === undefined)
            return categories[word.category].color;
          return hoveredWord === word.text
              ? categories[word.category].color
              : categories[word.category].lightColor
        }
      },
      [valueScaler, hoveredWord],
    );
  
    const renderLegend = useCallback(() => {
      return categories && Object.keys(categories).map((key) => {
        return (
            <div
              className="word-cloud-legend ext-my-1"
              key={categories[key].name}
            >
              <div className='word-cloud-legend-indicator' style={{ backgroundColor: categories[key].color }} />
              <div className="ops-me-2">{categories[key].name.toUpperCase()}</div>
            </div>
            ) 
        });
      }, [categories]);
  
    const handleWordClick = (
      word: Word,
      event: React.MouseEvent<SVGTextElement>,
    ) => {
      onWordClick?.(word, event);
    };
  
    const handleWordHover = (
      word: Word,
      event: React.MouseEvent<SVGTextElement>,
    ) => {
      onWordHover?.(word, event);
    };
  
    const handleMouseOver = (word: Word) => {
      setHoveredWord(word.text);
    };
  
    const handleMouseOut = () => {
      setHoveredWord(undefined);
    };

    if(isLoading) {
      return <div className="loader-container" style={{minHeight: height}}><Loader /></div>
    }
    if(errorMessage || words.length === 0) {
      return (
        <div className="error-container" style={{minHeight: height}}>
          <div>
            {errorMessage || "Earnings sentiment is still pending for this quarter."}
          </div>
          <div>
            {"Please check back soon."}
          </div>
        </div>
      )
    }
    return (
      <div onMouseOut={handleMouseOut}>
        <Wordcloud
          data-testid="word-cloud"
          words={words}
          width={width}
          height={height}
          fontSize={fontSizeSetter}
          font={fontFamily}
          padding={padding}
          spiral={spiralType}
          rotate={withRotation ? getRotationDegree : 0}
          random={deterministic ? () => 0.5 : Math.random}
        >
          {cloudWords =>
            cloudWords.map(w => (
              <Popover
                overlayStyle={{ marginBottom: '-8px'}}
                mouseEnterDelay={0}
                mouseLeaveDelay={0}
                content={
                  <Paragraph>
                    <Text strong>
                      Sentiment Insight:
                    </Text>
                      {" " + (words.find(word => word.text === w.text)?.detail || '')}
                  </Paragraph>
                }
                onOpenChange={function noRefCheck(){}}
              >
                <WordCloudText
                  key={w.text}
                  fill={getWordColorCallback(
                    words.find(word => word.text === w.text) as Word,
                  )}
                  textAnchor={'middle'}
                  transform={`translate(${w.x}, ${w.y}) rotate(${w.rotate})`}
                  fontSize={w.size}
                  fontFamily={w.font}
                  fontWeight={bold ? '600' : ''}
                  onClick={event =>
                    handleWordClick(
                      words.find(word => word.text === w.text) as Word,
                      event,
                    )
                  }
                  onMouseOver={event =>
                    handleWordHover(
                      words.find(word => word.text === w.text) as Word,
                      event,
                    )
                  }
                  onMouseEnter={() =>
                    handleMouseOver(
                      words.find(word => word.text === w.text) as Word,
                    )
                  }
                >
                  {w.text}
                </WordCloudText>
              </Popover>
            ))
          }
        </Wordcloud>
        {showLegend && categories && (
            <>
                <hr className='ext-divider-bar' />
                <div className='word-cloud-legend-container'>
                    {renderLegend()}
                </div>
            </>
        )}
      </div>
    );
}