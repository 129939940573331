import React, { useEffect, useState, useContext } from 'react';
import './index.scss';
import axios from "axios";
import { useNavigate, useLocation } from "react-router-dom";
import { BASE_ROUTE } from "../../../../utils/routing";
import { Button, Card, ThemeContext, Title, Link } from '@opsdti-global-component-library/amgen-design-system';
import { noDataMessage, apiErrorMessage } from "../../../../assets/errorMessageConstants";
import { Loader } from "@gitlab-rtsensing/component-library";
import { amgenTicker, getApiUrl } from "../../../../api/api-config";
import { getOktaToken } from "../../../../utils/commonMethods";

export const AmgenInTheNews: React.FC = () => {
    const navigate = useNavigate();
    const currentRoute = useLocation();
    const oktaToken = getOktaToken();
    const [articleList, setArticleList] = useState([]);
    const [articlesLoading, setArticlesLoading] = useState(false);
    const [newsErrorMessage, setNewsErrorMessage] = useState<string>("");
    const { theme } = useContext(ThemeContext);
    const { colorBorderSecondary } = theme?.token as any;

    const onLinkClick = (articleId: string, url?: string) => {
      if (url === "" || url === null) {
        const finalUrl = `${BASE_ROUTE}/news/${articleId}.html`;
        window.open(finalUrl);
        return;
      }
      const finalUrl = url?.replace(/^http:\/\//i, "https://");
      window.open(finalUrl);
    };

    const getArticleList = async () => {
        try {
          setArticlesLoading(true);
          const res = await axios({
            method: "get",
            url:`${getApiUrl("get-homepage-news-with-tags")}?filter_name=${amgenTicker}`,
            headers: {
              Authorization: `Bearer ${oktaToken}`,
            },
          });
    
          if (res.data.status === "SUCCESS" && res.data.data.response && res.data.data.count !== 0) {
            setArticleList(res.data.data.response);
          } else {
            setArticleList([]);
            setNewsErrorMessage(noDataMessage);
          }
          setArticlesLoading(false);
        } catch (error) {
          setArticleList([]);
          setArticlesLoading(false);
          setNewsErrorMessage(apiErrorMessage);
        }
      };

    useEffect(() => {
      getArticleList();
    }, []);

    return (
        <div className="ext-amgen-news">
            <div className="ext-amgen-news-container">
                <Card title="Amgen in the News">
                <div className="ext-article-list-wrapper ext-card-height ext-border-top-0">
                    {articlesLoading && <Loader />}
                    {!articlesLoading && newsErrorMessage && (
                    <div className="ext-empty-chart-msg">{noDataMessage}</div>
                    )}
                    {articleList && articleList.length > 0 && (
                        <ul style={{borderBottom: `1px solid ${colorBorderSecondary}`, paddingBottom: '31px'}}>
                            {articleList.map((article: any, index: number) => (
                                <div key={index} className="ext-article-item">
                                    <div className="ext-badge-title-container">
                                        •
                                        <div onClick={() => onLinkClick(article.article_id, article.url)}>
                                          <Title className='ext-article-title' level={3}>
                                            <Link className="gpt-news-list-title" type="secondary">
                                                {article.title}
                                            </Link>
                                          </Title>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </ul>
                    )}
                </div>
                <div className="ext-row ext-page-navigation-wrapper">
                    <Button
                        text="VIEW MORE NEWS ARTICLES"
                        type="secondary"
                        style={{ maxWidth: "312px"}}
                        onClick={() => {
                            navigate(`${BASE_ROUTE}/news-search`, {
                                state: {
                                    callingPath: currentRoute.pathname
                                },
                            });
                        }}
                    />
                </div>
                </Card>
            </div>
        </div>
    )
}