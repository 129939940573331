import React from "react";
import "./index.scss";
import axios from "axios";

// API
import { getApiUrl } from "../../api/api-config";

// Assets
import CrossIcon from "../../assets/icons/cross-icon";
import TrashIcon from "../../assets/icons/trash-icon";
import {
  apiErrorMessage,
  noDataMessage,
} from "../../assets/errorMessageConstants";

// Components
import { Button, Loader } from "@gitlab-rtsensing/component-library";
import EarningsDropdown from "../../components/earnings-dropdown";
import { TimeScaleData } from "../../api/get-year-and-quarter-list-dt";

// Library
import Multiselect from "multiselect-react-dropdown";

// Utils
import { getOktaToken } from "../../utils/commonMethods";
import { CustomModal } from "../modal";

type EditNoteModalProps = {
  isOpen: boolean;
  setClosedTagsModalList?: any;
  selectedNoteType?: string;
  notesCategory?: any;
  notesCategoryLoader?: boolean;
  notesCategoryListError?: string;
  resetNotesCategories?: any;
  noteItemForEdit?: any;
  setIsOpenDeleteModal?: any;
  userSelectedNote?: any;
  setUserSelectedNote?: any;
  timeScaleData: TimeScaleData
};

function EditNoteModal(props: EditNoteModalProps) {
  const {
    isOpen,
    setClosedTagsModalList,
    notesCategory,
    notesCategoryLoader,
    notesCategoryListError,
    resetNotesCategories,
    noteItemForEdit,
    setIsOpenDeleteModal,
    timeScaleData   
  } = props;
    const productTagSelectedValue:any = [];
    const topicTagSelectedValue:any = [];
    const companyTagSelectedValue:any = [];
    const companyDefaultSelectedValues: any = [];

    // List data for notes cateories
    const [topicList, setTopicList]: any = React.useState(topicTagSelectedValue);
    const [companyList, setCompanyList]: any = React.useState(companyTagSelectedValue);
    const [productList, setProductList]: any = React.useState(productTagSelectedValue);

    // Loader and error for add note
    const [loaderOnAddNote, setLoaderOnAddNote]: any = React.useState(false);
    const [errorOnAddNote, setErrorOnAddNote]: any = React.useState("");

    // Load year and quarter values for selected note
    const [editYear, setEditYear] = React.useState<string>(noteItemForEdit.year);
    const [editQuarter, setEditQuarter] = React.useState<string>("Q" + noteItemForEdit.quarter);

    // Validation Check
    const [validationError, setValidationError]: any = React.useState("");
    
    const oktaToken = getOktaToken();

    // Product Tag
    const noteItemForEditProductTag = noteItemForEdit.product_tag;
    noteItemForEditProductTag.map((item:any) => {
      item = item.replace(/['"]+/g, '');
      return item;
    })
    noteItemForEditProductTag.map((item:any) => {
      const formattedString = item.replace(/[{}""]/g, '');
      const capitalizedString = formattedString.charAt(0).toUpperCase() + formattedString.slice(1);
      const object = {id: item.replace(/[{}""]/g, ''), name: capitalizedString};
      return productTagSelectedValue.push(object);
    })

    // Topic Tag
    const noteItemForEditTopicTag = noteItemForEdit.association_tag;
    noteItemForEditTopicTag.map((item:any) => {
      item = item.replace(/['"]+/g, '');
      return item;
    })
    noteItemForEditTopicTag.map((item:any) => {
      const formattedString = item.replace(/[{}""]/g, '');
      const capitalizedString = formattedString.charAt(0).toUpperCase() + formattedString.slice(1);
      const object = {id: item.replace(/[{}""]/g, ''), name: capitalizedString.replace("_", " ")};        
      return topicTagSelectedValue.push(object);
    });

    // Company Tag
    const noteItemForEditCompanyTag = noteItemForEdit.company_tag;
    noteItemForEditCompanyTag.map((item:any) => {
      item = item.replace(/[{}""]/g, '');
      notesCategory.map((note:any) => {
        let companyName = "";
        if(note.category_name === "company") {
          note.values.map((val:any) => {
            if(val.id === item) {
              companyName = val.name
            }
          })
          const object = {id: item, name: companyName};
          companyTagSelectedValue.push(object);
          companyDefaultSelectedValues.push(object);
        }
      })
    }
    )

  // Note type tag
  const noteItemForEditNoteTypeTag = noteItemForEdit.note_category;
  // Notes Text
  const noteItemForEditNotesText = noteItemForEdit.note_details;
  const [associationList, setAssociationList]: any = React.useState(noteItemForEditNoteTypeTag);
  const [notesText, setNotesText]: any = React.useState(noteItemForEditNotesText);

  const getNoteType = (category_name: string) => {
    if (category_name === "company") {
      category_name = "COMPANIES";
    } else if (category_name === "product") {
      category_name = "PRODUCTS";
    } else if (category_name === "topic") {
      category_name = "ASSOCIATIONS";
    } else if (category_name === "insights_categories") {
      category_name = "NOTE TYPE";
    }
    return category_name;
  };

  const onSelectCategoryItem = (selectedValue: any, categoryName:string) => {
    setValidationError("");
    if(categoryName === "company") {
      setCompanyList(selectedValue);
    }
    if(categoryName === "product") {
      setProductList(selectedValue);
    }
    if(categoryName === "topic") {
      setTopicList(selectedValue);
    }
    if(categoryName === "insights_categories") {
      setAssociationList(selectedValue[0].id);
    }
  };

  const onSaveChangesBtnClick = async() => {
    setLoaderOnAddNote(true);
      
    const productPayload = productList.map((item: any) => item.id);
    const topicPayload = topicList.map((item: any) => item.id);
    const companyPayload = companyList.map((item: any) => item.id);
    
    const payload = {
      id: noteItemForEdit.note_id,
      note_details: notesText,
      note_category: associationList,
      company_tag: companyPayload,
      product_tag: productPayload,
      association_tag: topicPayload,
      quarter: editQuarter[1],
      year: editYear
    };

    // Validation Check
    if(companyList.length === 0 || notesText.length === 0 || associationList.length === 0) {
      setValidationError("Please select all required fields");
    }else{      
      try {
        const res:any = await axios({
          method: "post",
          url: getApiUrl("earnings-update-notes"),
          data: payload,
          headers: {
            Authorization: `Bearer ${oktaToken}`,
          },
        });
        if(res && res?.data && res?.status === 200) {
          setLoaderOnAddNote(false);
          resetNotesCategories();
        }else {
          setErrorOnAddNote(noDataMessage);
        }
        onCloseModal();
      } catch (error) {
        setLoaderOnAddNote(false);
        setErrorOnAddNote(apiErrorMessage);
      }
      setLoaderOnAddNote(false);
    }
    setLoaderOnAddNote(false);
 
  };

  const onCloseModal = () => {
    setClosedTagsModalList(false);
    resetValues();
  }

  // Reset Values
  const resetValues = () => {
    setCompanyList([]);
    setProductList([]);
    setTopicList([]);
    setAssociationList("");
    setNotesText("");
    setValidationError("");
  }

   return (
    <>
      <CustomModal opened={isOpen} onClose={() => onCloseModal()}>
        <CustomModal.Header contentClassName="d-flex align-items-center">
          <CustomModal.Title
            title="Edit Earnings Insight"
          />
        </CustomModal.Header>
        {loaderOnAddNote ? (
          <div className="ext-add-notes-loader">
            <Loader />
          </div>
        ) : errorOnAddNote ? (
          <div className="ext-add-notes-error">{errorOnAddNote}</div>
        ) : (
          <div className="ext-add-edit-note-modal">
            {notesCategoryListError ? (
              <div className="ext-notes-category-error">
                {notesCategoryListError}
              </div>
            ) : (
              <>
                {notesCategoryLoader ? (
                  <div className="ext-insight-curation-loader">
                    <Loader />
                  </div>
                ) : (
                  <div className="ext-add-note-modal-responsive">
                    <div className="ext-add-note-modal-container">
                      {notesCategory.length > 0 &&
                        notesCategory.map((item: any) => {
                          return (
                            <div className="ops-mt-4">
                              <span className="ext-note-label">
                                {getNoteType(item?.category_name)}
                                {item?.category_name === "topic" ||
                                item?.category_name === "product" ? (
                                  <></>
                                ) : (
                                  <span className="ext-note-required">*</span>
                                )}
                              </span>
                              <Multiselect
                                isObject={true}
                                onKeyPressFn={function noRefCheck() {}}
                                onRemove={(selectedItem) =>
                                  onSelectCategoryItem(
                                    selectedItem,
                                    item.category_name
                                  )
                                }
                                onSearch={function noRefCheck() {}}
                                onSelect={(selectedItem) =>
                                  onSelectCategoryItem(
                                    selectedItem,
                                    item.category_name
                                  )
                                }
                                singleSelect={
                                  item.category_name === "insights_categories"
                                    ? true
                                    : false
                                }
                                options={item?.values}
                                selectedValues={
                                  item?.category_name === "product" 
                                  ?
                                  productList
                                  :
                                  item?.category_name === "topic"
                                  ?
                                  topicList
                                  :
                                  item?.category_name === "insights_categories"
                                  ?
                                  [{id:associationList, name:associationList}]
                                  :
                                   item && item.category_name && item.category_name === "company"
                                  ?
                                  companyDefaultSelectedValues
                                  :
                                  []}
                                placeholder="Select"
                                showArrow={true}
                                style={{
                                  chips: {
                                    background: "#E3E3E3",
                                    borderRadius: "6px",
                                    color: "black",
                                    fontSize: "12px",
                                    fontWeight: 500,
                                  },
                                }}
                                customCloseIcon={
                                  <CrossIcon
                                    fill="#585858"
                                    classname="custom-close-icon"
                                  />
                                }
                                hidePlaceholder={true}
                                displayValue="name"
                                avoidHighlightFirstOption={true}
                              />
                            </div>
                          );
                        })}
                      <div className="ops-mt-4 d-flex">
                        <EarningsDropdown
                          label="YEAR"
                          options={Object.keys(timeScaleData).reverse()}
                          selectedValue={editYear}
                          className="ext-col"
                          onMenuItemClick={(event)=> {
                            setEditYear(event?.key)
                            setEditQuarter(timeScaleData[event?.key][0])

            
                          }}

                        />
                          <EarningsDropdown
                          label="TIMEFRAME"
                          options={timeScaleData[editYear]}
                          selectedValue={editQuarter}
                          className="ext-col"
                          onMenuItemClick={ (event)=> {
                            setEditQuarter(event?.key)

                            }}

                        />
                      </div>                
                <div className="ops-mt-4">
                  <span className="ext-note-label">
                    NOTE<span className="ext-note-required">*</span>
                  </span>
                  <div>
                    <textarea
                      id="note"
                      name="note"
                      rows={4}
                      className="ext-note-textarea"
                      value={notesText}
                      onChange={(e) => setNotesText(e.target.value)}
                      ></textarea>
                  </div>
                </div>
                </div>
                {/* <div
                    className="ops-mt-4"
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <input
                      type="checkbox"
                      id="ext-note-checkbox"
                      name="ext-note-checkbox"
                      value="ext-note-checkbox"
                      style={{ height: "16px", width: "16px" }}
                      // onClick={() => setAddAnotherNote(!addAnotherNote)}
                    />
                    <span className="ext-add-another-note-label">
                      Add another note with the same tags
                    </span>
                </div> */}
                {
                  validationError && validationError.length 
                  ?
                  <span className="ext-fs-12 ext-validation-error-msg">{validationError}</span> 
                  :
                  <></>}
              <div className="ext-add-note-modal-footer ops-mt-5">
                <Button
                  label="DELETE ITEM"
                  type="secondary"
                  className="ext-delete-btn"
                  icon={<TrashIcon />}
                  iconPosition="right"
                  onClick={() => {
                    setClosedTagsModalList(false);
                    setIsOpenDeleteModal(true);
                  }}
                />
                <Button
                  label="SAVE CHANGES"
                  type="secondary"
                  className="ext-add-note-btn"
                  onClick={() => onSaveChangesBtnClick()}
                />
              </div>
              {/* :
              <div className="ext-notes-category-error">{notesCategoryListError}</div>} */}
                  </div>
                )}
              </>
            )}
          </div>
        )}
      </CustomModal>
    </>
  );
}

export default EditNoteModal;
