interface Props {
  fill: string;
}

const RightIcon = ({ fill }: Props) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 0C4.5 0 0 4.5 0 10C0 15.5 4.5 20 10 20C15.5 20 20 15.5 20 10C20 4.5 15.5 0 10 0ZM13.7 10.7L10.7 13.7C10.3 14.1 9.7 14.1 9.3 13.7C8.9 13.3 8.9 12.7 9.3 12.3L10.6 11H7C6.4 11 6 10.6 6 10C6 9.4 6.4 9 7 9H10.6L9.3 7.7C8.9 7.3 8.9 6.7 9.3 6.3C9.7 5.9 10.3 5.9 10.7 6.3L13.7 9.3C14.1 9.7 14.1 10.3 13.7 10.7Z"
        fill={fill}
      />
    </svg>
  );
};

export default RightIcon;
