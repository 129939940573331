interface ApiMethods {
  [key: string]: string;
}

const methods: ApiMethods = {
  "get-companies": "company/get_company_data",
  "get-peer-big-sheet": "peer_big_sheet/peer_big_sheet_data",
  "download-peer-big-sheet": "peer_big_sheet/peer_big_sheet_excel",
  "get-company-detail": "company/company_profile_data",
  "get-company-list": "company/company_mapping",
  "get-company-graph": "company/get_company_graph",
  "get-comparative-pe":"company/get_comparative_pe_ratio",
  "get-colection-list": "collection/collection_names",
  "get-collections-article": "collection/get_articles_by_collection",
  "get-update-collection": "collection/update_collection_articles",
  "get-create-collection": "collection/create_collection",
  "get-remove-article": "collection/remove_article",
  "get-topic-list": "topic/topic_list",
  "get-topic-news": "news/news_articles_with_tags",
  "get-news-with-tags": "news/news_articles_with_tags",
  "get-homepage-news-with-tags": "news/homepage_news_articles_with_tags",
  "product-list": "product/get_product_mapping",
  "get-apoc-filters": "apoc/get_apoc_filters",
  "get-apoc-news": "apoc/apoc_articles_with_tags",
  "get-homepage-apoc-news": "apoc/homepage_apoc_articles_with_tags",
  "get-product-details": "product/product_profile_data",
  "delete-collection": "collection/delete_collection",
  "get-company-list-graph": "trending/trending_companies",
  "world-cloud": "trending/wordcloud_data",
  "get-product-list-graph": "trending/trending_products",
  "get-amgen-in-the-news": "trending/weekly_article_summary_amgn",
  "get-kiq-list": "kiqtags/kiqtags_list",
  "dow-jones": "news/get_news_details?article_id=",
  "get-published-list": "news/publisher_mappings",
  "get-amgen-company-list": "avcompany/av_company_list",
  "qna-question": "qna/get_qna_result",
  "unified-qna-question": "qna/get_unified_qna_result",
  "qna-question-feedback": "feedback",
  "get-kiq-builder-list": "kiqbuilder/kiq_queries",
  "get-quired-news-article": "kiqbuilder/get_tagged_news_articles",
  "get-all-tags": "kiqbuilder/get_all_tags",
  "save-kiq-query": "kiqbuilder/create_kiq_query",
  "edit-kiq-query": "kiqbuilder/update_kiq_query",
  "delete-kiq-query": "kiqbuilder/delete_kiq_query",
  "create_insights_log": "collection/post_insights_curations",
  "get-earnings-word-cloud": "earnings/get_word_cloud",
  "get-earning-table-data": "earnings/get_earnings_tabular_metrics",
  "get-homepage-earnings-table-data": "earnings/homepage_earnings_tabular_metrics",
  "get-earnings-comparison-dropdown": "earnings/earnings_comparison_dropdown",
  "get-earnings-comparison-values": "earnings/earnings_comparison_values",
  "get-user-notes": "earnings/get_user_notes",
  "get-notes-categories": "earnings/get_notes_categories",
  "earnings-create-notes": "earnings/create_notes",
  "earnings-update-notes": "earnings/update_notes",
  "get-key-insights": "earnings/get_key_insights",
  "get-breakdown-summary": "earnings/get_breakdown_summary",
  "earning-delete-notes": "earnings/delete_note",
  "time-scale": "earnings/get_year_and_quarter_list",
  "get-all-insights": "earnings/get_all_insights",
  "get-financial-editable-metrics" : "earnings/get_financial_editable_metrics",
  "edit-financial-summary-metrics" : "earnings/edit_financial_summary_metrics",
  "knowledge-graph": "experimental_topic/knowledge_graph_url",
  "get-filters": "news/get_filters",
  "get-company-sentiment": "news/company_sentiment",
};

export const getApiUrl = (url: string) => {
  const method = methods[url];
  let finalURL = process.env.REACT_APP_API_LINK;
  return `${finalURL}\\${method}`;
};

export const getQnaApiUrl = (url: string) => {
  const method = methods[url];
  const finalURL = process.env.REACT_APP_QNA_API_LINK;
  return `${finalURL}\\${method}`;
};

export const amgenTicker = "AMGN";
export const amgenId = "01FKKDQPNCPKZ6K697FWCMF65W";
