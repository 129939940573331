/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable import/no-unresolved */
import "./index.scss";
import { apiResponse } from "../../utils/commonMethods";
import parse from 'html-react-parser';
import { useEffect, useState } from "react";
import ErrorMessage from "../error-message";
import { Loader } from "@gitlab-rtsensing/component-library";
import {
  apiErrorMessage,
  noDataMessage,
} from "../../assets/errorMessageConstants";
import { AxiosResponse } from "axios";
import { BASE_ROUTE } from '../../utils/routing'

const DowJonesComponent = () => {
  const [newsData, setNewsData] = useState<any>();
  const [isLoading, setIsLoading] = useState<boolean>();
  const [errorMessage, setErrorMessage] = useState<string>();

  const closeWindow = () => {  //possible future functionality
    // function to close window
    
    // To close the opened window when redirected from nws-page
    window.close();

    // To redirect to news page when individual article is opened
    let newsPage: string = `${window.location.origin}/${BASE_ROUTE}/news-search`
    let new_window:any = window.open(newsPage, '_self');
    new_window.close();
  };

  const handleErrorMsg = (res: AxiosResponse<any, any>) => {
    if (res.data.data.length !== 0) {
      setNewsData(res.data.data[0]);
    } else {
      setErrorMessage(noDataMessage);
    }
  };

  const loadTopicDetails = async (article_id: string) => {
    setNewsData([]);
    setIsLoading(true);
    setErrorMessage("");

    const articleArr = [article_id];
    const payload = {};

    try {
      const res = await apiResponse("get", "dow-jones", articleArr, payload);
      if (res.data.status === "SUCCESS") {
        if(res.data.data[0].article_url){
          window.open(res.data.data[0].article_url,'_self');
          return;
        }
        handleErrorMsg(res);
      }
      setIsLoading(false);
    } catch (error) {
      setNewsData([]);
      setIsLoading(false);
      setErrorMessage(apiErrorMessage);
    }
  };
  useEffect(() => {
    setNewsData([]);
    setIsLoading(true);
    const params = window.location.pathname.split("news/");
    const params2 = params[1].split(".");
    loadTopicDetails(params2[0]);
  }, []);

  const copyrightYear = (text: string | undefined) => {
    let textSplit = text?.split(",");
    let textSplitYear: string[];
    let finalYear: string;
    if (textSplit) {
      textSplitYear = textSplit[1].split("*");
      finalYear = textSplitYear[0].trim();
      return finalYear;
    }
  };

  const modifiedArticleContent=(data:string)=>{

    const finalData = data && data.replace(new RegExp('\r?\n','g'), '<br>');
    return finalData && <p>{parse(finalData)}</p>
  }

  return (
    <div className="ext-dow-jones-component">
      {!isLoading && !errorMessage && (
        <div>
          <img
            src={require("../../assets/images/dow-jones-factiva-logo.jpg")}
            alt="dow-jones-logo"
          />
          <div className="ext-dow-jones-date">
            {newsData?.article_published}
          </div>
          <div className="ext-dow-jones-title">{newsData?.article_title}</div>
          <div className="ext-dow-jones-source">
            By{" "}
            <span className="ext-fw-bold">
              {newsData?.article_author.toUpperCase()}{" "}
            </span>
            |{" "}
            <span className="ext-fw-bold">
              {newsData?.article_source_name.toUpperCase()}
            </span>
          </div>
          <div className="ext-dow-jones-content">{modifiedArticleContent(newsData?.article_content)}</div>
          <div className="ext-dow-jones-footer">
            Copyright ©
            {newsData?.article_published &&
              copyrightYear(newsData?.article_published)}{" "}
            {newsData?.article_source_name} All rights reserved.
          </div>
          <div className="ext-dow-jones-button" onClick={closeWindow}>
            CLOSE WINDOW
          </div>
        </div>
      )}
      {!isLoading && errorMessage && (
        <ErrorMessage isIcon={false} message={errorMessage} />
      )}
      {isLoading && (
        <div
          className="ext-spinner-dow-jones-style ext-d-flex ext-justify-content-center"
          role="status"
        >
          <Loader />
        </div>
      )}
    </div>
  );
};

export default DowJonesComponent;
