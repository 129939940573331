/* eslint-disable react/react-in-jsx-scope */
import "./index.scss";

// import ExternalModal from "../external-custom-modal";
// import { CloseIcon } from "../../assets/icons/close-icon";
import { Modal } from "@gitlab-rtsensing/component-library";

interface Props {
  show: boolean ;
  onHideDeleteModal: (modalState: boolean) => void;
  onDeleteClick: (id: string) => void;
  collectionId: string;
  collectionName: string;
  collectionTitle: number;
}

const DeleteCollectionModal = ({
  show,
  onHideDeleteModal,
  onDeleteClick,
  collectionId,
  collectionName,
  collectionTitle,
}: Props) => {
  return (

    <Modal
    opened={show}
    onClose={() => {onHideDeleteModal(!show);}}>
      <Modal.Header contentClassName="d-flex align-items-center">
          <Modal.Title title="Delete Article Collection" />
        </Modal.Header>

        <>
        <div>
        {collectionTitle === 0 ? (
          <div className="ext-modal-body-div">
            Are you sure you want to delete this article collection{" "}
            <b>{collectionName}</b>?
          </div>
        ) : (
          <div className="ext-modal-body-div">
            There are no more articles in the Article Collection{" "}
            <b>{collectionName}</b>. Would you like to delete this Article
            Collection?
          </div>
        )}
        
        </div>

        <div className="ext-footer-section ext-d-flex ext-ms-auto">
           <div>
             {collectionTitle === 0 ? (
              <button
                type="button"
                className="ext-cancel-button"
                onClick={() => {
                  onHideDeleteModal(!show);
                }}
              >
                Cancel
              </button>
            ) : (
              <button
                type="button"
                className="ext-cancel-button"
                onClick={() => {
                  onHideDeleteModal(!show);
                }}
              >
                Keep Collection
              </button>
            )}

            <button
              type="button"
              className="ext-delete-button ops-ms-2 border-0"
              onClick={() => {
                onDeleteClick(collectionId);
                onHideDeleteModal(!show);
              }}
            >
              Delete Collection
            </button>
          </div>
        </div>
        </>
    </Modal>

  );
};

export default DeleteCollectionModal;
