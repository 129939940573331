export const BASE_ROUTE = "/intelligence";

export const getBackButtonText = (location: any, defaultText = "") => {
    if (location.state === null) {
      return defaultText;
    }
    const pathName = location.state.callingPath
      ? location.state.callingPath.pathname
      : "";
    if (!pathName) {
      return defaultText;
    }
    let buttonText = "";
    if (pathName.includes)
      if (pathName === `${BASE_ROUTE}`) {
        buttonText = "BACK TO TRENDING";
      } else if (pathName === `${BASE_ROUTE}/trending`) {
        buttonText = "BACK TO TRENDING";
      } else if (pathName === `${BASE_ROUTE}/companies`) {
        buttonText = "BACK TO COMPANIES";
      } else if (pathName.includes(`${BASE_ROUTE}/companies/`)) {
        buttonText = "BACK TO COMPANIES";
      } else if (pathName === `${BASE_ROUTE}/products`) {
        buttonText = "BACK TO PRODUCTS";
      } else if (pathName.includes(`${BASE_ROUTE}/products/`)) {
        buttonText = "BACK TO PRODUCTS";
      } else if (pathName === `${BASE_ROUTE}/topics`) {
        buttonText = "BACK TO TOPICS";
      } else if (pathName.includes(`${BASE_ROUTE}/topics/`)) {
        buttonText = "BACK TO TOPICS";
      } else if (pathName === `${BASE_ROUTE}/news-search`) {
        buttonText = "BACK TO NEWS";
      } else if (pathName === `${BASE_ROUTE}/news-curator`) {
        buttonText = "BACK TO NEWS CURATOR";
      } else if (pathName === `${BASE_ROUTE}/corporate-strategy`) {
        buttonText = "BACK TO KIQS";
      } else if (pathName.includes(`${BASE_ROUTE}/corporate-strategy/`)) {
        buttonText = "BACK TO KIQS";
      } else if (pathName === `${BASE_ROUTE}/amgen-ventures`) {
        buttonText = "BACK TO AMGEN VENTURE"
      } else if (pathName.includes(`${BASE_ROUTE}/amgen-ventures/`)) {
        buttonText = "BACK TO AMGEN VENTURE"
      } else if (pathName === `${BASE_ROUTE}/experimental`) {
        buttonText = "BACK TO EXPERIMENTAL PAGE"
      } else if (pathName.includes(`${BASE_ROUTE}/experimental/`)) {
        buttonText = "BACK TO EXPERIMENTAL PAGE"
      } else if (pathName === `${BASE_ROUTE}/builder`) {
        buttonText = "BACK TO KIQ BUILDER PAGE"
      } else if (pathName.includes(`${BASE_ROUTE}/builder/`)) {
        buttonText = "BACK TO KIQ BUILDER PAGE"
      }
    return buttonText;
  };