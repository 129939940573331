interface Props {
  fill: string;
  classname?: string;
}

const Calender = ({ fill, classname }: Props) => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.6666 1.66659H10.3333V0.999919C10.3333 0.823108 10.2631 0.653538 10.1381 0.528514C10.013 0.40349 9.84346 0.333252 9.66665 0.333252C9.48984 0.333252 9.32027 0.40349 9.19524 0.528514C9.07022 0.653538 8.99998 0.823108 8.99998 0.999919V1.66659H4.99998V0.999919C4.99998 0.823108 4.92974 0.653538 4.80472 0.528514C4.67969 0.40349 4.51012 0.333252 4.33331 0.333252C4.1565 0.333252 3.98693 0.40349 3.86191 0.528514C3.73688 0.653538 3.66665 0.823108 3.66665 0.999919V1.66659H2.33331C1.80288 1.66659 1.29417 1.8773 0.9191 2.25237C0.544027 2.62744 0.333313 3.13615 0.333313 3.66659V11.6666C0.333313 12.197 0.544027 12.7057 0.9191 13.0808C1.29417 13.4559 1.80288 13.6666 2.33331 13.6666H11.6666C12.1971 13.6666 12.7058 13.4559 13.0809 13.0808C13.4559 12.7057 13.6666 12.197 13.6666 11.6666V3.66659C13.6666 3.13615 13.4559 2.62744 13.0809 2.25237C12.7058 1.8773 12.1971 1.66659 11.6666 1.66659ZM12.3333 11.6666C12.3333 11.8434 12.2631 12.013 12.1381 12.138C12.013 12.263 11.8435 12.3333 11.6666 12.3333H2.33331C2.1565 12.3333 1.98693 12.263 1.86191 12.138C1.73688 12.013 1.66665 11.8434 1.66665 11.6666V6.99992H12.3333V11.6666ZM12.3333 5.66659H1.66665V3.66659C1.66665 3.48977 1.73688 3.32021 1.86191 3.19518C1.98693 3.07016 2.1565 2.99992 2.33331 2.99992H3.66665V3.66659C3.66665 3.8434 3.73688 4.01297 3.86191 4.13799C3.98693 4.26301 4.1565 4.33325 4.33331 4.33325C4.51012 4.33325 4.67969 4.26301 4.80472 4.13799C4.92974 4.01297 4.99998 3.8434 4.99998 3.66659V2.99992H8.99998V3.66659C8.99998 3.8434 9.07022 4.01297 9.19524 4.13799C9.32027 4.26301 9.48984 4.33325 9.66665 4.33325C9.84346 4.33325 10.013 4.26301 10.1381 4.13799C10.2631 4.01297 10.3333 3.8434 10.3333 3.66659V2.99992H11.6666C11.8435 2.99992 12.013 3.07016 12.1381 3.19518C12.2631 3.32021 12.3333 3.48977 12.3333 3.66659V5.66659Z"
        fill="#585858"
        className={classname}
      />
    </svg>
  );
};

export default Calender;
