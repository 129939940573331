interface Props {
  classname?: string;
  fill: string;
}
const CrossIcon = ({ classname, fill }: Props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 48 48"
      width="20px"
      height="20px"
    >
      <path
        fill={fill}
        d="M21.5 4.5H26.501V43.5H21.5z"
        transform="rotate(45.001 24 24)"
      />
      <path
        fill={fill}
        d="M21.5 4.5H26.5V43.501H21.5z"
        transform="rotate(135.008 24 24)"
      />
    </svg>
  );
};

export default CrossIcon;
