function TrashIcon() {
  return (
    <svg
      width="15"
      height="14"
      viewBox="0 0 15 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.1667 3.4974H9.83333V2.91406C9.83333 2.44993 9.64896 2.00481 9.32077 1.67663C8.99258 1.34844 8.54746 1.16406 8.08333 1.16406H6.91667C6.45254 1.16406 6.00742 1.34844 5.67923 1.67663C5.35104 2.00481 5.16667 2.44993 5.16667 2.91406V3.4974H2.83333C2.67862 3.4974 2.53025 3.55885 2.42085 3.66825C2.31146 3.77765 2.25 3.92602 2.25 4.08073C2.25 4.23544 2.31146 4.38381 2.42085 4.49321C2.53025 4.6026 2.67862 4.66406 2.83333 4.66406H3.41667V11.0807C3.41667 11.5449 3.60104 11.99 3.92923 12.3182C4.25742 12.6464 4.70254 12.8307 5.16667 12.8307H9.83333C10.2975 12.8307 10.7426 12.6464 11.0708 12.3182C11.399 11.99 11.5833 11.5449 11.5833 11.0807V4.66406H12.1667C12.3214 4.66406 12.4697 4.6026 12.5791 4.49321C12.6885 4.38381 12.75 4.23544 12.75 4.08073C12.75 3.92602 12.6885 3.77765 12.5791 3.66825C12.4697 3.55885 12.3214 3.4974 12.1667 3.4974ZM6.33333 2.91406C6.33333 2.75935 6.39479 2.61098 6.50419 2.50158C6.61358 2.39219 6.76196 2.33073 6.91667 2.33073H8.08333C8.23804 2.33073 8.38642 2.39219 8.49581 2.50158C8.60521 2.61098 8.66667 2.75935 8.66667 2.91406V3.4974H6.33333V2.91406ZM10.4167 11.0807C10.4167 11.2354 10.3552 11.3838 10.2458 11.4932C10.1364 11.6026 9.98804 11.6641 9.83333 11.6641H5.16667C5.01196 11.6641 4.86358 11.6026 4.75419 11.4932C4.64479 11.3838 4.58333 11.2354 4.58333 11.0807V4.66406H10.4167V11.0807Z"
        fill="#D62728"
      />
    </svg>
  );
}

export default TrashIcon;
