/* eslint-disable import/no-unresolved */
/* eslint-disable react/react-in-jsx-scope */
import { useState, useEffect } from "react";
import { LoginCallback, Security } from "@okta/okta-react";
import { Route, Routes, useNavigate, Navigate } from "react-router-dom";
import PageHeader from "../page-header";
import { OktaAuth, toRelativeUrl } from "@okta/okta-auth-js";
import { RequiredAuth } from "../secure-route";
import Topics from "../../pages/topics";
import Oncology from "../../pages/oncology"
import Products from "../../pages/products";
import NewsCurator from "../../pages/news-curator";
import News from "../../pages/news";
import CompanyProfile from "../../pages/company-profile";
import PeerBigSheet from "../../pages/peer-big-sheet";
import KIQ from "../../pages/kiq";
import KIQBUILDER from "../../pages/builder";
import HomePage from "../../pages/home-page";
import EarningPage from "../../pages/earning-page";
import Companies from "../../pages/companies";
import { AppContext } from "../../utils/app-context";
import UnauthorizedPage from "../../pages/unauthorize-page";
import axios from "axios";
import { getApiUrl } from "../../api/api-config";
import { getOktaToken } from "../../utils/commonMethods";
import {
  apiErrorMessage,
  noDataMessage,
} from "../../assets/errorMessageConstants";
import DowJonesComponent from "../dow-jones-factiva";
import { Loader } from "@gitlab-rtsensing/component-library";
import Experimental from "../../pages/experimental";
import AmgenVenture from "../../pages/amgen-venture";
import { ThemeProvider } from '@opsdti-global-component-library/amgen-design-system';
import { BASE_ROUTE } from "../../utils/routing";
import Layout from "../layout";

export const ExternalRoutes = (props: any) => {
  const navigate = useNavigate();
  const oktaToken = getOktaToken();
  const [kiqListData, setKiqListData] = useState([]);
  const [kiqBuilderListData, setKiqBuilderListData] = useState([]);
  const [topicsListData, setTopicsListData] = useState([]);
  const [productListData, setProductListData] = useState([]);
  const [getTimeSeriesData, setGetTimeSeriesData] = useState();
  const [ventureCompanyList, setVentureCompanyList] = useState([]);
  const [topicsLoading, isTopicsLoading] = useState(true);
  const [kiqLoading, isKiqLoading] = useState(true);
  const [kiqBuilderLoading, isKiqBuilderLoading] = useState(true);
  const [amgenCompanyLoading, setAmgenCompanyLoading] = useState(true);
  const [productLoading, isProductLoading] = useState(true);
  const [timeScaleLoading, isTimeScaleLoading] = useState(true);
  const [kiqListErrorMessage, isKiqListErrorMessage] = useState("");
  const [kiqBuilderListErrorMessage, isKiqBuilderListErrorMessage] = useState("");
  const [topicsListErrorMessage, isTopicsListErrorMessage] = useState("");
  const [productsListErrorMessage, isProductListErrorMessage] = useState("");
  const [ventureCompanyListErrorMessage, setVentureCompanyListErrorMessage] = useState("")
  const [authData, setAuthData] = useState<any>(null);

  const oktaAuthConfig = new OktaAuth({
    issuer: process.env.REACT_APP_OKTA_ISSUER_LINK,
    clientId: process.env.REACT_APP_OKTA_CLIENT_ID,
    redirectUri: window.location.origin + "/implicit/callback",
    scopes: ["openid", "profile", "email", "offline_access"],
    pkce: true,
    responseType: "code",
    tokenManager: {
      expireEarlySeconds: 120,
      autoRenew: true,
      storageKey: "sensing-okta-token",
    },
  });

  const getKIQList = async () => {
    try {
      isKiqLoading(true);
      const res = await axios({
        method: "post",
        url: getApiUrl("get-kiq-list"),
        data: {},
        headers: {
          Authorization: `Bearer ${oktaToken}`,
        },
      });
      if (res.data.status === "SUCCESS" && res.data.data.length !== 0) {
        const kiqs = res.data.data;
        setKiqListData(kiqs);
        isKiqLoading(false);
      } else {
        isKiqListErrorMessage(noDataMessage);
        isKiqLoading(false);
      }
    } catch (error) {
      isKiqListErrorMessage(apiErrorMessage);
      isKiqLoading(false);
    }
  };

  const getKIQBuilderList = async () => {
    try {
      isKiqBuilderLoading(true);
      const res = await axios({
        method: "get",
        url: getApiUrl("get-kiq-builder-list"),
        data: {},
        headers: {
          Authorization: `Bearer ${oktaToken}`,
        },
      });
      if (res.data.status === "SUCCESS") {
        const kiqBuildList = res.data.data;
        setKiqBuilderListData(kiqBuildList);
        isKiqBuilderLoading(false);
      } else {
        isKiqBuilderListErrorMessage(noDataMessage);
        isKiqBuilderLoading(false);
      }
    } catch (error) {
      isKiqBuilderListErrorMessage(apiErrorMessage);
      isKiqBuilderLoading(false);
    }
  };

  const getTopics = async () => {
    try {
      isTopicsLoading(true);
      const res = await axios({
        method: "post",
        url: getApiUrl("get-topic-list"),
        data: {},
        headers: {
          Authorization: `Bearer ${oktaToken}`,
        },
      });
      if (res.data.status === "SUCCESS" && res.data.data.length !== 0) {
        const topicsList = res.data.data;
        topicsList.map((item:any) => { 
          item.newName = item.name
          item.name = item.name.replace(/\s/g, '_');
          return item
        })
        setTopicsListData(topicsList);
        isTopicsLoading(false);
      } else {
        isTopicsLoading(false);
        isTopicsListErrorMessage(noDataMessage);
      }
    } catch (error) {
      isTopicsLoading(false);
      isTopicsListErrorMessage(apiErrorMessage);
    }
  };
  const getProducts = async () => {
    try {
      isProductLoading(true);
      const res = await axios({
        method: "post",
        url: getApiUrl("product-list"),
        data: {},
        headers: {
          Authorization: `Bearer ${oktaToken}`,
        },
      });
      if (res.data.status === "SUCCESS" && res.data.data.length !== 0) {
        const products = res.data.data;
        setProductListData(products);
        isProductLoading(false);
      } else {
        isProductListErrorMessage(noDataMessage);
        isProductLoading(false);
      }
    } catch (error) {
      isProductListErrorMessage(apiErrorMessage);
      isProductLoading(false);
    }
  };
  
  const getTimeScale = async () => {
    try {
      isTimeScaleLoading(true);
      const res = await axios({
        method: "get",
        url: getApiUrl("time-scale"),
        data: {},
        headers: {
          Authorization: `Bearer ${oktaToken}`,
        },
      });
      if (res.data.status === "SUCCESS" && res.data.data.length !== 0) {
        const timeScale = res.data.data;
        setGetTimeSeriesData(timeScale);
        isTimeScaleLoading(false);
      } else {
        isTimeScaleLoading(false);
      }
    } catch (error) {
      isTimeScaleLoading(false);
    }
  };

  const getAmgenCompanyList = async() => {
    try {
      setAmgenCompanyLoading(true);
      const res = await axios({
        method: "get",
        url: getApiUrl("get-amgen-company-list"),
        data: {},
        headers: {
          Authorization: `Bearer ${oktaToken}`,
        },
      });
      if (res.data.status === "SUCCESS" && res.data.data.length !== 0) {
        const companyList = res.data.data;
        setVentureCompanyList(companyList);
        setAmgenCompanyLoading(false);
      } else {
        setVentureCompanyListErrorMessage(noDataMessage);
        setAmgenCompanyLoading(false);
      }
    } catch (error) {
      setVentureCompanyListErrorMessage(apiErrorMessage);
      setAmgenCompanyLoading(false);
    }
  };

  const goToSetKiqQuery = (data:any) => {
    setKiqBuilderListData(data);
  }

  useEffect(() => {
    if (authData) {
      if (authData?.external && authData?.externalAuthorization?.kiq) {
        getKIQList();
      } else {
        isKiqLoading(false);
      }
      if (authData?.external && authData?.externalAuthorization?.topics) {
        getTopics();
      } else {
        isTopicsLoading(false);
      }
      if(authData?.external && authData?.externalAuthorization?.avCompany) {
        getAmgenCompanyList();
      }else {
        setAmgenCompanyLoading(false)
      }
      if (authData?.external && authData?.externalAuthorization?.products) {
        getProducts();
      } else {
        isProductLoading(false);
      }
      if (authData?.external && authData?.externalAuthorization?.kiqBuilder) {
        getKIQBuilderList();
      } else {
        isKiqBuilderLoading(false);
      }
      if (authData?.external && authData?.externalAuthorization?.earnings) {
        getTimeScale();
      } else {
        isTimeScaleLoading(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authData]);

  const restoreOriginalUri = async (_oktaAuth: any, originalUri: any) => {
    navigate(toRelativeUrl(originalUri || "/", window.location.origin));
  };
  const { themeService } = props;

  return (
    <>
      <AppContext.Provider value={{ authData, setAuthData }}>
        {process.env.REACT_APP_OKTA_AUTH_REQUIRED === "YES" && (
          <Security
            oktaAuth={oktaAuthConfig}
            restoreOriginalUri={restoreOriginalUri}
          >
            <ThemeProvider themeService={themeService}>
              <PageHeader>
                <Layout>
                <Routes>
                  <Route
                    path="/external"
                    element={<Navigate replace={true} to={BASE_ROUTE} />}
                  />
                  <Route
                    path="/external/*"
                    element={<Navigate replace={true} to={`${BASE_ROUTE}${window.location.pathname.replace("/external", '')}`} />}
                  />
                  <Route path={BASE_ROUTE} element={<RequiredAuth />}>
                    <Route
                      path=""
                      element={
                        <>
                          {(productLoading ) && (
                            <div className="homepage-loader">
                              <Loader />
                            </div>
                          )}
                          {!productLoading && (
                            <>
                              {authData?.externalAuthorization?.trending ? (
                                <HomePage />
                              ) : (
                                <UnauthorizedPage />
                              )}
                            </>
                          )}
                        </>
                      }
                    />
                  </Route>

                  <Route path={`${BASE_ROUTE}/companies`} element={<RequiredAuth />}>
                    <Route
                      path=""
                      element={
                        <>
                          {(productLoading ) && (
                            <div className="homepage-loader">
                              <Loader />
                            </div>
                          )}
                          {!productLoading && (
                            <>
                              {authData?.externalAuthorization?.companies ? (
                                <Companies />
                              ) : (
                                <UnauthorizedPage />
                              )}
                            </>
                          )}
                        </>
                      }
                    />
                  </Route>

                  <Route path={`${BASE_ROUTE}/products`} element={<RequiredAuth />}>
                    <Route
                      path=""
                      element={
                        <>
                          {(productLoading ) && (
                            <div className="homepage-loader">
                              <Loader />
                            </div>
                          )}
                          {!productLoading && (
                            <>
                              {authData?.externalAuthorization?.products &&
                              productListData.length > 0 ? (
                                <Products
                                  productList={productListData}
                                  errorMessage={productsListErrorMessage}
                                  isLoading={productLoading}
                                />
                              ) : (
                                <UnauthorizedPage />
                              )}
                            </>
                          )}
                        </>
                      }
                    />
                  </Route>

                  <Route
                    path={`${BASE_ROUTE}/products/:productName`}
                    element={<RequiredAuth />}
                  >
                    <Route
                      path=""
                      element={
                        <>
                          {(productLoading ) && (
                            <div className="homepage-loader">
                              <Loader />
                            </div>
                          )}
                          {!productLoading && (
                            <>
                              {authData?.externalAuthorization?.products &&
                              productListData.length > 0 ? (
                                <Products
                                  productList={productListData}
                                  errorMessage={productsListErrorMessage}
                                  isLoading={productLoading}
                                />
                              ) : (
                                <UnauthorizedPage />
                              )}
                            </>
                          )}
                        </>
                      }
                    />
                  </Route>

                  <Route path={`${BASE_ROUTE}/topics`} element={<RequiredAuth />}>
                    <Route
                      path=""
                      element={
                        <>
                          {( topicsLoading ) && (
                            <div className="homepage-loader">
                              <Loader />
                            </div>
                          )}
                          { !topicsLoading && (
                            <>
                              {authData?.externalAuthorization?.topics &&
                              topicsListData.length > 0 ? (
                                <Topics
                                  topicsList={topicsListData}
                                  isLoading={topicsLoading}
                                  errorMessage={topicsListErrorMessage}
                                />
                              ) : (
                                <UnauthorizedPage />
                              )}
                            </>
                          )}
                        </>
                      }
                    />
                  </Route>

                  <Route path={`${BASE_ROUTE}/ta/oncology`} element={<RequiredAuth />}>
                    <Route
                      path=""
                      element={
                        <>
                          {( topicsLoading ) && (
                            <div className="homepage-loader">
                              <Loader />
                            </div>
                          )}
                          { !topicsLoading && (
                            <>
                              {authData?.externalAuthorization?.oncology ? (
                                <Oncology />
                              ) : (
                                <UnauthorizedPage />
                              )}
                            </>
                          )}
                        </>
                      }
                    />
                  </Route>

                  <Route
                    path={`${BASE_ROUTE}/topics/:topicName`}
                    element={<RequiredAuth />}
                  >
                    <Route
                      path=""
                      element={
                        <>
                          {( topicsLoading ) && (
                            <div className="homepage-loader">
                              <Loader />
                            </div>
                          )}
                          {!topicsLoading && (
                            <>
                              {authData?.externalAuthorization?.topics &&
                              topicsListData.length > 0 ? (
                                <Topics
                                  topicsList={topicsListData}
                                  isLoading={topicsLoading}
                                  errorMessage={topicsListErrorMessage}
                                />
                              ) : (
                                <UnauthorizedPage />
                              )}
                            </>
                          )}
                        </>
                      }
                    />
                  </Route>

                  <Route path={`${BASE_ROUTE}/news-curator`} element={<RequiredAuth />}>
                    <Route
                      path=""
                      element={
                        <>
                          {(productLoading || topicsLoading || kiqLoading) && (
                            <div className="homepage-loader">
                              <Loader />
                            </div>
                          )}
                          {!productLoading && !topicsLoading && !kiqLoading && (
                            <>
                              {authData?.externalAuthorization?.newsCurator ? (
                                <NewsCurator />
                              ) : (
                                <UnauthorizedPage />
                              )}
                            </>
                          )}
                        </>
                      }
                    />
                  </Route>

                  <Route path={`${BASE_ROUTE}/news-search`} element={<RequiredAuth />}>
                    <Route
                      path=""
                      element={
                        <>
                          {(productLoading || topicsLoading || kiqLoading) && (
                            <div className="homepage-loader">
                              <Loader />
                            </div>
                          )}
                          {!productLoading && !topicsLoading && !kiqLoading && (
                            <>
                              {authData?.externalAuthorization?.news ? (
                                <News />
                              ) : (
                                <UnauthorizedPage />
                              )}
                            </>
                          )}
                        </>
                      }
                    />
                  </Route>

                  <Route path={`${BASE_ROUTE}/peer-big-sheet`}
                    element={<RequiredAuth />}
                  >
                    <Route
                      path=""
                      element={
                        <>
                          {(productLoading ) && (
                            <div className="homepage-loader">
                              <Loader />
                            </div>
                          )}
                          {!productLoading  && (
                            <>
                              {authData?.externalAuthorization?.peerBigSheet ? (
                                <PeerBigSheet />
                              ) : (
                                <UnauthorizedPage />
                              )}
                            </>
                          )}
                        </>
                      }
                    />
                  </Route>

                  

                  <Route
                    path={`${BASE_ROUTE}/companies/:company_ticker`}
                    element={<RequiredAuth />}
                  >
                    <Route
                      path=""
                      element={
                        <>
                          {(productLoading ) && (
                            <div className="homepage-loader">
                              <Loader />
                            </div>
                          )}
                          {!productLoading &&  (
                            <>
                              {authData?.externalAuthorization?.companies ? (
                                <CompanyProfile />
                              ) : (
                                <UnauthorizedPage />
                              )}
                            </>
                          )}
                        </>
                      }
                    />
                  </Route>

                  <Route path={`${BASE_ROUTE}/corporate-strategy`} element={<RequiredAuth />}>
                    <Route
                      path=""
                      element={
                        <>
                          {( kiqLoading) && (
                            <div className="homepage-loader">
                              <Loader />
                            </div>
                          )}
                          { !kiqLoading && (
                            <>
                              {authData?.externalAuthorization?.kiq &&
                              kiqListData.length > 0 ? (
                                <KIQ
                                  kiqList={kiqListData}
                                  errorMessage={kiqListErrorMessage}
                                  isLoading={kiqLoading}
                                />
                              ) : (
                                <UnauthorizedPage />
                              )}
                            </>
                          )}
                        </>
                      }
                    />
                  </Route>

                  <Route
                    path={`${BASE_ROUTE}/corporate-strategy/:kiqName`}
                    element={<RequiredAuth />}
                  >
                    <Route
                      path=""
                      element={
                        <>
                          {( kiqLoading) && (
                            <div className="homepage-loader">
                              <Loader />
                            </div>
                          )}
                          {!kiqLoading && (
                            <>
                              {authData?.externalAuthorization?.kiq &&
                              kiqListData.length > 0 ? (
                                <KIQ
                                  kiqList={kiqListData}
                                  errorMessage={kiqListErrorMessage}
                                  isLoading={kiqLoading}
                                />
                              ) : (
                                <UnauthorizedPage />
                              )}
                            </>
                          )}
                        </>
                      }
                    />
                  </Route>


                  <Route path={`${BASE_ROUTE}/builder`} element={<RequiredAuth />}>
                    <Route
                      path=""
                      element={
                        <>
                          {( kiqBuilderLoading) && (
                            <div className="homepage-loader">
                              <Loader />
                            </div>
                          )}
                          { !kiqBuilderLoading && (
                            <>
                              {authData?.externalAuthorization?.kiqBuilder ? (
                                <KIQBUILDER
                                  kiqList={kiqBuilderListData}
                                  errorMessage={kiqBuilderListErrorMessage}
                                  isLoading={kiqBuilderLoading}
                                  setNewKiqBuilderList={(data:any) => goToSetKiqQuery(data)}
                                />
                              ) : (
                                <UnauthorizedPage />
                              )}
                            </>
                          )}
                        </>
                      }
                    />
                  </Route>

                  <Route
                    path={`${BASE_ROUTE}/builder/:kiqName`}
                    element={<RequiredAuth />}
                  >
                    <Route
                      path=""
                      element={
                        <>
                          {( kiqBuilderLoading) && (
                            <div className="homepage-loader">
                              <Loader />
                            </div>
                          )}
                          {!kiqBuilderLoading && (
                            <>
                              {authData?.externalAuthorization?.kiqBuilder ? (
                                <KIQBUILDER
                                  kiqList={kiqBuilderListData}
                                  errorMessage={kiqBuilderListErrorMessage}
                                  isLoading={kiqBuilderLoading}
                                  setNewKiqBuilderList={(data:any) => goToSetKiqQuery(data)}
                                />
                              ) : (
                                <UnauthorizedPage />
                              )}
                            </>
                          )}
                        </>
                      }
                    />
                  </Route>

                  <Route path={`${BASE_ROUTE}/amgen-ventures`} element={<RequiredAuth />}>
                    <Route
                      path=""
                      element={
                        <>
                          {( amgenCompanyLoading) && (
                            <div className="homepage-loader">
                              <Loader />
                            </div>
                          )}
                          { !amgenCompanyLoading && (
                            <>
                              {authData?.externalAuthorization?.avCompany &&
                              ventureCompanyList.length > 0 ? (
                                <AmgenVenture
                                  amgenCompanyList={ventureCompanyList}
                                  errorMessage={ventureCompanyListErrorMessage}
                                  isLoading={amgenCompanyLoading}
                                />
                              ) : (
                                <UnauthorizedPage />
                              )}
                            </>
                          )}
                        </>
                      }
                    />
                  </Route>

                  <Route
                    path={`${BASE_ROUTE}/amgen-ventures/:companyname`}
                    element={<RequiredAuth />}
                  >
                    <Route
                      path=""
                      element={
                        <>
                          {(amgenCompanyLoading) && (
                            <div className="homepage-loader">
                              <Loader />
                            </div>
                          )}
                          {!amgenCompanyLoading && (
                            <>
                              {authData?.externalAuthorization?.avCompany &&
                              ventureCompanyList.length > 0 ? (
                                <AmgenVenture
                                  amgenCompanyList={ventureCompanyList}
                                  errorMessage={ventureCompanyListErrorMessage}
                                  isLoading={amgenCompanyLoading}
                                />
                              ) : (
                                <UnauthorizedPage />
                              )}
                            </>
                          )}
                        </>
                      }
                    />
                  </Route>

                  <Route
                    path={`${BASE_ROUTE}/news/:articleId`}
                    element={<RequiredAuth />}
                  >
                    <Route
                      path=""
                      element={
                        <>
                          {(productLoading ) && (
                            <div className="homepage-loader">
                              <Loader />
                            </div>
                          )}
                          {<DowJonesComponent />}
                        </>
                      }
                    />
                  </Route>

                  <Route path={`${BASE_ROUTE}/experimental`} element={<RequiredAuth />}>
                    <Route
                      path=""
                      element={
                        <>
                            {authData?.externalAuthorization?.experimentalPage 
                            ? (
                              <Experimental />
                            ) : (
                              <UnauthorizedPage />
                            )}
                        </>
                      }
                    />
                  </Route>

              {/* route for earning page with no dependency on okta */}
                  <Route path={`${BASE_ROUTE}/earnings`} element={<RequiredAuth />}>
                    <Route
                      path=""
                      element={
                        <>
                          {(timeScaleLoading ) && (
                            <div className="homepage-loader">
                              <Loader />
                            </div>
                          )}
                          {!timeScaleLoading && getTimeSeriesData ? (
                            <>
                              {authData?.externalAuthorization?.earnings ? (
                                <EarningPage timeScaleData={getTimeSeriesData} />
                              ) : (
                                <UnauthorizedPage />
                              )}
                            </>
                          ) :
                          <UnauthorizedPage />
                          }
                        </>
                      }/>
                  </Route>

                  <Route path="/implicit/callback" element={<LoginCallback />} >

                  </Route>
                

                </Routes>
                </Layout>
              </PageHeader>
            </ThemeProvider>
          </Security>
        )}
      </AppContext.Provider>
    </>
  );
};
// };
export default ExternalRoutes;
