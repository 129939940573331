import React from "react";
import "./index.scss";

type Props = {
  message?: string;
  isIcon?: boolean;
};

const ErrorMessage: React.FC<Props> = (props: Props) => {
  return (
    <div className="ext-error-wrapper ops-py-12 ops-px-12">
      <div className="ext-d-flex">
        <div className="ext-message">{props.message}</div>
      </div>
    </div>
  );
};

export default ErrorMessage;
