import React from 'react';
import { classes } from '../../../utils/classes';

export type Props = {
  title: any | undefined;
  className?: string;
};
const ModalTitle: React.FC<Props> = (props: Props) => {
  const { title, className } = props;

  if (title === undefined) return <></>;

  const classNames = classes([
    'ops-text-dark',
    'ops-fw-bold',
    'ops-fs-4',
    'ops-lh-2',
    className,
  ]);

  return <p className={classNames}>{title}</p>;
};

export default ModalTitle;
