import { Loader, Modal } from "@gitlab-rtsensing/component-library";
import "./index.scss";
import { Suspense, lazy, useEffect } from "react";
import { lazyImport } from "../../utils/lazy-import";
import AmgenNetworks from "../../assets/icons/amgen-networks";
import Logo from "../../assets/images/Amgen-Sensing.png"; 

interface RequestAccessModalProps {
  title?: string;
  opened: boolean;
  onRequestAccessModalClosed: () => void;
}

const RequestAccessModal = (props: RequestAccessModalProps) => {
  const RequestAccessForm = lazy(() =>
    lazyImport("@amgen/rtsensing-request-form")
  );
  const {
    title = "Request Access",
    opened,
    onRequestAccessModalClosed,
  } = props;

  useEffect(() => {
    const el = document.querySelector(
      ".external-request-access-modal > div > div"
    );
    el?.classList.remove("ops-px-6");
    el?.classList.remove("ops-py-4");
  });

  return (
    <Modal
      className="external-request-access-modal"
      opened={opened}
      onClose={onRequestAccessModalClosed}
    >
       <Modal.Header
        contentClassName="ext-d-flex ext-align-items-center"
        className="request-modal-header"
      >
        <AmgenNetworks fill="var(--ops-white)" />
        <img src={Logo} alt="amgen logo"></img>
        <Modal.Title className="request-modal-title" title={title} />
      </Modal.Header>
      <Suspense fallback={<Loader />}>
        {opened && <RequestAccessForm />}
      </Suspense>
    </Modal>
  );
};

export default RequestAccessModal;
