import React, { useState } from "react";
import "./index.scss";
import { Button } from "@gitlab-rtsensing/component-library";
import ViewAllInsights from "../view-insights-modal";

type EarningsNotesProps = {
  earningsNotes?: any;
  quarter?: string;
  year?: string;
};

const EarningsNotes: React.FC<EarningsNotesProps> = (
  props: EarningsNotesProps
) => {
  const { quarter } = props;
  const { year } = props;

  const [showNotes, setShowNotes] = useState(false);  
  const onClickViewAllInsights = () => {
    setShowNotes(true);
  }

  const onClosePopupModal = () => {
    setShowNotes(false);
  }

  return (
    <div>
      <div className="ext-earnings-notes-wrapper">
        <div className="ext-d-flex ext-justify-content-center ops-my-5">
            <Button
              type="secondary"
              label="VIEW ALL INSIGHTS"
              className="ext-earning-notes-btn"
              onClick={() => onClickViewAllInsights()}
            />
        </div>

        {
          showNotes ? 
            <ViewAllInsights showNotes={showNotes} onClosePopupModal={onClosePopupModal} quarter={quarter} year={year} />
            :
            <></>
        }
      </div>
    </div>
  );
};

export default EarningsNotes;
