interface Props {
  fill: string;
}

const LeftIcon = ({ fill }: Props) => {
  return (
    <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M10 0C4.5 0 0 4.5 0 10C0 15.5 4.5 20 10 20C15.5 20 20 15.5 20 10C20 4.5 15.5 0 10 0ZM13 11H9.4L10.7 12.3C11.1 12.7 11.1 13.3 10.7 13.7C10.3 14.1 9.7 14.1 9.3 13.7L6.3 10.7C5.9 10.3 5.9 9.7 6.3 9.3L9.3 6.3C9.7 5.9 10.3 5.9 10.7 6.3C11.1 6.7 11.1 7.3 10.7 7.7L9.4 9H13C13.6 9 14 9.4 14 10C14 10.6 13.6 11 13 11Z"
          fill={fill}
        />
      </svg>
  );
};

export default LeftIcon;
