import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import "./index.scss";
import ArticleCard from "../../components/article-card";
import { getFormatedTagList, getOktaToken } from "../../utils/commonMethods";
import { getApiUrl } from "../../api/api-config";
import ModalGenerateEmail from "../modal-generate-email";
import { useNavigate, useLocation } from "react-router-dom";
import DeleteCollectionModal from "../delete-collection-modal";
import { Loader } from "@gitlab-rtsensing/component-library";
import { apiErrorMessage } from "../../assets/errorMessageConstants";
import ErrorMessage from "../../components/error-message";
import DeleteArticleModal from "../delete-article-modal";
import { AppContext } from "../../utils/app-context";
import ExternalModal from "../external-custom-modal";
import { CloseIcon } from "../../assets/icons/close-icon";

type Props = {
  show: boolean;
  hide?: boolean;
  onHideModal: (modalState: boolean) => void;
  onDeleteCollection: (id: string) => void;
  collectionId: string;
  collectionName: string;
  completeRemove: any;
  deleteCollectionError: boolean;
};

type collectionData = {
  collection_id: string;
  collection_name: string;
};

const MyCollection: React.FC<Props> = ({
  show,
  onHideModal,
  onDeleteCollection,
  collectionName,
  collectionId,
  completeRemove,
  deleteCollectionError,
}) => {
  const oktaToken = getOktaToken();
  const [articlesList, setArticlesList] = useState([]);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isOpenGenerateEmail, setIsOpenGenerateEmail] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isDeleteModal, setIsDeleteModel] = useState(false);
  const [collectionArticleListError, setCollectionArticleListError] =
    useState("");
  const [showdelete, setshowdelete] = useState(false);
  const [carticleId, setCarticleId] = useState("");
  const [deleteArticleName, setDeleteArticleName] = useState("");
  const [collectionTitle, setCollectionTitle] = useState(0);
  const [articleLength, setArticleLength] = useState(10);
  const [removeArticleError, setRemoveArticleError] = useState(false);

  const collectionData: collectionData = {
    collection_id: collectionId,
    collection_name: collectionName,
  };

  const navigate = useNavigate();
  const location = useLocation();
  const { authData } = useContext(AppContext);

  const getCollectionArticles = async (title: number) => {
    setIsLoading(true);
    const payload = {
      collection_id: collectionId,
      page_offset: 0,
      limit: 50,
    };
    try {
      const res = await axios({
        method: "post",
        url: getApiUrl("get-collections-article"),
        data: payload,
        headers: {
          Authorization: `Bearer ${oktaToken}`,
        },
      });
      res?.data?.data && res.data.data.response && setArticlesList(res.data.data.response);
      setArticleLength(res.data.data.response.length);
      if (res.data.data.response.length === 0 && title === 1) {
        setIsDeleteModel(true);
        setCollectionTitle(1);
      } else {
        setCollectionTitle(0);
      }
      setIsLoading(false);
      setIsDeleting(false);
    } catch (error) {
      setIsLoading(false);
      setCollectionArticleListError(apiErrorMessage);
    }
  };

  const deleteArticle = async (id: any) => {
    try {
      setIsDeleting(true);
      const payload = {
        collection_id: collectionId,
        collection_data: [id],
      };

      const res = await axios({
        method: "delete",
        url: getApiUrl("get-remove-article"),
        data: payload,

        headers: {
          Authorization: `Bearer ${oktaToken}`,
        },
      });
      if (res) {
        getCollectionArticles(1);
      }
    } catch (error) {
      setRemoveArticleError(true);
      setTimeout(() => setRemoveArticleError(false), 3000);
      setIsLoading(false);
      setIsDeleting(false);
    }
  };
  const deletingArticle = (articleId: string, title: string) => {
    setshowdelete(true);
    setCarticleId(articleId);
    setDeleteArticleName(title);
  };

  const openGenerateEmail = () => {
    setIsOpenGenerateEmail(true);
  };

  const closeGenerateEmail = () => {
    completeRemove();
    setIsOpenGenerateEmail(false);
  };

  const closeArticleModal = () => {
    setshowdelete(false);
  };
  const closeDeleteModal = () => {
    setIsDeleteModel(false);
    if (articleLength === 0) {
      setTimeout(() => {
        completeRemove();
      }, 1500);
    }
  };

  const NoArticle = () => {
    return "No articles found in the selected collection.";
  };

  useEffect(() => {
    if (
      authData &&
      Object.keys(authData).length > 0 &&
      authData?.externalAuthorization?.newsCurator
    ) {
      getCollectionArticles(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {!isOpenGenerateEmail && (

        <ExternalModal
        
        title={<>{`My Articles Collection: ${collectionName}`}</>}
        visible={show}
        closeEvent={() => {completeRemove()
          onHideModal(!show)}}
        icon={<CloseIcon height={20} width={20} className="close-icon" />}      
        >
          <>

<>
              {removeArticleError && (
                <div className="ext-text-danger ext-fst-italic">
                  Unable to delete article, please try again
                </div>
              )}
              {deleteCollectionError && (
                <div className="ext-text-danger ext-fst-italic">
                  Unable to delete collection, please try again
                </div>
              )}
              {/* {articlesList.length===0? setIsDeleteModel(true):null} */}
              <div className="ext-section-height">
                {isDeleting || isLoading ? (
                  <div className="ops-mt-12 ops-pt-12 ext-d-flex ext-justify-content-center">
                    <Loader />
                  </div>
                ) : (
                  <div>
                    {!collectionArticleListError && (
                      <div className="ext-fst-italic modal-heading">
                        {articlesList && articlesList.length > 1
                          ? "Share or remove articles in the collection below."
                          : articlesList && articlesList.length === 1
                          ? "Share or remove article in the collection below."
                          : NoArticle()}
                      </div>
                    )}
                    <div className="ext-my-collection-model-height">
                      {collectionArticleListError && !isLoading && (
                        <ErrorMessage
                          isIcon={false}
                          message={collectionArticleListError}
                        />
                      )}
                      {articlesList.length > 0 &&
                        articlesList.map((article: any, index: number) => {
                          let specialClassName =
                            index % 2 === 0
                              ? "ext-article-background-gray"
                              : "ext-article-background-white";

                          const tags = getFormatedTagList(
                            navigate,
                            location,
                            article.company_names,
                            article.product_names,
                            article.topic_names,
                            article.kiq_names,
                            article.av_company_names
                          );

                          return (
                            <ArticleCard
                              key={index}
                              redirectUrl={article.url}
                              articleId={article.article_id}
                              specialClassName={specialClassName}
                              title={article.title}
                              publishDate={article.published}
                              publishSource={article.source_name}
                              tagList={tags}
                              checkboxFlag={false}
                              trashFlag={true}
                              handleDelete={() =>
                                deletingArticle(
                                  article.article_id,
                                  article.title
                                )
                              }
                            />
                          );
                        })}
                    </div>
                  </div>
                )}

                {
                  <DeleteArticleModal
                    show={showdelete}
                    onHideDeleteModal={() => closeArticleModal()}
                    onDeleteClick={() => deleteArticle(carticleId)}
                    articleId={carticleId}
                    articleName={deleteArticleName}
                  />
                }
                {/* /> */}
              </div>
            </>
            <div className="ext-d-flex ext-flex-wrap modal-buttons ops-mt-2">
              <div className="ops-mb-1 ops-mb-sm-0 ops-mb-md-1 left-buttons ext-flex-grow-1">
                <button
                  type="button"
                  className="btn btn-outline-primary btn-sm rounded-pill external-delete-button"
                  onClick={() => {
                    setIsDeleteModel(true);
                    setCollectionTitle(0);
                  }}
                >
                  Delete Collection
                </button>
              </div>
              <div>
                {articlesList.length > 0 && (
                  <button
                    type="button"
                    className="ext-secondary-button ops-mb-1 btn btn-outline-primary btn-sm rounded-pill ops-ms-2"
                    onClick={() => {
                      openGenerateEmail();
                    }}
                  >
                    Generate Email
                  </button>
                )}
              </div>
            </div>
            </>

        </ExternalModal>
      )}



      {isOpenGenerateEmail && (
        <ModalGenerateEmail
          onHideModal={closeGenerateEmail}
          show={isOpenGenerateEmail}
          collectionData={collectionData}
          backToNews={() => onHideModal(!show)}
        />
      )}

      <div className="ext-delete-size-modal">
      <DeleteCollectionModal
        show={isDeleteModal}
        onHideDeleteModal={() => closeDeleteModal()}
        onDeleteClick={() => onDeleteCollection(collectionId)}
        collectionId={collectionId}
        collectionName={collectionName}
        collectionTitle={collectionTitle}
      />
      </div>

    </>
  );
};

export default MyCollection;
