function ExclamationIcon() {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.57464 2.87521C5.58866 2.19766 6.78082 1.83599 8.00037 1.83594C9.63535 1.83788 11.2028 2.48824 12.3589 3.64435C13.5151 4.80052 14.1655 6.3681 14.1673 8.00318C14.1672 9.22263 13.8055 10.4147 13.128 11.4286C12.4504 12.4427 11.4873 13.2331 10.3605 13.6999C9.23373 14.1666 7.99381 14.2887 6.7976 14.0508C5.60138 13.8128 4.50259 13.2255 3.64016 12.3631C2.77774 11.5007 2.19042 10.4019 1.95248 9.20566C1.71454 8.00945 1.83666 6.76953 2.3034 5.64272C2.77014 4.51591 3.56053 3.55281 4.57464 2.87521Z"
        stroke="#D62728"
      />
      <path
        d="M7.52925 4.85932C7.65427 4.7343 7.82384 4.66406 8.00065 4.66406C8.17746 4.66406 8.34703 4.7343 8.47206 4.85932C8.59708 4.98435 8.66732 5.15392 8.66732 5.33073V7.9974C8.66732 8.17421 8.59708 8.34378 8.47206 8.4688C8.34703 8.59382 8.17746 8.66406 8.00065 8.66406C7.82384 8.66406 7.65427 8.59382 7.52925 8.4688C7.40422 8.34378 7.33398 8.17421 7.33398 7.9974V5.33073C7.33398 5.15392 7.40422 4.98435 7.52925 4.85932Z"
        fill="#D62728"
      />
      <path
        d="M7.63027 10.1097C7.7399 10.0365 7.8688 9.9974 8.00065 9.9974C8.17746 9.9974 8.34703 10.0676 8.47206 10.1927C8.59708 10.3177 8.66732 10.4873 8.66732 10.6641C8.66732 10.7959 8.62822 10.9248 8.55496 11.0344C8.48171 11.1441 8.37759 11.2295 8.25577 11.28C8.13396 11.3304 7.99991 11.3436 7.87059 11.3179C7.74127 11.2922 7.62248 11.2287 7.52925 11.1355C7.43601 11.0422 7.37252 10.9234 7.34679 10.7941C7.32107 10.6648 7.33427 10.5308 7.38473 10.4089C7.43519 10.2871 7.52064 10.183 7.63027 10.1097Z"
        fill="#D62728"
      />
    </svg>
  );
}

export default ExclamationIcon;
