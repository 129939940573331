interface Props {
  classname: string;
}
const MinusCircleIcon = ({ classname }: Props) => {
  return (
    <svg
      className={classname}
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <circle cx="9" cy="9" r="8"></circle>
      <line x1="5" y1="9" x2="12" y2="9"></line>
    </svg>
  );
};

export default MinusCircleIcon;
