import React, { useState, useEffect, useContext } from "react";
import "./index.scss";
import CompanyList from "./CompanyList";
import {
  CompaniesListRequest,
// eslint-disable-next-line import/no-unresolved
} from "../../api/get-companies-dt";
import { orderBy } from "lodash";
import {
  apiResponse,
} from "../../utils/commonMethods";
import { AppContext } from "../../utils/app-context";
import RequestPage from "../../components/request-access-page";
import ErrorMessage from "../../components/error-message";
import { apiErrorMessage } from "../../assets/errorMessageConstants";
import { ComparativePEValues } from "../home-page/components/comparative-pe";
import { Title, Input, Button, Label } from '@opsdti-global-component-library/amgen-design-system';
import "../../assets/css/bootstrap.scss";

const Companies: React.FC = () => {
  const [companies, setCompanies] = useState<any[]>([]);
  const [filteredCompanies, setFilteredCompanies] = useState<any[]>([]);
  const [isFirstLoading, setIsFirstLoading] = useState(false);
  const [columns, setColumns] = useState([
    {
      title: "Company",
      key: "company_name",
      isSorting: true,
      type: "string",
      sort: {
        active: true,
        isSorted: true,
        direction: "asc",
        value: "company_name",
        onSort: (index: number, companiesList:  any) => {
          sortTableData(index, companiesList);
        },
      },
    },
    {
      title: "Price",
      key: "company_price",
      isSorting: true,
      type: "number",
      sort: {
        active: false,
        isSorted: false,
        direction: "asc",
        onSort: (index: number, companiesList:  any) => {
          sortTableData(index, companiesList);
        },
      },
    },
    {
      title: "Today",
      key: "company_daily_change_percentage",
      isSorting: true,
      type: "number",
      sort: {
        active: false,
        isSorted: false,
        direction: "asc",
        onSort: (index: number, companiesList:  any) => {
          sortTableData(index, companiesList);
        },
      },
    },
    {
      title: "Price History",
      key: "company_price_history",
      isSorting: false,
      type: "number",
      sort: {
        active: false,
        isSorted: false,
        direction: "asc",
        onSort: (index: number, companiesList:  any) => {
          sortTableData(index, companiesList);
        },
      },
    },
    {
      title: "52 Week range",
      key: "company_yearly_range.current_value",
      isSorting: true,
      type: "number",
      sort: {
        active: false,
        isSorted: false,
        direction: "asc",
        onSort: (index: number, companiesList:  any) => {
          sortTableData(index, companiesList);
        },
      },
    },
    {
      title: "P/E Ratio",
      key: "company_pe_ratio",
      isSorting: true,
      type: "number",
      sort: {
        active: false,
        isSorted: false,
        direction: "asc",
        onSort: (index: number, companiesList:  any) => {
          sortTableData(index, companiesList);
        },
      },
    },
    {
      title: "Market Cap",
      key: "company_mkt_cap",
      isSorting: true,
      type: "number",
      sort: {
        active: false,
        isSorted: false,
        direction: "asc",
        onSort: (index: number, companiesList:  any) => {
          sortTableData(index, companiesList);
        },
      },
    },
  ]);

  const [pEMin, setPEMin] = useState("");
  const [pEMax, setPEMax] = useState("");
  const [mrMin, setMrMin] = useState("");
  const [mrMax, setMrMax] = useState("");
  const handleReset = () => {
    setPEMin("");
    setPEMax("");
    setMrMin("");
    setMrMax("");
    setFilteredCompanies(companies)
  };

  const [errorMessage, setErrorMessage] = useState("");

  const sortTableData = (
    selectedIndex: number,
    companiesList:  any
  ) => {
    let updatedColumn = [...columns];
    const tempCompanyList = [...companiesList];
    updatedColumn = updatedColumn.map((column, index) => {
      if (index === selectedIndex) {
        let sortedTableData = [];
        column.sort.isSorted = true;
        column.sort.active = true;
        if (column.sort.direction === "asc") {
          column.sort.direction = "desc";
          if (column.type === "string") {
            sortedTableData = orderBy(tempCompanyList, [column.key], ["desc"]);
          } else {
            sortedTableData = orderBy(tempCompanyList, [column.key], ["desc"]);
          }
        } else {
          column.sort.direction = "asc";
          if (column.type === "string") {
            sortedTableData = orderBy(tempCompanyList, [column.key], ["asc"]);
          } else {
            sortedTableData = orderBy(tempCompanyList, [column.key], ["asc"]);
          }
        }
        setFilteredCompanies([...sortedTableData])
      } else {
        column.sort.isSorted = false;
        column.sort.direction = "asc";
        column.sort.active = false;
      }
      return column;
    });
    setColumns([...updatedColumn]);
  };

  useEffect(() => {
    let mutableCompanies = companies
    if(pEMin !== "")
      mutableCompanies = mutableCompanies.filter((company) => company.company_pe_ratio >= pEMin)
    if(pEMax !== "")
      mutableCompanies = mutableCompanies.filter((company) => company.company_pe_ratio <= pEMax)
    if(mrMin !== "")
      mutableCompanies = mutableCompanies.filter((company) => company.company_mkt_cap >= mrMin)
    if(mrMax !== "")
      mutableCompanies = mutableCompanies.filter((company) => company.company_mkt_cap <= mrMax)

    setFilteredCompanies(mutableCompanies)
  }, [pEMin, pEMax, mrMin, mrMax])

  const getCompanies = async (updatedFilters: any) => {
    let payload: CompaniesListRequest = {};
    if (updatedFilters.market_cap_max !== "") {
      payload.market_cap_max = parseFloat(updatedFilters.market_cap_max);
    }
    if (updatedFilters.market_cap_min !== "") {
      payload.market_cap_min = parseFloat(updatedFilters.market_cap_min);
    }
    if (updatedFilters.pe_ratio_max !== "") {
      payload.pe_ratio_max = parseFloat(updatedFilters.pe_ratio_max);
    }
    if (updatedFilters.pe_ratio_min !== "") {
      payload.pe_ratio_min = parseFloat(updatedFilters.pe_ratio_min);
    }
    try {
      const res = await apiResponse("post", "get-companies", [], payload);

      if (res.data.status === "SUCCESS") {
        const sortedCompanies = orderBy(
          res.data.data,
          ["company_name"],
          ["asc"]
        );
        setCompanies([...sortedCompanies]);
        setFilteredCompanies([...sortedCompanies])
      } else {
        setErrorMessage(res.data.message);
        setCompanies([]);
        setFilteredCompanies([]);
      }
      setIsFirstLoading(false);
    } catch (error) {
      setCompanies([]);
      setFilteredCompanies([]);
      setIsFirstLoading(false);
      setErrorMessage(apiErrorMessage);
    }
  };

  useEffect(() => {
    setIsFirstLoading(true);
    setCompanies([]);
    setFilteredCompanies([]);
    if (
      authData &&
      Object.keys(authData).length > 0 &&
      authData?.externalAuthorization?.companies
    ) {
      getCompanies({
        pe_ratio_min: pEMin,
        pe_ratio_max: pEMax,
        market_cap_min: mrMin,
        market_cap_max: mrMax,
      });
    }
  }, []);
  const { authData } = useContext(AppContext);

  const isResetDisabled = !pEMin && !pEMax && !mrMin && !mrMax;

  return authData && authData?.externalAuthorization?.companies ? (
    <div className="ext-company-financials ops-px-4">
      <Title style={{ marginBottom: "20px" }} level={1}>
        Company Financials
      </Title>
      <div className="ext-row">
        <Title style={{ marginBottom: "20px" }} level={2}>P/E Ratios</Title>
        <div className="ext-col-lg-4 ext-col-sm-12">
          <ComparativePEValues
            popOverContent="The peerset average excludes Eli Lilly, Novo Nordisk, and Takeda. All P/E multiples are current year estimates, updated daily from FactSet and FMP."
            cardTitle="Price-to-Earnings Multiple Comparison"
          />
        </div>
      </div>
      <div className="ext-row" style={{ marginTop: "40px" }}>
        <Title level={2}>Company Financials Table</Title>
        <div className="ext-company-financials-table-container">
          <div className="ext-companies-filter-section ops-my-4">
            <div className="ext-companies-filter-container">
              <Label>
                P/E RATIO :
              </Label>
              <div className="ext-companies-input-container">
                <Input
                  className="form-control input-box ext-input-element-size ext-button-styling"
                  placeholder="Minimum"
                  value={pEMin}
                  onChange={(e) => setPEMin(e.target.value)}
                />
                <Input
                  className="form-control input-box ext-input-element-size ext-button-styling"
                  placeholder="Maximum"
                  value={pEMax}
                  onChange={(e) => setPEMax(e.target.value)}
                />
              </div>
            </div>

            <div className="ext-companies-filter-container">
              <Label>
                MARKET CAP :
              </Label>
              <div className="ext-companies-input-container">
                <Input
                  className="form-control input-box ext-input-element-size ext-button-styling"
                  placeholder="Minimum"
                  value={mrMin}
                  onChange={(e) => setMrMin(e.target.value)}
                />
                <Input
                  className="form-control input-box ext-input-element-size ext-button-styling"
                  placeholder="Maximum"
                  value={mrMax}
                  onChange={(e) => setMrMax(e.target.value)}
                />
              </div>
            </div>
            <Button
              text="RESET"
              type="secondary"
              onClick={() => {
                handleReset();
              }}
              disabled={isResetDisabled}
            />
          </div>
          {errorMessage !== "" ? (
            <ErrorMessage message={errorMessage} isIcon={false} />
          ) : (
            <div className="companies-list-section ops-p-0 ext-overflow-table">
              <CompanyList
                tableData={filteredCompanies}
                columns={columns}
                isFirstLoading={isFirstLoading}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  ) : (
    <>{authData && <RequestPage />}</>
  );
};

export default Companies;
