import Card from "../../components/card";
import "./index.scss";
import ArticleCard from "../../components/article-card";
import { useContext } from "react";
import { AppContext } from "../../utils/app-context";
import {
  apiResponse,
  formatText,
  getFormatedTagList,
} from "../../utils/commonMethods";
import { useEffect, useState } from "react";
import { Loader } from "@gitlab-rtsensing/component-library";
import { useLocation, useNavigate } from "react-router-dom";
import { ProductListType } from "../../api/get-product-data-dt";
import RequestPage from "../../components/request-access-page";
import ErrorMessage from "../../components/error-message";
import {
  apiErrorMessage,
  articlesMsg,
  noDataMessage,
} from "../../assets/errorMessageConstants";
import { Pagination } from "../../components/pagination";
import BackBtn from "../../components/back-btn";
import { BASE_ROUTE, getBackButtonText } from "../../utils/routing";
import dayjs from '../../utils/dayjs';

type Props = {
  productList: any;
  isLoading: boolean;
  errorMessage: string;
};

const Products: React.FC<Props> = (props: Props) => {
  const [productList, setProductList] = useState<ProductListType[]>([]);
  const [selectedProductId, setSelectedProductId] = useState<string>("");

  const [selectedProductName, setSelectedProductName] =
    useState<string>("loading...");

  const [isNewsListLoading, setIsNewsListLoading] = useState<boolean>(true);
  const [errorMessageNewsList, setErrorMessageNewsList] = useState<string>("");
  const [newsList, setNewsList] = useState([]);
  const [showBackBtn, setShowBackBtn] = useState(false);
  const [isProductDetailsLoading, setIsProductDetailsLoading] =
    useState<boolean>(true);
  const [errorMessageProductDetails, setErrorMessageProductDetails] =
    useState<string>("");
  const [productDetails, setProductDetails] = useState<any[]>([]);

  const [page, setPage] = useState(1);
  const [totalArticles, setTotalArticles] = useState(0);
  const pageLimit = 50;
  const navigate = useNavigate();
  const location: any = useLocation();
  const { authData } = useContext(AppContext);
  const [backButtonRoute, setBackButtonRoute] = useState<any>(-1);

  const handlePages = async (updatePage: number) => {
    setPage(updatePage);
  };

  const loadProductNews = async (productName: string) => {
    setSelectedProductId(productName);
    setSelectedProductName(`${productName}`);
    setNewsList([]);
    setIsNewsListLoading(true);
    setErrorMessageNewsList("");
    setTotalArticles(0);

    const payload = {
      sort_by_value: "desc",
      page_offset: page - 1,
      end_date: dayjs(),
      start_date: dayjs().subtract(89, 'days'),
      limit: 50,
      filters: { product: [`${productName}`] },
    };

    try {
      const res = await apiResponse("post", "get-news-with-tags", [], payload);
      if (
        res.data.status === "SUCCESS" &&
        res.data.data.response &&
        res.data.data.response?.length !== 0
      ) {
        setNewsList(res.data.data.response);
        setTotalArticles(res.data.data.count);
        setErrorMessageNewsList("");
      } else {
        setNewsList([]);
        setTotalArticles(0);
        setErrorMessageNewsList(noDataMessage);
      }
      setIsNewsListLoading(false);
    } catch (error) {
      setNewsList([]);
      setTotalArticles(0);
      setErrorMessageNewsList(apiErrorMessage);
      setIsNewsListLoading(false);
    }
  };

  const loadProductDetails = async (productName: string) => {
    // getDropdownProductData("ALL");
    setIsProductDetailsLoading(true);
    setProductDetails([]);
    setErrorMessageProductDetails("");

    try {
      const res = await apiResponse(
        "get",
        "get-product-details",
        [productName],
        {}
      );
      if (
        res.data.status === "SUCCESS" &&
        res.data.data &&
        res.data.data.length > 0
      ) {
        setProductDetails(res.data.data);
      } else {
        setProductDetails([]);
        setErrorMessageProductDetails(noDataMessage);
      }
      setIsProductDetailsLoading(false);
    } catch (error) {
      setProductDetails([]);
      setIsProductDetailsLoading(false);
      setErrorMessageProductDetails(apiErrorMessage);
    }
  };

  const handleClick = (productName: string) => {
    setShowBackBtn(false);
    setSelectedProductId(productName);

    navigate(`${BASE_ROUTE}/products/${productName}`, {
      state: { productName: productName, callingPath: location },
    });
  };

  const getUpperLimit = () => {
    const upperLimit = page * pageLimit;
    if (upperLimit > totalArticles) {
      return totalArticles;
    }
    return upperLimit;
  };

  const showBtn = () => {
    const pathName = location.state.callingPath
      ? location.state.callingPath.pathname
      : "";

    setShowBackBtn(!pathName.includes(`${BASE_ROUTE}/products`));
  };

  useEffect(() => {
    if (selectedProductId && selectedProductName) {
      setIsNewsListLoading(true);
      setErrorMessageNewsList("");
      loadProductNews(selectedProductName);
    }
  }, [page]);

  useEffect(() => {
    setShowBackBtn(false);
    if (
      authData &&
      Object.keys(authData).length > 0 &&
      authData?.externalAuthorization?.products &&
      props.productList.length > 0
    ) {
      setPage(1);
      setIsNewsListLoading(true);
      setErrorMessageNewsList("");
      setErrorMessageNewsList("");
      setIsProductDetailsLoading(true);
      setErrorMessageProductDetails("");
      setSelectedProductName("loading...");
      if (location.state) {
        showBtn();
      } else {
        setBackButtonRoute(-1);
      }
      if (location.state?.productName) {
        setSelectedProductId(location.state.productName);
      }

      const products = props.productList;
      if (productList.length === 0) {
        setProductList(products);
      }
      const params = window.location.pathname.split("products/");
      const productCode = params[1];
      const firstProduct = products[0];
      if (!productCode) {
        navigate(`${BASE_ROUTE}/products/${firstProduct.name}`, {
          state: { callingPath: location },
        });
      } else {
        const selectedProducts = products.filter((product: any) => {
          if (product.name.toLowerCase() === productCode.toLowerCase()) {
            return true;
          }
          return false;
        });

        if (selectedProducts.length === 0) {
          navigate(`${BASE_ROUTE}/products/${firstProduct.name}`, {
            state: { callingPath: location },
          });
        }
        const selectedProduct = selectedProducts[0];
        setIsNewsListLoading(true);
        loadProductNews(selectedProduct.name);
        loadProductDetails(selectedProduct.name);
      }
    }
  }, [location.state && location.state.productName]);

  return authData && authData?.externalAuthorization?.products ? (
    <div className="ext-individual-product-profile ext-row ops-px-4 ops-pb-2">
      {showBackBtn && (
        <div className="ext-product-page-navigation ops-mb-2">
          <BackBtn
            navigateFn={() => navigate(backButtonRoute)}
            backBtnText={getBackButtonText(location)}
          ></BackBtn>
        </div>
      )}
      <div className="ext-product-left-panel ext-col-lg-4 ext-col-md-12 ops-mb-4 ops-mb-sm-0 ops-mb-md-3 ops-mb-lg-0">
        <div className="ext-product-information">
          <Card cardHeader="Product Information ">
            {!isProductDetailsLoading && !errorMessageProductDetails ? (
              <>
                {productDetails.map((item: any, index: number) => {
                  return (
                    <div
                      className="ext-product-attributes ops-mx-2 ops-my-2"
                      key={index}
                    >
                      <div className="ext-product-img ext-row">
                        <div className="ext-col-lg-12 ext-col-md-12">
                          <img
                            src={item && item.image && item.image}
                            alt="Product name"
                            className="ext-product-logo"
                          />
                        </div>
                      </div>
                      <div className="ext-product-detail-information">
                        <div className="ext-attribute ext-row">
                          <div className="ext-col-sm-12 ext-col-md-12">
                            <div className="ext-info-title">
                              PRODUCT DETAILS
                            </div>
                            <div className="ext-info-value">
                              {item.product_details
                                ? item.product_details
                                : "N/A"}
                            </div>
                          </div>
                        </div>
                        <div className="ext-attribute ext-row">
                          <div className="ext-col-sm-12 ext-col-md-12">
                            <div className="ext-info-title">
                              THERAPEUTIC AREA
                            </div>
                            <div className="ext-info-value">
                              {item.product_therapeutic_area
                                ? item.product_therapeutic_area
                                : "N/A"}
                            </div>
                          </div>
                        </div>
                        <div className="ext-attribute ext-row">
                          <div className="ext-col-sm-12 ext-col-md-12">
                            <div className="ext-info-title">
                              COMPETITOR PRODUCTS
                            </div>
                            <div className="ext-info-value">
                              {item.product_competitor_products
                                ? item.product_competitor_products
                                : "N/A"}
                            </div>
                          </div>
                        </div>

                        <div className="ext-attribute ext-row">
                          <div className="ext-col-sm-12 ext-col-md-12">
                            <div className="ext-info-title">COMPANY</div>
                            <div className="ext-info-value">
                              {item.product_company
                                ? item.product_company
                                : "N/A"}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </>
            ) : (
              <></>
            )}

            {!isProductDetailsLoading && errorMessageProductDetails ? (
              <>
                <div className="ext-error-style">
                  <ErrorMessage
                    message={errorMessageProductDetails}
                    isIcon={false}
                  />
                </div>
              </>
            ) : (
              <></>
            )}
            {isProductDetailsLoading ? (
              <div
                className="ext-product-details-loader ext-spinner-style ext-d-flex ext-justify-content-center ext-products-articles-loader"
                role="status"
              >
                <div>
                  <Loader />
                </div>
              </div>
            ) : (
              <></>
            )}
          </Card>
        </div>
        <div className="ext-product-related-list ops-mt-6">
          
          <Card cardHeader="Amgen Products">
            <div className="ext-products-list ext-margin-left-20 ext-px-4 ext-py-3">
              {!props.isLoading &&
                !props.errorMessage &&
                productList.map((item: any, index: number) => {
                  let selected = "ext-products-name-selected ";
                  let notSelected = "ext-products-name";
                  const productName = item.name;

                  return (
                    <div
                      onClick={() => {
                        setIsNewsListLoading(true);
                        handleClick(productName);
                      }}
                      className={` ${
                        selectedProductId === productName
                          ? selected
                          : notSelected
                      } `}
                      key={item.name}
                    >
                      {productName == "lumakras" || productName == "tezspire" ? productName.toUpperCase() : formatText(productName)}
                    </div>
                  );
                })}
              {!props.isLoading && props.errorMessage && (
                <div className="ext-error-list-style">
                  <ErrorMessage message={props.errorMessage} isIcon={false} />
                </div>
              )}
              {props.isLoading && (
                <div
                  className="ext-spinner-list-style ext-d-flex ext-justify-content-center ops-mb-4 ops-mt-4"
                  role="status"
                >
                  <div>
                    <Loader />
                  </div>
                </div>
              )}
            </div>
          </Card>


        </div>
      </div>
      <div className="ext-product-right-panel ext-col-lg-8 ext-col-md-12">
        <Card
          cardHeader={
            selectedProductName === "loading..."
              ? formatText(selectedProductName)
              : `${formatText(selectedProductName)} in the news`
          }
        >
          <div className="ext-article-list-wrapper ext-border-top-0">
            <div className="ext-article-message ext-fst-italic ext-col-md-9 ops-ps-4 ops-py-2">
              {articlesMsg}
            </div>
            {!isNewsListLoading &&
              !errorMessageNewsList &&
              newsList?.map((article: any, index: number) => {
                let specialClassName =
                  index % 2 === 0
                    ? "ext-article-background-white"
                    : "ext-article-background-gray";

                const tags = getFormatedTagList(
                  navigate,
                  location,
                  article.company_names,
                  article.product_names,
                  article.topic_names,
                  article.kiq_names,
                  article.av_company_names,
                  article.corex_topic_names
                );

                return (
                  <ArticleCard
                    key={index}
                    redirectUrl={article.url}
                    specialClassName={specialClassName}
                    title={article.title}
                    articleId={article.article_id}
                    publishDate={article.published}
                    publishSource={article.source_name}
                    tagList={tags}
                    checkboxFlag={false}
                    trashFlag={false}
                  />
                );
              })}
            {!isNewsListLoading && errorMessageNewsList && (
              <div>
                <ErrorMessage message={errorMessageNewsList} isIcon={false} />
              </div>
            )}

            {isNewsListLoading && (
              <div
                className="ext-d-flex ext-products-articles-loader"
                role="status"
              >
                <div>
                  <Loader />
                </div>
              </div>
            )}
          </div>

          <div className="ext-article-pagination">
            <div className="container">
              <div className="ext-article-pagination-wrapper ops-mt-2">
                {!isNewsListLoading &&
                  newsList.length !== 0 &&
                  totalArticles !== 0 && (
                    <>
                      <div className="ext-text-pagination ext-col">
                        Showing {(page - 1) * pageLimit + 1} - {getUpperLimit()}{" "}
                        of {totalArticles} results
                      </div>
                      <div className="pagination-wrapper ext-col">
                        <Pagination
                          page={page}
                          totalPages={Math.ceil(totalArticles / pageLimit)}
                          handlePagination={handlePages}
                        />
                      </div>
                    </>
                  )}
              </div>
            </div>
          </div>
        </Card>
      </div>
    </div>
  ) : (
    <>{authData && <RequestPage />}</>
  );
};

export default Products;