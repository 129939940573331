
import './index.scss'; // Import global styles



// Layout component
const Layout = (props: any) => {
    return (
        <div className="external-sensing-outer-container">
            <div className="external-sensing-inner-container">
                {props.children}
            </div>
        </div>
    );
};

export default Layout;