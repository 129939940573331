import { useRef, useState, useEffect } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import PaginationHeader from "../pagination-header";
import "./index.scss";
import EarningsDropdown from "../earnings-dropdown";

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 3,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

type Props = {
  totalItems: any,
  children: any,
  timeScaleDataMinusFullYear: any,
  getKeyInsightsData: (quarter: string, year: string) => void,
  investorRelationsYear: string,
  investorRelationsQuarter: string,
  setInvestorRelationsYear: (year: string) => void,
  setInvestorRelationsQuarter: (quarter: string) => void,
}
const CustomCarousel = (props: Props) => {
  const 
  { 
    timeScaleDataMinusFullYear, 
    investorRelationsYear, 
    investorRelationsQuarter, 
    getKeyInsightsData,
    setInvestorRelationsYear, 
    setInvestorRelationsQuarter 
  } = props;

  const [paginationNumber, setPaginationNumber] = useState({
    startNumber: 1,
    endNumber: getPageLimit(),
  });

  const carouselRef = useRef<any>();
  const totalProducts = props?.totalItems;

  const getSlideCount = (itemsOnSlide: number) => {
    for (let i = 1; i <= totalProducts; i++) {
      if (itemsOnSlide === i) {
        return totalProducts - i + 1;
      }
    }
    return 1;
  };

  function getPageLimit() {
    if (window.innerWidth > 0 && window.innerWidth <= 464) {
      return 1;
    } else if (window.innerWidth > 464 && window.innerWidth <= 1024) {
      return 2;
    } else {
      return 3;
    }
  }

  const handlePageChange = (action: string) => {
    const totalSlides = getSlideCount(carouselRef.current.state.slidesToShow);
    const currentSlide = carouselRef.current.state.currentSlide;
    let newSlide = currentSlide;
    if (action === "Increment") {
      if (currentSlide + 1 > totalSlides - 1) {
        newSlide = 0;
      } else {
        newSlide = currentSlide + 1;
      }
    } else {
      if (currentSlide - 1 < 0) {
        newSlide = totalSlides - 1;
      } else {
        newSlide = currentSlide - 1;
      }
    }
    carouselRef.current.goToSlide(newSlide, false);
  };

  const resizeObserver = new ResizeObserver(() => {
    setTimeout(() => {
      const currentSlide = carouselRef?.current?.state?.currentSlide;
      carouselRef?.current?.goToSlide(currentSlide, false);
    }, 200);
  });

  useEffect(() => {
    const sliderElement: any = document.getElementById(
      "custom-carousel-wrapper"
    );
    resizeObserver && resizeObserver.observe(sliderElement);
  }, []);

  return (
    <div className="ext-coursel-card-container">
      <div className="ext-d-flex">
        <div className="coursel-filter-container">
          <div className="coursel-filter-dropdown-container">
            <EarningsDropdown
              label="YEAR"
              options={Object.keys(timeScaleDataMinusFullYear).reverse()}
              selectedValue={investorRelationsYear}
              onMenuItemClick={(event)=> {
                setInvestorRelationsYear(event.key)
                setInvestorRelationsQuarter(timeScaleDataMinusFullYear[event?.key][0])
                getKeyInsightsData(timeScaleDataMinusFullYear[event?.key][0], event?.key);
              }}

            />
            <EarningsDropdown
              label="TIMEFRAME"
              options={timeScaleDataMinusFullYear[investorRelationsYear]}
              selectedValue={investorRelationsQuarter}
              onMenuItemClick={ (event)=> {
                setInvestorRelationsQuarter(event?.key)
                getKeyInsightsData(event?.key, investorRelationsYear);
              }}
            />
          </div>
          <PaginationHeader
            handlePageChange={handlePageChange}
            totalProducts={totalProducts}
            paginationNumber={paginationNumber}
          />
        </div>
      </div>
      <div className="ops-mt-3">
        <Carousel
          ref={carouselRef}
          arrows={false}
          responsive={responsive}
          itemClass={"brand-glance-item"}
          centerMode={false}
          swipeable={true}
          draggable={true}
          partialVisible={false}
          afterChange={(previousSlide, { currentSlide }) => {
            setPaginationNumber({
              startNumber: currentSlide + 1,
              endNumber: currentSlide + carouselRef.current.state.slidesToShow,
            });
          }}
        >
          {props.children}
        </Carousel>
      </div>
    </div>
  );
};

export default CustomCarousel;
