import { Button } from "@gitlab-rtsensing/component-library";
import ExclamationIcon from "../../assets/icons/exclamation-icon";
import axios from "axios";
import { getApiUrl } from "../../api/api-config";
import { getOktaToken } from "../../utils/commonMethods";
import {CustomModal} from "../modal";


type DeleteConfirmationPros = {
  isOpenDeleteModal: boolean;
  setIsOpenDeleteModal?: any;
  setClosedTagsModalList?: any;
  selectedNote?: any;
  getNotesList?: any;
};

const ConfirmationModal = (props: DeleteConfirmationPros) => {
const oktaToken = getOktaToken();
const { isOpenDeleteModal, setIsOpenDeleteModal, setClosedTagsModalList, selectedNote, getNotesList} = props;
  
const deleteNote = async (note: any) => {
try {
    const res = await axios({
    method: "delete",
    url: `${getApiUrl("earning-delete-notes")}?note_id=${note.note_id}`,
    data: [],
    headers: {
        Authorization: `Bearer ${oktaToken}`,
    },
    });
    if (res && res.data && res.data.status === "SUCCESS") {
    setIsOpenDeleteModal(false);
    setClosedTagsModalList(false);
    getNotesList();
    } else {
        setIsOpenDeleteModal(true);
    setClosedTagsModalList(false);
    }
} catch (error) {
    setIsOpenDeleteModal(true);
    setClosedTagsModalList(false);  
}
};

  return (
    <CustomModal
      opened={isOpenDeleteModal}
      onClose={() => {
        setIsOpenDeleteModal(false);
        setClosedTagsModalList(true);
      }}
    >
        <CustomModal.Header>
          <CustomModal.Title title="Confirm Deletion" />
        </CustomModal.Header>
        <div className="ext-status-window ops-mt-5">
          <div>
            <ExclamationIcon />
          </div>{" "}
          <div style={{ lineHeight: "17px", paddingLeft: "4px" }}>
            Are you sure you want to delete this insight?
          </div>
        </div>
        <div className="ext-delete-text ops-mt-5">
          This action cannot be undone.
        </div>
        <div className="ext-add-note-modal-footer ops-mt-5">
          <Button
            label="CANCEL"
            type="secondary"
            className="ext-add-note-btn"
            onClick={() => {
              setIsOpenDeleteModal(false);
              setClosedTagsModalList(true);
            }}
          />
          <div style={{ paddingLeft: "8px" }}>
            <Button
              label={"DELETE INSIGHT"}
              type="secondary"
              className="ext-delete-btn"
              onClick={() => {
                deleteNote(selectedNote);
              }}
            />
          </div>
        </div>
    </CustomModal>
  );
};

export default ConfirmationModal;
