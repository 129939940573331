interface Props {
  fill: string;
  classname?: string;
}

const LessThan = ({ fill, classname }: Props) => {
  return (
    <svg
      className={`${classname} ops-chevron-icon ops-chevron-icon--LEFT`}
      width="25"
      height="10"
      viewBox="0 0 13 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.34314 9.50564L9.83288 0.688937C10.2904 0.263844 11.0326 0.263844 11.4901 0.688937L12.597 1.71742C13.0541 2.14205 13.0546 2.82982 12.599 3.25537L5.078 10.2755L12.5985 17.2961C13.0546 17.7217 13.0536 18.4094 12.5966 18.8341L11.4896 19.8626C11.0321 20.2877 10.2899 20.2877 9.8324 19.8626L0.34314 11.0454C-0.11438 10.6203 -0.11438 9.93073 0.34314 9.50564Z"
        fill={fill}
      ></path>
    </svg>
  );
};

export default LessThan;
