// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal-header_ops-modal-header__-j\\+Ol{position:relative;border-bottom:1px solid var(--ops-gray-300);min-height:27px}.modal-header_ops-modal-header-close__thgGT{border:none;position:absolute;top:0;right:0;background:rgba(0,0,0,0);height:100%;cursor:pointer}`, "",{"version":3,"sources":["webpack://./src/components/modal/modal-header/modal-header.module.scss"],"names":[],"mappings":"AAAA,uCACE,iBAAA,CACA,2CAAA,CACA,eAAA,CAGF,4CACE,WAAA,CACA,iBAAA,CACA,KAAA,CACA,OAAA,CACA,wBAAA,CACA,WAAA,CACA,cAAA","sourcesContent":[".ops-modal-header {\n  position: relative;\n  border-bottom: 1px solid var(--ops-gray-300);\n  min-height: 27px;\n}\n\n.ops-modal-header-close {\n  border: none;\n  position: absolute;\n  top: 0;\n  right: 0;\n  background: transparent;\n  height: 100%;\n  cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ops-modal-header": `modal-header_ops-modal-header__-j+Ol`,
	"ops-modal-header-close": `modal-header_ops-modal-header-close__thgGT`
};
export default ___CSS_LOADER_EXPORT___;
