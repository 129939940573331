/* eslint-disable react/jsx-key */
import React, { useContext, useEffect, useState } from "react";
import Card from "../../components/card";
import { Button, Modal } from "@gitlab-rtsensing/component-library";
import ArticleCard from "../../components/article-card";
import { Pagination } from "../../components/pagination";
import "./index.scss";
import {
  apiResponse,
  formatAmgenVenture,
  getFormatedTagList,
} from "../../utils/commonMethods";
import {
  apiErrorMessage,
  noDataMessage,
} from "../../assets/errorMessageConstants";
import { KIQListType } from "../../api/get-kiq-data-dt";
import ErrorMessage from "../../components/error-message";
import { Loader } from "@gitlab-rtsensing/component-library";
import { AppContext } from "../../utils/app-context";
import RequestPage from "../../components/request-access-page";
import { useLocation, useNavigate } from "react-router-dom";
import BackBtn from "../../components/back-btn";
import PlusIcon from "../../assets/icons/plus-icon";
import TagsModal from "../../components/tags-modal";
import Tag from "../../components/tag";
import CustomToggle from "../../components/custom-toggle";
import { BASE_ROUTE, getBackButtonText } from '../../utils/routing'

type Props = {
  kiqList: any;
  isLoading: boolean;
  errorMessage: string;
  setNewKiqBuilderList: any;
};

type SelectedTagsType = {
  company: string[];
  product: string[];
  av_company: string[];
  kiq: string[];
  topic: string[];
};

const KIQBUILDER: React.FC<Props> = (props: Props) => {
  const [selectedKiqName, setSelectedKiqName] = useState("");
  const [selectedKiqId, setSelectedKiqId] = useState("");
  const [KiqList, setKiqList] = useState<KIQListType[]>([]);
  const [isNewsListLoading, setIsNewsListLoading] = useState<boolean>(false);
  const [showBackBtn, setShowBackBtn] = useState(false);
  const [backButtonRoute, setBackButtonRoute] = useState<any>(-1);
  const [page, setPage] = useState(1);
  const [newsList, setNewsList] = useState([]);
  const [totalArticles, setTotalArticles] = useState(0);
  const [errorMessageNewsList, setErrorMessageNewsList] = useState<string>("");
  const [collectionName, setSelectedCollectionName] = useState("");
  const [opentagsModal, setOpenTagsModal] = useState(false);
  const [tagList, setTagList] = useState<any[]>([]);
  const [selectedIndex, setSelectedIndex] = useState(100);
  const [initialDisplayTags, setInitialDisplayTags] = useState<any>([]);
  const [queryName, setQueryName] = useState("");
  const [operationArray, setOperationArray] = useState(["OR"]);
  const [selectedTags, setSelectedTags] = useState<SelectedTagsType>({
    company: [],
    product: [],
    av_company: [],
    kiq: [],
    topic: [],
  });
  const pageLimit = 50;
  const [refreshFlag, setRefreshFlag] = useState(true);
  const [selectedKiqQuery, setSelectedKiqQuery] = useState<any>({});
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const [showKiqdeleteLoader, setShowKiqBuilderLoader] = useState(false);

  const [editKiq, setEditKiq] = useState(true);
  const [createKiqStart, setCreateKiqStart] = useState(false);
  const [inititalFlag, setInitialFlag] = useState(true);
  const [initialLoad, setInitialLoad] = useState(false);
  const [errorRespose, setErrorResponse] = useState("");
  const [showLoader, setShowLoader] = useState(false);

  const navigate = useNavigate();
  const location: any = useLocation();

  const { authData } = useContext(AppContext);
  const loadKiqDetails = async (
    kiqId: string,
    kiqName: string,
    collectionId: string
  ) => {
    setSelectedKiqId(kiqId);
    setSelectedKiqName(kiqName);
    setSelectedCollectionName(collectionId);
    setNewsList([]);
    setIsNewsListLoading(true);
    setErrorMessageNewsList("");
    setTotalArticles(0);

    const payload = {
      sort_by_value: "desc",
      page_offset: page - 1,
      limit: pageLimit,
      collection_data: [collectionId],
    };
    try {
      const res = await apiResponse(
        "post",
        "get-quired-news-article",
        [],
        payload
      );
      if (
        res.data.status === "SUCCESS" &&
        res.data.data.response &&
        res.data.data.count !== 0
      ) {
        setNewsList(res.data.data.response);
        setTotalArticles(res.data.data.count);
        setErrorMessageNewsList("");
      } else {
        setNewsList([]);
        setTotalArticles(0);
        setErrorMessageNewsList(noDataMessage);
      }
      setIsNewsListLoading(false);
    } catch (error) {
      setNewsList([]);
      setTotalArticles(0);
      setErrorMessageNewsList(apiErrorMessage);
      setIsNewsListLoading(false);
    }
  };

  const getAllTags = async () => {
    try {
      const allTags = await apiResponse("get", "get-all-tags", [], {});
      if (allTags.data.status === "SUCCESS" && allTags.data.data) {
        setTagList(allTags.data.data);
      } else {
        setTagList([]);
      }
    } catch (error) {
      setTagList([]);
    }
  };

  const fetchAllKiqQuery = async () => {
    try {
      const res = await apiResponse("get", "get-kiq-builder-list", [], {});
      if (res.data.status === "SUCCESS") {
        const kiqDataList = res.data.data;
        props.setNewKiqBuilderList(kiqDataList);
        navigate(`${BASE_ROUTE}/builder`, {
          state: { kiqName: name, callingPath: location },
        });
        // setSaveQueryFlag(false);
        setEditKiq(true);
        setShowKiqBuilderLoader(false);
      }
    } catch (error) {
      setShowKiqBuilderLoader(false);
    }
  };

  const handleClick = (id: string, name: string) => {
    setShowBackBtn(false);
    navigate(`${BASE_ROUTE}/builder/${id}`, {
      state: { kiqName: name, callingPath: location },
    });
  };

  const handlePages = async (updatePage: number) => {
    setPage(updatePage);
  };

  const savedQuery = async (query: string) => {
    const payload = {
      collection_name: queryName,
      collection_data: [`${query}`],
    };
    try {
      setShowLoader(true);
      let res = await apiResponse("post", "save-kiq-query", [], payload);
      if (res.data.status === "SUCCESS") {
        setShowLoader(true);
        fetchAllKiqQuery();
      } else {
        setShowLoader(false);
        setErrorResponse(res.data.message);
      }
    } catch (error) {
      queryName.length > 0 ? 
      setErrorResponse("Collection already exist, please use different name") : 
      setErrorResponse("Please add query name");
      setShowLoader(false);
    }
  };

  const saveEditedQuery = async (query: string) => {
    const payload = {
      collection_name: queryName,
      collection_data: [`${query}`],
      collection_id: selectedKiqQuery.collection_id,
    };
    const res = await apiResponse("post", "edit-kiq-query", [], payload);
    try {
      if (res.data.status === "SUCCESS") {
        setShowLoader(true);
        fetchAllKiqQuery();
      } else {
        setErrorResponse(res.data.message);
        setShowLoader(false);
      }
    } catch (error) {
      setShowLoader(false);
    }
  };

  const   getCompanyFullName = (companyTicker: string) => {
    return(
    tagList && tagList.length > 0 && tagList.map((item, index) => {
    return(
    item.category_name === "company" &&
      item.values.map((ele:string, index: number) => {
        const spliteValue = ele.split("$$");
          if(companyTicker === spliteValue[2]){
           return (spliteValue[1])
        }
      }))
    }
  ))
  }

  const selectedUserTags = (data: {
    category: "company" | "product" | "kiq" | "av_company" | "topic";
    tag_name: string;
  }) => {
    const fieldName = data.category;
    const tag = data.tag_name;
    const existingTags = { ...selectedTags };
    const selectedCategoryTags = existingTags[fieldName];
    let updatedSelectedCategoryTags = [...selectedCategoryTags];
    if (selectedCategoryTags.includes(tag)) {
      updatedSelectedCategoryTags = updatedSelectedCategoryTags.filter(
        (item) => {
          return item !== tag;
        }
      );
    } else {
      updatedSelectedCategoryTags.push(tag);
    }
    existingTags[fieldName] = updatedSelectedCategoryTags;
    setSelectedTags({ ...existingTags });
  };

  const generateString = (dataString: any) => {
    const result: any = [];
    const queryString: any = [];

    dataString.map((firstArray: any, index: number) => {
      const tempArray = [];
      for (const key in firstArray) {
        tempArray.push(...firstArray[key]);
      }
      result.push(tempArray);
    });

    result.length > 0 &&
      result.map((element: any, index: number) => {
        const subString = `(${element.join(` ${operationArray[index]} `)})`;
        queryString.push(subString);
      });

    const regexExp = /\((\W+)\)/g;
    const regexForString = /\(\) AND | AND \(\) | \(\) AND \(\) /g;
    const revisedString = queryString.join(" AND ").replace(regexExp, "($1)");
    const finalString = revisedString.replace(regexForString, " ");

    return finalString;
  };

  const saveQuery = (data: any) => {
    if(queryName.length === 0) {
      setErrorResponse("please add query name")
    }else{
      const regexString = generateString(data);
      savedQuery(regexString);
    }
  };

  const editChangesSave = (data: any) => {
    if(queryName.length === 0) {
      setErrorResponse("please add query name")
    }
    else {
      setShowLoader(true);
      const regexString = generateString(data);
      saveEditedQuery(regexString);
    }
  };

  const getUpperLimit = () => {
    const upperLimit = page * pageLimit;
    if (upperLimit > totalArticles) {
      return totalArticles;
    }
    return upperLimit;
  };

  const showBtn = () => {
    const pathName = location.state.callingPath
      ? location.state.callingPath.pathname
      : "";

    setShowBackBtn(!pathName.includes(`${BASE_ROUTE}/builder`));
  };

  const onClickedAddGroups = () => {
    operationArray.length > 0 && operationArray.push("OR");
    setOpenTagsModal(true);
  };

  const isObjectValueEmpty = (data:any) => {
    for (let key in data) {
      if( data[key].length > 0 ){
        return false
      }
    }
    return true 
  } 

  const onUserClosedModal = (result: boolean) => {
    //currently it is 100 for new add group
    const isEmpty = isObjectValueEmpty(selectedTags);
    selectedIndex === 100  //if this is not 100 that means flow is for edit
      ? !isEmpty && initialDisplayTags.push(selectedTags) && operationArray.push("OR")
      : isEmpty ? initialDisplayTags.splice(selectedIndex,1) && operationArray.splice(selectedIndex,1): 
      initialDisplayTags.splice(selectedIndex, 1, selectedTags) && operationArray.splice(selectedIndex, 1, operationArray[selectedIndex]); //check for empty , if emp pop that elemt from array
    setSelectedTags({
      company: [],
      product: [],
      av_company: [],
      kiq: [],
      topic: [],
    });
    setOpenTagsModal(result);
    setSelectedIndex(100);
  };

  useEffect(() => {
    if (selectedKiqId && selectedKiqName && collectionName) {
      setIsNewsListLoading(true);
      setErrorMessageNewsList("");
      loadKiqDetails(selectedKiqId, selectedKiqName, collectionName);
    }
  }, [page]);

  useEffect(() => {
    setShowBackBtn(false);
    props && props.kiqList && props.kiqList.length === 0
      ? setInitialLoad(true)
      : setInitialLoad(false);
    if (
      authData &&
      Object.keys(authData).length > 0 &&
      authData?.externalAuthorization?.kiqBuilder &&
      props.kiqList.length > 0
    ) {
      setPage(1);

      if (location.state) {
        showBtn();
      } else {
        setBackButtonRoute(-1);
      }
      const kiqs = props.kiqList;
      setKiqList(kiqs);
      const params = window.location.pathname.split("builder/");
      const kiqCode = params[1];
      const firstKiq = kiqs[0];
      if (!kiqCode) {
        navigate(`${BASE_ROUTE}/builder/${firstKiq.collection_id}`, {
          state: { callingPath: location },
        });
      } else {
        const selectedKiqs = kiqs.filter((kiq: any) => {
          if (kiq.collection_id == kiqCode) {
            return true;
          }
          return false;
        });

        if (selectedKiqs.length === 0) {
          navigate(`${BASE_ROUTE}/builder/${firstKiq.collection_id}`, {
            state: { callingPath: location },
          });
        }
        const selectedKiq = selectedKiqs[0];
        setSelectedKiqQuery(selectedKiq);
        loadKiqDetails(
          selectedKiq.collection_id,
          selectedKiq.collection_name,
          selectedKiq.collection_data
        );
      }
    }
  }, [location.state && location.state]);

  useEffect(() => {
    getAllTags();
  }, []);

  useEffect(() => {
    const objectKeys = ["company", "kiq", "av_company", "product", "topic"];
    if (!editKiq) {
      const operationArraySet: any = [];
      const operationArray: any = [];
      selectedKiqQuery.collection_name &&
        setQueryName(selectedKiqQuery.collection_name);
      selectedKiqQuery.collection_details &&
        selectedKiqQuery.collection_details.length > 0 &&
        selectedKiqQuery.collection_details.map((item: any, index: number) => {
          item.separator === "AND"
            ? operationArray.push("AND")
            : operationArray.push("OR");
          item.tag_values.map((ele: any) => {
            const objectKeyCollection = Object.keys(ele);
            const keySet = objectKeys.filter(
              (x) => objectKeyCollection.indexOf(x) === -1
            );
            keySet.map((element: string) => (ele[element] = []));
          });

          operationArraySet.push(item.tag_values[0]);
        });

      operationArray.length > 0 && setOperationArray(operationArray);
      operationArraySet.length > 0 && setInitialDisplayTags(operationArraySet);
    }
  }, [editKiq]);

  useEffect(() => {
    setRefreshFlag(true);
  }, [refreshFlag]);

  const toggleButton = () => {
    setEditKiq(!editKiq);
    setOperationArray(["OR"]);
    setInitialDisplayTags([]);
  };

  const backToPreviousPage = () => {
    setOperationArray(["OR"]);
    setInitialDisplayTags([]);
    navigate(`${BASE_ROUTE}/builder`);
  };
  const onClickAddTags = (index: number) => {
    setSelectedIndex(index);
    initialDisplayTags.length !== 0 &&
      setSelectedTags(initialDisplayTags[index]);
    setOpenTagsModal(true);
  };

  const removedQuery = (index: number) => {
    setInitialDisplayTags((oldValues: any) => {
      return oldValues.filter((_: any, i: number) => i !== index);
    });

    setOperationArray((oldValues: any) => {
      return oldValues.filter((_: any, i: number) => i !== index);
    });
    initialDisplayTags.length === 0 && setInitialDisplayTags([]);
    operationArray.length === 0 && operationArray.push("OR");
  };

  const changeToogleValue = (data: any, index: number) => {
    operationArray[index] = data;
    setRefreshFlag(false);
  };

  const deletedKiq = async (id: string) => {
    setOpenConfirmationModal(false);
    setShowKiqBuilderLoader(true);
    const response = await apiResponse("delete", "delete-kiq-query", [], {
      "collection_id": id,
    });
    try {
      if (response.data.status === "SUCCESS") {
        fetchAllKiqQuery();
      } else {
        setOpenConfirmationModal(true);
        setShowKiqBuilderLoader(false);
      }
    } catch (error) {
      setOpenConfirmationModal(true);
      setShowKiqBuilderLoader(false);
    }
  };

  const openEditSection = () => {
    setCreateKiqStart(true);
    setInitialFlag(false);
  };

  useEffect(() => {
    setTimeout(() => {
      setErrorResponse("");
    }, 3000);
  }, [errorRespose]);


  return authData && authData?.externalAuthorization?.kiqBuilder ? (
    <div className="ext-news-container ext-row ops-px-4 ops-my-2">
      {showBackBtn && (
        <div className="kiq-page-navigation ops-mb-2">
          <BackBtn
            navigateFn={() => navigate(backButtonRoute)}
            backBtnText={getBackButtonText(location)}
          ></BackBtn>
        </div>
      )}
      <div className="ext-left-panel ext-col-lg-3 ext-col-md-12 ops-mb-4 ops-mb-sm-0 ops-mb-md-3 ops-mb-lg-0">
        <Card cardHeader="Saved Queries">
          <div className="ops-my-2 ops-ms-4 ext-fw-bold ext-amgen-color ext-kiqs-list">
            {!props.isLoading &&
              !props.errorMessage &&
              KiqList.map((item: any) => {
                return (
                  <div
                    onClick={() => {
                      // setIsNewsListLoading(true);
                      handleClick(item.collection_id, item.collection_name);
                    }}
                    className={` ${
                      selectedKiqId === item.collection_id
                        ? "ext-kiq-name-selected "
                        : "ext-kiq-name"
                    } `}
                    key={item.collection_name}
                  >
                    {item.collection_name}
                  </div>
                );
              })}

            {!props.isLoading &&
              !props.errorMessage &&
              props.kiqList.length === 0 && (
                <div className="ext-kiqbuilder-text ops-my-4">
                  Create a custom KIQ based on your topics of interest using the
                  KIQ Builder panel to the right.
                </div>
              )}

            {!props.isLoading && props.errorMessage && (
              <ErrorMessage isIcon={false} message={props.errorMessage} />
            )}
            {props.isLoading && (
              <div
                className="ext-spinner-topics-style ext-d-flex ext-justify-content-center ext-topic-loader"
                role="status"
              >
                <Loader />
              </div>
            )}
          </div>
          <div className="ops-mx-3 ops-mt-5 ext-d-flex ext-justify-content-center">
            {props.kiqList.length > 0 && editKiq && (
              <Button
                type="primary"
                label="NEW QUERY +"
                className="ext-kiqbuilder-save-query"
                onClick={() => openEditSection()}
              />
            )}
          </div>
        </Card>
      </div>
      {
        <div className="ext-right-panel ext-col-lg-9 ext-col-md-12 ops-mb-4 ops-mb-sm-0 ">
          <>
            {props.kiqList.length === 0 && initialLoad && (
              <Card cardHeader="Key Intelligent Question Builder">
                <div className="ext-kiq-create-container">
                  <div className="ext-row">
                    <div className="ops-mx-3">
                      <span className="ext-kiqbuild-header">Name:</span>
                      <span className="ext-kiq-input-textbar">
                        <input
                          required
                          type="text"
                          placeholder="My Query"
                          className="ext-kiqbuild-textbox"
                          onChange={(e) => setQueryName(e.target.value)}
                        />
                      </span>
                    </div>
                    {errorRespose.length > 0 && <div className="ext-buildkiq-error-msg">{errorRespose}</div>}
                  </div>

                  <div className="ext-kiqbuild-wrapper">
                  <div className="ext-kiqbuild-wrapper">
                          {initialDisplayTags &&
                          refreshFlag &&
                          initialDisplayTags.length > 0
                            ? initialDisplayTags.map(
                                (arraySet: any, index: number) => {
                                  return (
                                    <div className="ext-kiqbuild-create-section ops-mx-3 ops-my-3">
                                      <div className="ext-kiqbuild-query-header ops-mt-3 ops-mx-2 ops-mb-2">
                                        <span className="ext-kiqbuilder-header-text ops-mx-5 ops-mt-3 ext-d-flex">
                                          <span className="ops-mt-2 ops-mx-1">
                                            include{" "}
                                          </span>

                                          <CustomToggle
                                            selectedToggleValue={
                                              operationArray[index]
                                            }
                                            changesToggleState={(value: any) =>
                                              changeToogleValue(value, index)
                                            }
                                          />
                                          <span className="ops-mt-2 ops-mx-1">
                                            {" "}
                                            of this tags - on edit
                                          </span>
                                        </span>
                                        {initialDisplayTags.length > 0 && (
                                          <Button
                                            label="remove group"
                                            onClick={() => {
                                              removedQuery(index);
                                            }}
                                            type="secondary"
                                            className="ops-mx-5 ops-mt-4 ext-kiqbuilder-remove-group"
                                          />
                                        )}
                                      </div>
                                      <hr className="ext-header-styling" />

                                      <div className="ext-kiqbuiler-display-tag ops-mx-4">
                                        {initialDisplayTags.length > 0 &&
                                          Object.keys(arraySet).map((key) =>
                                          key === 'company' && arraySet[key].length > 0 && arraySet[key].map(
                                              (item: string) => {
                                                const reviedValue = item.split("$$")
                                                return (
                                                <Tag
                                                code={reviedValue[0]}
                                                key={reviedValue[0]}
                                                name={getCompanyFullName(reviedValue[0])}
                                                type={key}
                                                canClick={true}
                                                onClick={() => {}}
                                                canFilter={true}
                                                onFilter={() => {}}
                                                isFilteredOnTag={true}
                                                showMinusIcon={true}
                                                showAmgenBlueColor={true}
                                                />)
                                              }
                                            )
                                          )}
                                          {initialDisplayTags.length > 0 &&
                                          Object.keys(arraySet).map((key) =>
                                          key !== 'company' && arraySet[key].length > 0 && arraySet[key].map(
                                              (item: string) => (
                                                <Tag
                                                  code={item}
                                                  key={item}
                                                  name={formatAmgenVenture(item)}
                                                  type={key}
                                                  canClick={true}
                                                  onClick={() => {}}
                                                  canFilter={true}
                                                  onFilter={() => {}}
                                                  isFilteredOnTag={true}
                                                  showMinusIcon={true}
                                                  showAmgenBlueColor={true}
                                                />
                                              )
                                            )
                                          )}
                                      </div>

                                      <div className="ext-create-tags-query ops-mx-4 ops-my-3">
                                        <Button
                                          icon={<PlusIcon fill="#3773d2" />}
                                          iconPosition="right"
                                          label="add tags"
                                          className="ext-kiqbuild-button-border"
                                          type="secondary"
                                          onClick={() => {
                                            onClickAddTags(index);
                                          }}
                                        />
                                        <TagsModal
                                          headerName={"Select Tags"}
                                          data={tagList}
                                          isOpen={opentagsModal}
                                          selectedTags={selectedTags}
                                          setClosedTagsModalList={(
                                            result: boolean
                                          ) => onUserClosedModal(result)}
                                          setUserSelectedTags={(
                                            updateState: any
                                          ) => selectedUserTags(updateState)}
                                        />
                                      </div>
                                    </div>
                                  );
                                }
                              )
                            : !showKiqdeleteLoader && (
                              showLoader ? <Loader/> :
                                <div className="ext-kiqbuild-create-section ops-mx-3 ops-my-3">
                                  <div className="ext-kiqbuild-query-header ops-mt-3 ops-mx-2 ops-mb-2">
                                    <span className="ext-kiqbuilder-header-text ops-mx-5 ops-mt-3 ext-d-flex">
                                      <span className="ops-mt-2 ops-mx-1">
                                        include{" "}
                                      </span>

                                      <CustomToggle
                                        selectedToggleValue={operationArray[0]}
                                        changesToggleState={(value: any) =>
                                          changeToogleValue(value, 0)
                                        }
                                      />
                                      <span className="ops-mt-2 ops-mx-1">
                                        of this tags
                                      </span>
                                    </span>
                                  </div>
                                  <hr className="ext-header-styling" />

                                  <div className="ext-custom-tags-wrapper ops-mx-4">
                                    {initialDisplayTags.length > 0 && (
                                      <>
                                        {initialDisplayTags.map(
                                          (tagsBlock: any) =>
                                            Object.keys(tagsBlock).map((key) =>
                                             key === 'company' && tagsBlock[key].length > 0 && tagsBlock[key].map(
                                                (item: string) => {
                                                  
                                                  const reviedValue = item.split("$$")
                                                return (
                                                <Tag
                                                code={reviedValue[0]}
                                                key={reviedValue[0]}
                                                name={getCompanyFullName(reviedValue[0])}
                                                type={key}
                                                canClick={true}
                                                onClick={() => {}}
                                                canFilter={true}
                                                onFilter={() => {}}
                                                isFilteredOnTag={true}
                                                showMinusIcon={true}
                                                showAmgenBlueColor={false}
                                                />)
                                                  }
                                              )
                                            )
                                        )}
                                      </>
                                    )}
                                    {initialDisplayTags.length > 0 && (
                                      <>
                                        {initialDisplayTags.map(
                                          (tagsBlock: any) =>
                                            Object.keys(tagsBlock).map((key) =>
                                            key !== 'company' &&  tagsBlock[key].length > 0 && tagsBlock[key].map(
                                                (item: string) => (
                                                  <Tag
                                                    code={item}
                                                    key={item}
                                                    name={formatAmgenVenture(item)}
                                                    type={tagsBlock}
                                                    canClick={true}
                                                    onClick={() => {}}
                                                    canFilter={true}
                                                    onFilter={() => {}}
                                                    isFilteredOnTag={true}
                                                    showMinusIcon={true}
                                                    showAmgenBlueColor={false}
                                                  />
                                                )
                                              )
                                            )
                                        )}
                                      </>
                                    )}
                                  </div>

                                  <div className="ext-create-tags-query ops-mx-4 ops-my-3">
                                    <Button
                                      icon={<PlusIcon fill="#3773d2" />}
                                      iconPosition="right"
                                      label="add tags"
                                      className="ext-kiqbuild-button-border"
                                      type="secondary"
                                      onClick={() => {
                                        setOpenTagsModal(true);
                                      }}
                                    />
                                    <TagsModal
                                      headerName={"Select Tags"}
                                      data={tagList}
                                      isOpen={opentagsModal}
                                      selectedTags={selectedTags}
                                      setClosedTagsModalList={(
                                        result: boolean
                                      ) => onUserClosedModal(result)}
                                      setUserSelectedTags={(updateState: any) =>
                                        selectedUserTags(updateState)
                                      }
                                    />
                                  </div>
                                </div>
                                    
                              )}

                          {initialDisplayTags.length > 0 &&
                            !showKiqdeleteLoader && (
                              <div className="ops-mx-5">
                                <Button
                                  icon={<PlusIcon fill="#3773d2" />}
                                  iconPosition="right"
                                  label="add groups"
                                  type="secondary"
                                  className="ext-kiqbuild-border-btn-color"
                                  onClick={() => {
                                    onClickedAddGroups();
                                  }}
                                />
                              </div>
                            )}
                        </div>
                  </div>
                  <div className="ext-kiqbuild-footer">
                    <hr className="ext-header-styling" />
                    <div className="ext-mt-5 ext-mb-5 ext-d-flex ops-mx-5 ext-justify-content-end ext-kiqbuild-footer-styling">
                      <div>
                        {initialDisplayTags.length > 0 ? (
                          <Button
                            label="save query"
                            className="ops-mx-4 ext-buildkiq-save-query"
                            onClick={() => {
                              saveQuery(initialDisplayTags);
                            }}
                            type="primary"
                          />
                        ) : (
                          <Button
                            disabled
                            label="save query"
                            className="ext-float-item-right"
                            onClick={() => {}}
                            type="primary"
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </Card>
            )}

            {inititalFlag && !initialLoad && (
              <>
                <Card
                  cardHeader={editKiq ? selectedKiqName : "Edit Key Intelligent Question"}
                  isEditable={editKiq}
                  toggleQueryButon={toggleButton}
                  deleteAction={(flag: boolean) =>
                    setOpenConfirmationModal(flag)
                  }
                >
                  <>
                    
                    {showKiqdeleteLoader && <Loader />}
                    {/* this is edit part */}
                    {!editKiq && !showKiqdeleteLoader ? (
                      showLoader ? <Loader /> : 
                      <div className="ext-kiq-create-container">
                        <div className="ext-row">
                          <div className="ops-mx-3">
                            <span className="ext-kiqbuild-header">
                              KIQ Name:
                            </span>
                            <span className="ext-kiq-input-textbar">
                              <input
                                required
                                type="text"
                                value={queryName}
                                placeholder="My Query"
                                className="ext-kiqbuild-textbox"
                                onChange={(e) => setQueryName(e.target.value)}
                              />
                            </span>
                            {errorRespose.length > 0 && <div className="ext-buildkiq-error-msg">{errorRespose}</div>}
                          </div>
                        </div>

                        <div className="ext-kiqbuild-wrapper">
                          {initialDisplayTags &&
                          refreshFlag &&
                          initialDisplayTags.length > 0
                            ? initialDisplayTags.map(
                                (arraySet: any, index: number) => {
                                  return (
                                    <div className="ext-kiqbuild-create-section ops-mx-3 ops-my-3">
                                      <div className="ext-kiqbuild-query-header ops-mt-3 ops-mx-2 ops-mb-2">
                                        <span className="ext-kiqbuilder-header-text ops-mx-5 ops-mt-3 ext-d-flex">
                                          <span className="ops-mt-2 ops-mx-1">
                                            include{" "}
                                          </span>

                                          <CustomToggle
                                            selectedToggleValue={
                                              operationArray[index]
                                            }
                                            changesToggleState={(value: any) =>
                                              changeToogleValue(value, index)
                                            }
                                          />
                                          <span className="ops-mt-2 ops-mx-1">
                                            {" "}
                                            of this tags
                                          </span>
                                        </span>
                                        {initialDisplayTags.length > 0 && (
                                          <Button
                                            label="remove group"
                                            onClick={() => {
                                              removedQuery(index);
                                            }}
                                            type="secondary"
                                            className="ops-mx-5 ops-mt-4 ext-kiqbuilder-remove-group"
                                          />
                                        )}
                                      </div>
                                      <hr className="ext-header-styling" />

                                      <div className="ext-kiqbuiler-display-tag ops-mx-4">
                                        {initialDisplayTags.length > 0 &&
                                          Object.keys(arraySet).map((key) =>
                                          key === 'company' && arraySet[key].length > 0 && arraySet[key].map(
                                              (item: string) => {
                                                const reviedValue = item.split("$$")
                                                return (
                                                <Tag
                                                  code={reviedValue[0]}
                                                  key={reviedValue[0]}
                                                  name={getCompanyFullName(reviedValue[0])}
                                                  type={key}
                                                  canClick={true}
                                                  onClick={() => {}}
                                                  canFilter={true}
                                                  onFilter={() => {}}
                                                  isFilteredOnTag={true}
                                                  showMinusIcon={true}
                                                  showAmgenBlueColor={true}
                                                />
                                                )
                                              }
                                            )
                                          )}
                                          {initialDisplayTags.length > 0 &&
                                          Object.keys(arraySet).map((key) =>
                                          key !== 'company' && arraySet[key].length > 0 && arraySet[key].map(
                                              (item: string) => (
                                                <Tag
                                                  code={item}
                                                  key={item}
                                                  name={formatAmgenVenture(item)}
                                                  type={key}
                                                  canClick={true}
                                                  onClick={() => {}}
                                                  canFilter={true}
                                                  onFilter={() => {}}
                                                  isFilteredOnTag={true}
                                                  showMinusIcon={true}
                                                  showAmgenBlueColor={true}
                                                  
                                                />
                                              )
                                            )
                                          )}
                                      </div>

                                      <div className="ext-create-tags-query ops-mx-4 ops-my-3">
                                        <Button
                                          icon={<PlusIcon fill="#3773d2" />}
                                          iconPosition="right"
                                          label="add tags"
                                          className="ext-kiqbuild-button-border"
                                          type="secondary"
                                          onClick={() => {
                                            onClickAddTags(index);
                                          }}
                                        />
                                        <TagsModal
                                          headerName={"Select Tags"}
                                          data={tagList}
                                          isOpen={opentagsModal}
                                          selectedTags={selectedTags}
                                          setClosedTagsModalList={(
                                            result: boolean
                                          ) => onUserClosedModal(result)}
                                          setUserSelectedTags={(
                                            updateState: any
                                          ) => selectedUserTags(updateState)}
                                        />
                                      </div>
                                    </div>
                                  );
                                }
                              )
                            : !showKiqdeleteLoader && (
                                <div className="ext-kiqbuild-create-section ops-mx-3 ops-my-3">
                                  <div className="ext-kiqbuild-query-header ops-mt-3 ops-mx-2 ops-mb-2">
                                    <span className="ext-kiqbuilder-header-text ops-mx-5 ops-mt-3 ext-d-flex">
                                      <span className="ops-mt-2 ops-mx-1">
                                        include{" "}
                                      </span>

                                      <CustomToggle
                                        selectedToggleValue={operationArray[0]}
                                        changesToggleState={(value: any) =>
                                          changeToogleValue(value, 0)
                                        }
                                      />
                                      <span className="ops-mt-2 ops-mx-1">
                                        of this tags
                                      </span>
                                    </span>
                                  </div>
                                  <hr className="ext-header-styling" />

                                  <div className="ext-custom-tags-wrapper ops-mx-4">
                                    {initialDisplayTags.length > 0 && (
                                      <>
                                        {initialDisplayTags.map(
                                          (tagsBlock: any) =>
                                            Object.keys(tagsBlock).map((key) =>
                                            tagsBlock[key].length > 0 && tagsBlock[key].map(
                                                (item: string) => (
                                                  <Tag
                                                    code={item}
                                                    key={item}
                                                    name={formatAmgenVenture(item)}
                                                    type={tagsBlock}
                                                    canClick={true}
                                                    onClick={() => {}}
                                                    canFilter={true}
                                                    onFilter={() => {}}
                                                    isFilteredOnTag={true}
                                                    showMinusIcon={true}
                                                    showAmgenBlueColor={false}
                                                  />
                                                )
                                              )
                                            )
                                        )}
                                      </>
                                    )}
                                  </div>

                                  <div className="ext-create-tags-query ops-mx-4 ops-my-3">
                                    <Button
                                      icon={<PlusIcon fill="#3773d2" />}
                                      iconPosition="right"
                                      label="add tags"
                                      className="ext-kiqbuild-button-border"
                                      type="secondary"
                                      onClick={() => {
                                        setOpenTagsModal(true);
                                      }}
                                    />
                                    <TagsModal
                                      headerName={"Select Tags"}
                                      data={tagList}
                                      isOpen={opentagsModal}
                                      selectedTags={selectedTags}
                                      setClosedTagsModalList={(
                                        result: boolean
                                      ) => onUserClosedModal(result)}
                                      setUserSelectedTags={(updateState: any) =>
                                        selectedUserTags(updateState)
                                      }
                                    />
                                  </div>
                                </div>
                              )}

                          {initialDisplayTags.length > 0 &&
                            !showKiqdeleteLoader && (
                              <div className="ops-mx-5">
                                <Button
                                  icon={<PlusIcon fill="#3773d2" />}
                                  iconPosition="right"
                                  label="add groups"
                                  type="secondary"
                                  className="ext-kiqbuild-border-btn-color"
                                  onClick={() => {
                                    onClickedAddGroups();
                                  }}
                                />
                              </div>
                            )}
                        </div>

                        <div className="ext-kiqbuild-footer">
                          <hr className="ext-header-styling" />
                          <div className="ext-mt-5 ext-mb-5 ext-d-flex ops-mx-5 ext-kiqbuild-footer-styling ext-justify-content-between">
                            <div>
                              <Button
                                label="delete kiq"
                                type="secondary"
                                className="ext-btn-border-color"
                                onClick={() => {
                                  setOpenConfirmationModal(true);
                                }}
                              />
                            </div>
                            <div>
                              <Button
                                label="cancel"
                                type="secondary"
                                className="ext-btn-border-color"
                                onClick={() => {
                                  toggleButton();
                                }}
                              />
                            {
                              initialDisplayTags && initialDisplayTags.length > 0 ?
                              <Button
                                label="save changes"
                                className="ext-buildkiq-save-query"
                                onClick={() => {
                                  editChangesSave(initialDisplayTags);
                                }}
                                type="primary"
                              /> : 
                              <Button
                            disabled
                            label="save query"
                            className="ext-float-item-right ops-mx-3"
                            onClick={() => {}}
                            type="primary"
                          />
                            }
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      !showKiqdeleteLoader && (
                        //Articles list display here
                        <div className="ops-mx-5">
                          <div
                            className={
                              "ext-article-list-wrapper ext-border-top-0"
                            }
                          >
                            {isNewsListLoading && (
                              <div
                                className="ext-spinner-style ext-d-flex ext-justify-content-center ext-topic-loader"
                                role="status"
                              >
                                <Loader />
                              </div>
                            )}
                            {!isNewsListLoading && errorMessageNewsList && (
                              <ErrorMessage
                                isIcon={false}
                                message={errorMessageNewsList}
                              />
                            )}
                            {!isNewsListLoading &&
                              !errorMessageNewsList &&
                              newsList?.map((article: any, index: any) => {
                                let specialClassName =
                                  index % 2 === 0
                                    ? "ext-article-background-white"
                                    : "ext-article-background-gray";

                                const tags = getFormatedTagList(
                                  navigate,
                                  location,
                                  article.company_names,
                                  article.product_names,
                                  article.topic_names,
                                  article.kiq_names,
                                  article.av_company_names,
                                  article.corex_topic_names
                                );

                                return (
                                  <ArticleCard
                                    specialClassName={specialClassName}
                                    key={index}
                                    redirectUrl={article.url}
                                    title={article.title}
                                    articleId={article.article_id}
                                    publishDate={article.published}
                                    publishSource={article.source_name}
                                    tagList={tags}
                                    checkboxFlag={false}
                                    trashFlag={false}
                                  />
                                );
                              })}
                          </div>

                          <div className="ext-article-pagination">
                            <div className="container">
                              <div className="ext-article-pagination-wrapper ext-row">
                                {!isNewsListLoading &&
                                  newsList.length !== 0 &&
                                  totalArticles !== 0 && (
                                    <>
                                      <div className="ext-text-pagination ext-col">
                                        Showing {(page - 1) * pageLimit + 1} -{" "}
                                        {getUpperLimit()} of {totalArticles}{" "}
                                        results
                                      </div>
                                      <div className="pagination-wrapper ext-col ">
                                        <Pagination
                                          page={page}
                                          totalPages={Math.ceil(
                                            totalArticles / pageLimit
                                          )}
                                          handlePagination={handlePages}
                                        />
                                      </div>
                                    </>
                                  )}
                              </div>
                            </div>
                          </div>
                        </div>
                      )
                    )}
                  </>
                </Card>

                <Modal
                  opened={openConfirmationModal}
                  onClose={() => setOpenConfirmationModal(false)}
                  className="ext-modal-confirmation-styling"
                >
                  <Modal.Header contentClassName="ext-d-flex align-items-center">
                    <Modal.Title
                      className="ops-ms-4"
                      title="Delete KIQ"
                    ></Modal.Title>
                  </Modal.Header>
                  <div className="ops-my-3">
                    <div className="ops-mx-2">
                      {`Are you sure you want to delete the KIQ`} <b>{queryName}</b>
                    </div>
                    <div className="ops-my-5 ext-d-flex ext-justify-content-end">
                    <div className="ext-mx-5">
                        <Button
                          label="Cancel"
                          onClick={() => {
                            setOpenConfirmationModal(false);
                          }}
                          type="secondary"
                          className="ops-mt-4 ext-kiqbuilder-cancel-btn"
                        />
                      </div>
                      <div className="ext-mx-5">
                        <Button
                          label="Delete"
                          onClick={() => {
                            deletedKiq(selectedKiqQuery.collection_id);
                          }}
                          type="secondary"
                          className="ops-mx-5 ops-mt-4 ext-kiqbuilder-delete-btn"
                        />
                      </div>
                    </div>
                  </div>
                </Modal>
              </>
            )}
            {!inititalFlag &&
              createKiqStart &&
              !initialLoad &&
              !showKiqdeleteLoader && (
                <>
                  <Card cardHeader="Key Intelligent Question Builder">
                    <>{showKiqdeleteLoader && <Loader />}</>
                    {showLoader ? <Loader /> :
                    <div className="ext-kiq-create-container">
                      <div className="ext-row">
                        <div className="ops-mx-3">
                          <span className="ext-kiqbuild-header">Name:</span>
                          <span className="ext-kiq-input-textbar">
                            <input
                              required
                              type="text"
                              placeholder="My Query"
                              className="ext-kiqbuild-textbox"
                              onChange={(e) => setQueryName(e.target.value)}
                            />
                          </span>
                        </div>
                        {errorRespose.length > 0 && <div className="ext-buildkiq-error-msg">{errorRespose}</div>}
                      </div>

                      <div className="ext-kiqbuild-wrapper">
                        {initialDisplayTags &&
                        refreshFlag &&
                        initialDisplayTags.length > 0 ? (
                          initialDisplayTags.map(
                            (arraySet: any, index: number) => {
                              return (
                                <div className="ext-kiqbuild-create-section ops-mx-3 ops-my-3">
                                  <div className="ext-kiqbuild-query-header ops-mt-3 ops-mx-2 ops-mb-2">
                                    <span className="ext-kiqbuilder-header-text ops-mx-5 ops-mt-3 ext-d-flex">
                                      <span className="ops-mt-2 ops-mx-1">
                                        include{" "}
                                      </span>

                                      <CustomToggle
                                        selectedToggleValue={
                                          operationArray[index]
                                        }
                                        changesToggleState={(value: any) =>
                                          changeToogleValue(value, index)
                                        }
                                      />
                                      <span className="ops-mt-2 ops-mx-1">
                                        {" "}
                                        of this tags
                                      </span>
                                    </span>
                                    {initialDisplayTags.length > 0 && (
                                      <Button
                                        label="remove group"
                                        onClick={() => {
                                          removedQuery(index);
                                        }}
                                        type="secondary"
                                        className="ops-mx-5 ops-mt-4 ext-kiqbuilder-remove-group"
                                      />
                                    )}
                                  </div>
                                  <hr className="ext-header-styling" />

                                  <div className="ext-kiqbuiler-display-tag ops-mx-4">
                                    {initialDisplayTags.length > 0 &&
                                          Object.keys(arraySet).map((key) =>
                                          key === 'company' && arraySet[key].length > 0 && arraySet[key].map(
                                              (item: string) => {
                                                const reviedValue = item.split("$$")
                                                return (
                                                <Tag
                                                  code={reviedValue[0]}
                                                  key={reviedValue[0]}
                                                  name={getCompanyFullName(reviedValue[0])}
                                                  type={key}
                                                  canClick={true}
                                                  onClick={() => {}}
                                                  canFilter={true}
                                                  onFilter={() => {}}
                                                  isFilteredOnTag={true}
                                                  showMinusIcon={true}
                                                  showAmgenBlueColor={false}
                                                />
                                                )
                                              }
                                            )
                                          )}
                                          {initialDisplayTags.length > 0 &&
                                          Object.keys(arraySet).map((key) =>
                                          key !== 'company' && arraySet[key].length > 0 && arraySet[key].map(
                                              (item: string) => (
                                                <Tag
                                                  code={item}
                                                  key={item}
                                                  name={formatAmgenVenture(item)}
                                                  type={key}
                                                  canClick={true}
                                                  onClick={() => {}}
                                                  canFilter={true}
                                                  onFilter={() => {}}
                                                  isFilteredOnTag={true}
                                                  showMinusIcon={true}
                                                  showAmgenBlueColor={false}
                                                />
                                              )
                                            )
                                          )}
                                  </div>

                                  <div className="ext-create-tags-query ops-mx-4 ops-my-3">
                                    <Button
                                      icon={<PlusIcon fill="#3773d2" />}
                                      iconPosition="right"
                                      label="add tags"
                                      className="ext-kiqbuild-button-border"
                                      type="secondary"
                                      onClick={() => {
                                        onClickAddTags(index);
                                      }}
                                    />
                                    <TagsModal
                                      headerName={"Select Tags"}
                                      data={tagList}
                                      isOpen={opentagsModal}
                                      selectedTags={selectedTags}
                                      setClosedTagsModalList={(
                                        result: boolean
                                      ) => onUserClosedModal(result)}
                                      setUserSelectedTags={(updateState: any) =>
                                        selectedUserTags(updateState)
                                      }
                                    />
                                  </div>
                                </div>
                              );
                            }
                          )
                        ) : (
                          <div className="ext-kiqbuild-create-section ops-mx-3 ops-my-3">
                            <div className="ext-kiqbuild-query-header ops-mt-3 ops-mx-2 ops-mb-2">
                              <span className="ext-kiqbuilder-header-text ops-mx-5 ops-mt-3 ext-d-flex">
                                <span className="ops-mt-2 ops-mx-1">
                                  include{" "}
                                </span>
                                <CustomToggle
                                  selectedToggleValue={operationArray[0]}
                                  changesToggleState={(value: any) =>
                                    changeToogleValue(value, 0)
                                  }
                                />
                                <span className="ops-mt-2 ops-mx-1">
                                  of this tags
                                </span>
                              </span>
                            </div>
                            <hr className="ext-header-styling" />

                            <div className="ext-custom-tags-wrapper ops-mx-4">
                              {initialDisplayTags.length > 0 && (
                                <>
                                  {initialDisplayTags.map((tagsBlock: any) =>
                                    Object.keys(tagsBlock).map((key) =>
                                    key === 'company' && tagsBlock[key].length > 0 && tagsBlock[key].map((item: string) => {
                                      const reviedValue = item.split("$$")
                                      return(
                                      <Tag
                                      code={reviedValue[0]}
                                      key={reviedValue[0]}
                                      name={getCompanyFullName(reviedValue[0])}
                                      type={key}
                                      canClick={true}
                                      onClick={() => {}}
                                      canFilter={true}
                                      onFilter={() => {}}
                                      isFilteredOnTag={true}
                                      showMinusIcon={true}
                                      showAmgenBlueColor={false}
                                    />
                                    )})
                                    )
                                  )}
                                </>
                              )}
                              {initialDisplayTags.length > 0 && (
                                <>
                                  {initialDisplayTags.map((tagsBlock: any) =>
                                    Object.keys(tagsBlock).map((key) =>
                                    key !== 'company' && tagsBlock[key].length > 0 && tagsBlock[key].map((item: string) => (
                                        <Tag
                                          code={item}
                                          key={item}
                                          name={formatAmgenVenture(item)}
                                          type={tagsBlock}
                                          canClick={true}
                                          onClick={() => {}}
                                          canFilter={true}
                                          onFilter={() => {}}
                                          isFilteredOnTag={true}
                                          showMinusIcon={true}
                                          showAmgenBlueColor={false}
                                        />
                                      ))
                                    )
                                  )}
                                </>
                              )}
                            </div>

                            <div className="ext-create-tags-query ops-mx-4 ops-my-3">
                              <Button
                                icon={<PlusIcon fill="#3773d2" />}
                                iconPosition="right"
                                label="add tags"
                                className="ext-kiqbuild-button-border"
                                type="secondary"
                                onClick={() => {
                                  setOpenTagsModal(true);
                                }}
                              />
                              <TagsModal
                                headerName={"Select Tags"}
                                data={tagList}
                                isOpen={opentagsModal}
                                selectedTags={selectedTags}
                                setClosedTagsModalList={(result: boolean) =>
                                  onUserClosedModal(result)
                                }
                                setUserSelectedTags={(updateState: any) =>
                                  selectedUserTags(updateState)
                                }
                              />
                            </div>
                          </div>
                        )}

                        {initialDisplayTags.length > 0 && (
                          <div className="ops-mx-5">
                            <Button
                              icon={<PlusIcon fill="#3773d2" />}
                              iconPosition="right"
                              label="add groups"
                              type="secondary"
                              className="ext-kiqbuild-border-btn-color"
                              onClick={() => {
                                onClickedAddGroups();
                              }}
                            />
                          </div>
                        )}
                      </div>
                      <div className="ext-kiqbuild-footer">
                        <hr className="ext-header-styling" />
                        <div className="ext-mt-5 ext-mb-5 ext-d-flex ops-mx-5 ext-justify-content-between ext-kiqbuild-footer-styling">
                          <div>
                            <Button
                              label="Back"
                              className=""
                              onClick={() => {
                                backToPreviousPage();
                              }}
                              type="secondary"
                            />
                          </div>
                          <div>
                            {initialDisplayTags.length > 0 ? (
                              <Button
                                label="save query"
                                className="ext-buildkiq-save-query ext-float-item-right"
                                onClick={() => {
                                  saveQuery(initialDisplayTags);
                                }}
                                type="primary"
                              />
                            ) : (
                              <Button
                                disabled
                                label="save query"
                                className="ext-float-item-right"
                                onClick={() => {}}
                                type="primary"
                              />
                            )}
                          </div>
                        </div>
                      </div>
                    </div>}
                  </Card>
                </>
              )}
          </>
        </div>
      }
    </div>
  ) : (
    <>{authData && <RequestPage />}</>
  );
};

export default KIQBUILDER;
