import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Loader } from "@gitlab-rtsensing/component-library";
import Card from "../../components/card";
import ArticleCard from "../../components/article-card";
import {
  CompanyGraphDataRequest,
  CompanyGraphDataResponse,
  CompanyGraphParamsRequest,
  CompanyProfileDataRequest,
  CompanyProfileDataResponse,
  graphValue,
} from "../../api/get-company-details-dt";
import {CompaniesTableData} from "../../api/get-companies-dt";

import {
  CartesianGrid,
  XAxis,
  YAxis,
  AreaChart,
  Area,
  ResponsiveContainer,
  Tooltip,
} from "recharts";

import {
  apiResponse,
  formatCount,
  formateToDecimal,
  formatGraphDate,
  formatText,
  getFormatedTagList,
} from "../../utils/commonMethods";
import ErrorMessage from "../../components/error-message";
import {
  apiErrorMessage,
  noDataMessage,
} from "../../assets/errorMessageConstants";
import "./index.scss";
import BackBtn from "../../components/back-btn";
import ChevronUpIcon from "../../assets/icons/chevron-up-icon";
import { BASE_ROUTE, getBackButtonText } from "../../components/../utils/routing";
import dayjs from "../../utils/dayjs";

const CompanyProfile: React.FC = () => {
  const navigate = useNavigate();

  const [companyData, setCompanyData] =
    useState<CompanyProfileDataResponse | null>();
  const [articleData, setArticleData] = useState([]);
  const [graphData, setGraphData] = useState<CompanyGraphDataResponse | null>();
  const [companyUrl, setCompanyUrl] = useState(" ");
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [isArticleLoading, setIsArticleLoading] = useState(false);

  const [graphInterval, setGraphInterval] = useState("yearly");
  const [graphLoading, setIsGraphLoading] = useState(false);

  const [backButtonRoute, setBackButtonRoute] = useState<any>(-1);
  const [errorMessageData, setErrorMessageData] = useState("");
  const [errorMessageArticle, setErrorMessageArticle] = useState("");
  const [errorMessageGraph, setErrorMessageGraph] = useState("");
  const [graphParams, setGraphParams] = useState<CompaniesTableData>();
  const defaultGraphParams: CompaniesTableData = {
    company_ticker: "",
    company_name: "",
    company_price: 0,
    company_daily_change_percentage: 0,
    company_daily_change_points: 0,
    company_pe_ratio: 0,
    company_mkt_cap: 0,
    company_daily_range: {
      upper_value: 0,
      lower_value: 0,
      current_value: 0,
    },
    company_yearly_range: {
      upper_value: 0,
      lower_value: 0,
      current_value: 0,
    },
    company_price_history: {
      value_1D: 0,
      value_5D: 0,
      value_1M: 0,
      value_1Y: 0,
      value_3Y: 0,
      value_5Y: 0,
      value_all: 0,
    },
    company_logo_url: "",
  };

  const location: any = useLocation();

  const fetchCompanyData = async (pathname: string) => {
    setIsDataLoading(true);
    let payload: CompanyProfileDataRequest = { company_ticker: pathname };
    try {
      const res = await apiResponse(
        "get",
        "get-company-detail",
        [pathname],
        payload
      );
      if (res.data.status === "SUCCESS" && res.data.data) {
        setCompanyData(res.data.data);
        fetchArticleData(res.data.data?.company_details[0].ticker, 0, 50);
        fetchGraphData(res.data.data?.company_details[0].ticker, graphInterval);
        fetchGraphParams(res.data.data?.company_details[0].ticker);
        setCompanyUrl(res.data.data?.company_details[0].website);
      } else {
        setCompanyData(null);
        setErrorMessageData(noDataMessage);
      }
      setIsDataLoading(false);
    } catch (error) {
      setCompanyData(null);
      setIsDataLoading(false);
      setErrorMessageData(apiErrorMessage);
      setErrorMessageArticle(apiErrorMessage);
      setIsArticleLoading(false);
      setIsGraphLoading(false);
      setErrorMessageGraph(apiErrorMessage);
    }
  };

  const fetchArticleData = async (
    company_ticker: string,
    page_offset: number,
    limit: number
  ) => {
    const payload = {
      sort_by_value: "desc",
      page_offset: 0,
      end_date: dayjs(),
      start_date: dayjs().subtract(89, 'days'),
      limit: 50,
      filters: { company: [`${company_ticker}`] },
    };
    setIsArticleLoading(true);
    try {
      const res = await apiResponse("post", "get-news-with-tags", [], payload);
      if (
        res.data.status === "SUCCESS" &&
        res.data.data.response.length !== 0
      ) {
        setArticleData(res.data.data.response);
      } else {
        setArticleData([]);
        setErrorMessageArticle(noDataMessage);
      }
      setIsArticleLoading(false);
    } catch (error) {
      setArticleData([]);
      setIsArticleLoading(false);
      setErrorMessageArticle(apiErrorMessage);
    }
  };

  const fetchGraphData = async (company_ticker: string, range: string) => {
    let payload: CompanyGraphDataRequest = {
      company_ticker: company_ticker,
      range: range,
    };
    setIsGraphLoading(true);
    try {
      let urlIdentifier = [company_ticker, range];
      const res = await apiResponse(
        "get",
        "get-company-graph",
        urlIdentifier,
        payload
      );
      if (res.data.status === "SUCCESS" && res.data.data) {
        setGraphData(res.data.data);
      } else {
        setGraphData(null);
        setErrorMessageGraph(noDataMessage);
      }
      setIsGraphLoading(false);
    } catch (error) {
      setGraphData(null);
      setIsGraphLoading(false);
      setErrorMessageGraph(apiErrorMessage);
    }
  };

  const fetchGraphParams = async (company_ticker: string) => {
    let payload: CompanyGraphParamsRequest = {
      company_ticker: company_ticker,
    };
    try {
      const res = await apiResponse(
        "post",
        "get-companies",
        [company_ticker],
        payload
      );
      if (res.data.status === "SUCCESS") {
        setGraphParams(res.data.data[0]);
      } else {
        setGraphParams(defaultGraphParams);
      }
    } catch (error) {
      setGraphParams(defaultGraphParams);
    }
  };

  const getFormatedData = (range: string, graphData: graphValue[]) => {
    const newArray = graphData && [...graphData].reverse();

    if (newArray) {
      newArray.forEach((ele) => {
        let date = formatGraphDate(ele.date, range);
        if (date) {
          ele.date = date;
        }
        return ele;
      });
      return newArray;
    }
  };

  const displayUrl = (data: any) => {
    const splits = data.split("www.", 10);
    return splits[1];
  };

  const getCompanyRange = (range: string) => {
    if (range === "daily") {
      return "Today";
    } else if (range === "weekly") {
      return "Weekly";
    } else if (range === "monthly") {
      return "Monthly";
    } else if (range === "yearly") {
      return "Yearly";
    } else if (range === "All") {
      return "All";
    }
  };

  const getValue = (
    range: string,
    graphParams: CompaniesTableData | undefined
  ) => {
    if (graphParams) {
      if (range === "daily") {
        return graphParams.company_price_history.value_1D;
      } else if (range === "weekly") {
        return graphParams.company_price_history.value_5D;
      } else if (range === "monthly") {
        return graphParams.company_price_history.value_1M;
      } else if (range === "yearly") {
        return graphParams.company_price_history.value_1Y;
      } else if (range === "All") {
        return graphParams.company_price_history.value_all;
      }
    } else {
      return 0;
    }
  };
  const stockValuePercentage = getValue(graphInterval, graphParams);
  const priceNature = stockValuePercentage ? stockValuePercentage < 0 : false;
  const stockValuePercentageString: any = stockValuePercentage?.toString();

  const emptyGraphValue: graphValue[] = [
    { date: "", open: 0, low: 0, high: 0, close: 0, volume: 0 },
  ];

  const graphDataFinal = graphData ? graphData.graph_data : emptyGraphValue;

  let amgenProducts =
    companyData?.company_details[0].ticker === "AMGN"
      ? ["LUMAKRAS", "Otezla", "Prolia", "Repatha", "TEZSPIRE"]
      : [];

  const handleProductClick = (productName: string) => {
    navigate(`${BASE_ROUTE}/products/${productName}`, {
      state: { productName: productName, callingPath: location },
    });
  };

  useEffect(() => {
    setIsDataLoading(true);
    setIsArticleLoading(true);
    if (location && location.state) {
      fetchCompanyData(location.state.companyId);
    } else {
      const company_ticker = window.location.pathname.split("companies/");
      fetchCompanyData(company_ticker[1]);
      setBackButtonRoute(`${BASE_ROUTE}/companies`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.state && location.state.companyId]);

  useEffect(() => {
    setIsGraphLoading(true);
    if (companyData) {
      fetchGraphData(companyData?.company_details[0].ticker, graphInterval);
      fetchGraphParams(companyData?.company_details[0].ticker);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [graphInterval]);

  return (
    <div className="individual-company-profile ops-px-3">
      <div className="ext-company-page-navigation">
        <BackBtn
          navigateFn={() => navigate(backButtonRoute)}
          backBtnText={getBackButtonText(location, 'BACK TO COMPANIES')}
        ></BackBtn>
      </div>

      <div className="ext-company-container ext-row ops-mt-2 ops-mb-4">
        <div className="ext-company-left-panel ext-col-lg-4 ext-col-md-12 ops-mb-3 ops-mb-sm-0 ops-mb-md-3 ops-mb-lg-0">
          <Card
            cardHeader={`About ${
              !isDataLoading &&
              companyData?.company_details[0].name !== undefined
                ? companyData?.company_details[0].name
                : "Company"
            }`}
          >
            <>
              {isDataLoading && <Loader />}
              {errorMessageData && !isDataLoading && (
                <ErrorMessage isIcon={false} message={errorMessageData} />
              )}
              {!errorMessageData && companyData && (
                <>
                  <div className="ext-company-attributes ops-mx-2 ops-my-2 ">
                    <div className="ext-company-img ext-row">
                      <div className="ext-col-lg-12 ext-col-md-12">
                        <img
                          src={companyData && companyData?.image}
                          alt={companyData?.company_details[0].name}
                          className="ext-company-logo"
                        />
                      </div>
                    </div>
                    <div className="ext-company-information">
                      <div className="ext-attribute ext-row ops-mb-3">
                        <div className="ext-attribute-col ext-col-sm-12 ext-col-md-6 ops-mb-3 ops-mb-sm-0 ops-mb-md-3 ops-mb-lg-0">
                          <div className="ext-info-title">CEO</div>
                          <div className="ext-info-value">
                            {companyData?.company_details[0].ceo === ""
                              ? "Data not available"
                              : companyData?.company_details[0].ceo}
                          </div>
                        </div>
                        <div className="ext-attribute-col ext-col-sm-12 ext-col-md-6">
                          <div className="ext-info-title"># OF EMPLOYEES</div>
                          <div className="ext-info-value">
                            {formatCount(
                              companyData?.company_details[0].num_employees
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="ext-attribute ext-row ops-mb-3">
                        <div className="ext-attribute-col ext-col-sm-12 ext-col-md-12">
                          <div className="ext-info-title">LOCATION</div>
                          <div className="ext-info-value">
                            {`${companyData?.company_details[0].address}, ${
                              companyData?.company_details[0].city
                            }, ${
                              companyData?.company_details[0].state
                                ? companyData?.company_details[0].state
                                : "N/A"
                            }, ${companyData?.company_details[0].zip}`}
                          </div>
                        </div>
                      </div>
                      <div className="ext-attribute ext-row ops-mb-3">
                        <div className="ext-attribute-col ext-col-sm-12 ext-col-md-6 ops-mb-3 ops-mb-sm-0 ops-mb-md-3 ops-mb-lg-0">
                          <div className="ext-info-title ops-mt-2">WEBSITE</div>
                          <div className="ext-info-value">
                            <a
                              href={companyData?.company_details[0].website.replace(
                                /^http:\/\//i,
                                "https://"
                              )}
                              rel="noreferrer"
                              target="_blank"
                              className="ext-website-link"
                            >
                              {companyUrl && displayUrl(companyUrl)}
                            </a>
                          </div>
                        </div>
                        <div className="ext-attribute-col ext-col-sm-12 ext-col-md-6 ops-mt-sm-2">
                          <div className="ext-info-title">SYMBOL</div>
                          <div className="ext-info-value">
                            {companyData?.company_details[0].ticker}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="ext-company-description-container ops-px-3">
                    <div className="ext-info-about">ABOUT</div>
                    <div className="ext-company-description ">
                      {companyData?.company_details[0].summary}
                    </div>
                  </div>
                </>
              )}
            </>
          </Card>
        </div>

        <div className="ext-company-right-panel ext-col-lg-8 ext-col-md-12  ">
          <div className="ext-company-articles ">
            <Card
              cardHeader={`${
                !isDataLoading &&
                companyData?.company_details[0].name !== undefined
                  ? companyData?.company_details[0].name
                  : "Company"
              } in the News `}
            >
              <>
                {isArticleLoading && <Loader />}
                {!isArticleLoading && errorMessageArticle && (
                  <ErrorMessage isIcon={false} message={errorMessageArticle} />
                )}
                {!errorMessageArticle && !isArticleLoading && (
                  <>
                    <div className="ext-article-list-wrapper ext-border-top-0">
                      {articleData &&
                        articleData.length > 0 &&
                        articleData?.map((article: any, index: number) => {
                          let specialClassName =
                            index % 2 === 0
                              ? "ext-article-background-gray"
                              : "ext-article-background-white";

                          const tags = getFormatedTagList(
                            navigate,
                            location,
                            article.company_names,
                            article.product_names,
                            article.topic_names,
                            article.kiq_names,
                            article.amgen_venture,
                            article.corex_topic_names
                          );

                          return (
                            <ArticleCard
                              articleId={article.article_id}
                              specialClassName={specialClassName}
                              key={index}
                              redirectUrl={article.url}
                              title={article.title}
                              publishDate={article.published}
                              publishSource={article.source_name}
                              tagList={tags}
                              checkboxFlag={false}
                              trashFlag={false}
                            />
                          );
                        })}
                    </div>
                  </>
                )}
              </>
            </Card>
          </div>
          <div className="ext-company-graph-product ext-row ops-mt-3">
            {companyData?.company_details[0].ticker !== "AMGN" ? (
              <div
                className={
                  "ext-company-graph ext-col-md-12 ext-col-lg-12 ops-mb-3 ops-mb-sm-0 ops-mb-md-3 ops-mb-lg-0"
                }
              >
                <Card
                  cardHeader={`${
                    !isDataLoading &&
                    companyData?.company_details[0].ticker !== undefined
                      ? companyData?.company_details[0].ticker
                      : "Company"
                  } Market Summary `}
                >
                  <div className="ext-company-graph-wrapper">
                    {graphLoading ? (
                      <div
                        className="ext-d-flex ext-justify-content-center ops-mb-4 ops-mt-4"
                        role="status"
                      >
                        <Loader />
                      </div>
                    ) : (
                      <>
                        <div className="ext-graph-info ext-d-flex ext-row ops-mx-3 ops-my-4 ">
                          <div className="ext-col-xl-3 ext-col-sm-12 ext-d-flex ops-mt-1">
                            <div className="ext-value">
                              {graphParams?.company_price !== undefined
                                ? `$${graphParams?.company_price}`
                                : " N/A"}
                            </div>
                            <div className="ext-graph-status ext-d-flex">
                              {priceNature ? (
                                // <i className="bi bi-chevron-down ext-dec-icon"></i>
                                <ChevronUpIcon
                                  fill="#db0000"
                                  classname="ext-dec-icon ext-vertical-align"
                                />
                              ) : (
                                // <i className="bi bi-chevron-up ext-inc-icon"></i>
                                <ChevronUpIcon
                                  fill="#2B9600"
                                  classname="ext-inc-icon ext-vertical-align"
                                />
                              )}
                              <span
                                className={
                                  priceNature
                                    ? "mr-2 ext-dec-percent-score"
                                    : "mr-2 ext-inc-percent-score"
                                }
                              >{`${Number.parseFloat(
                                stockValuePercentageString
                              ).toFixed(1)}%`}</span>
                              <span className="ext-percent-score-period">
                                {getCompanyRange(graphInterval)}
                              </span>
                            </div>
                          </div>
                          <div className="ext-col-xl-7 ext-col-sm-12">
                            <div className=" ext-amgen-period-buttons">
                              <div className="ext-period-title ops-mt-1">
                                DATE RANGE:
                              </div>
                              <button
                                type="button"
                                className={
                                  graphInterval === "daily"
                                    ? "ext-period-btn-selected ops-btn op-btn-primary ops-btn-sm "
                                    : "ext-period-btn ext-btn ext-btn-sm "
                                }
                                onClick={() => {
                                  setGraphInterval("daily");
                                }}
                              >
                                1D
                              </button>
                              <button
                                type="button"
                                className={
                                  graphInterval === "weekly"
                                    ? "ext-period-btn-selected ops-btn op-btn-primary ops-btn-sm ext-col-2 "
                                    : "ext-period-btn ops-btn ext-btn-sm ext-col-2"
                                }
                                onClick={() => {
                                  setGraphInterval("weekly");
                                }}
                              >
                                1W
                              </button>
                              <button
                                type="button"
                                className={
                                  graphInterval === "monthly"
                                    ? "ext-period-btn-selected ops-btn op-btn-primary ops-btn-sm col-2 "
                                    : "ext-period-btn ext-btn ext-btn-sm ext-col-2"
                                }
                                onClick={() => {
                                  setGraphInterval("monthly");
                                }}
                              >
                                1M
                              </button>
                              <button
                                type="button"
                                className={
                                  graphInterval === "yearly"
                                    ? "ext-period-btn-selected ops-btn op-btn-primary ops-btn-sm col-2 "
                                    : "ext-period-btn ext-btn ext-btn-sm ext-col-2"
                                }
                                onClick={() => {
                                  setGraphInterval("yearly");
                                }}
                              >
                                1Y
                              </button>
                            </div>
                          </div>
                        </div>
                        {errorMessageGraph && (
                          <ErrorMessage
                            message={errorMessageGraph}
                            isIcon={false}
                          />
                        )}
                        {!errorMessageGraph && (
                          <>
                            <div className="ops-px-2 ops-py-0 ext-company-graph-container">
                              <ResponsiveContainer width="99%" height={200}>
                                <AreaChart
                                  data={getFormatedData(
                                    graphInterval,
                                    graphDataFinal
                                  )}
                                >
                                  <Area
                                    type="monotone"
                                    dataKey="close"
                                    fillOpacity={1}
                                    fill="url(#colorUv)"
                                    stroke="#2B9600"
                                  />

                                  <defs>
                                    <linearGradient
                                      id="colorUv"
                                      x1="0"
                                      y1="0"
                                      x2="0"
                                      y2="1"
                                    >
                                      <stop
                                        offset="5%"
                                        stopColor="#2B9600"
                                        stopOpacity={0.1}
                                      />
                                      <stop
                                        offset="95%"
                                        stopColor="#2B9600"
                                        stopOpacity={0}
                                      />
                                    </linearGradient>
                                  </defs>
                                  <CartesianGrid vertical={false} />

                                  <XAxis
                                    dataKey="date"
                                    axisLine={false}
                                    tickLine={false}
                                    minTickGap={
                                      graphInterval === "weekly" ? 105 : 25
                                    }
                                    style={{
                                      fontSize: "10px",
                                      fill: "#9C9C9C",
                                      fontWeight: "700",
                                      lineHeight: "14px",
                                    }}
                                  />
                                  <YAxis
                                    dataKey="close"
                                    axisLine={false}
                                    style={{
                                      fontSize: "10px",
                                      fill: "#9C9C9C",
                                      fontWeight: "700",
                                      lineHeight: "14px",
                                    }}
                                  />
                                  <Tooltip />
                                </AreaChart>
                              </ResponsiveContainer>
                            </div>
                            <div className="ext-graph-border ops-my-4"></div>
                          </>
                        )}

                        <div className="ext-graph-attribute ext-row ops-m-3">
                          <div className="ext-col-6 ext-d-flex">
                            <div className="ext-amgen-graph-info-title">
                              P/E RATIO
                            </div>
                            <div className="ext-graph-info-value">
                              <span>
                                {graphParams?.company_pe_ratio !== undefined
                                  ? formateToDecimal(
                                      graphParams?.company_pe_ratio,
                                      2
                                    )
                                  : "N/A"}
                              </span>
                            </div>
                          </div>
                          <div className="ext-col-6 ext-d-flex">
                            <div className="ext-amgen-graph-info-title">
                              MARKET CAP
                            </div>
                            <div className="ext-graph-info-value">
                              <span>
                                {graphParams?.company_mkt_cap !== undefined
                                  ? `${formateToDecimal(
                                      graphParams?.company_mkt_cap,
                                      2
                                    )}B`
                                  : "N/A"}
                              </span>
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </Card>
              </div>
            ) : (
              <div
                className={
                  "ext-company-graph ext-col-md-8 ext-col-lg-8 ops-mb-3 ops-mb-sm-0 ops-mb-md-3 ops-mb-lg-0"
                }
              >
                <Card
                  cardHeader={`${
                    !isDataLoading &&
                    companyData?.company_details[0].ticker !== undefined
                      ? companyData?.company_details[0].ticker
                      : "Company"
                  } Market Summary `}
                >
                  <div className="ext-company-graph-wrapper">
                    {graphLoading ? (
                      <div
                        className="ext-d-flex ext-justify-content-center ops-mb-4 ops-mt-4"
                        role="status"
                      >
                        <Loader />
                      </div>
                    ) : (
                      <>
                        <div className="ext-amgen-graph-info ext-d-flex ext-row ops-mx-3 ops-my-4 ">
                          <div className="ext-value ext-col-6">
                            {graphParams?.company_price !== undefined
                              ? `$${graphParams?.company_price}`
                              : " N/A"}
                          </div>
                          <div className={"ext-col-6 ext-graph-status"}>
                            {priceNature ? (
                              <i className="bi bi-chevron-down ext-dec-icon"></i>
                            ) : (
                              <i className="bi bi-chevron-up ext-inc-icon"></i>
                            )}
                            <span
                              className={
                                priceNature
                                  ? "ops-mr-2 ext-dec-percent-score"
                                  : "ops-mr-2 ext-inc-percent-score"
                              }
                            >{`${Number.parseFloat(
                              stockValuePercentageString
                            ).toFixed(1)}%`}</span>
                            <span className="ext-percent-score-period">
                              {getCompanyRange(graphInterval)}
                            </span>
                          </div>
                        </div>
                        {errorMessageGraph && (
                          <ErrorMessage
                            message={errorMessageGraph}
                            isIcon={false}
                          />
                        )}
                        {!errorMessageGraph && (
                          <>
                            <div className="ops-px-2 ops-py-0 ext-company-graph-container">
                              <ResponsiveContainer width="95%" height={200}>
                                <AreaChart
                                  data={getFormatedData(
                                    graphInterval,
                                    graphDataFinal
                                  )}
                                >
                                  <Area
                                    type="monotone"
                                    dataKey="close"
                                    fillOpacity={1}
                                    fill="url(#colorUv)"
                                    stroke="#2B9600"
                                  />

                                  <defs>
                                    <linearGradient
                                      id="colorUv"
                                      x1="0"
                                      y1="0"
                                      x2="0"
                                      y2="1"
                                    >
                                      <stop
                                        offset="5%"
                                        stopColor="#2B9600"
                                        stopOpacity={0.1}
                                      />
                                      <stop
                                        offset="95%"
                                        stopColor="#2B9600"
                                        stopOpacity={0}
                                      />
                                    </linearGradient>
                                  </defs>
                                  <CartesianGrid vertical={false} />

                                  <XAxis
                                    dataKey="date"
                                    axisLine={false}
                                    tickLine={false}
                                    minTickGap={
                                      graphInterval === "weekly" ? 105 : 25
                                    }
                                    style={{
                                      fontSize: "10px",
                                      fill: "#9C9C9C",
                                      fontWeight: "700",
                                      lineHeight: "14px",
                                    }}
                                  />
                                  <YAxis
                                    dataKey="close"
                                    axisLine={false}
                                    style={{
                                      fontSize: "10px",
                                      fill: "#9C9C9C",
                                      fontWeight: "700",
                                      lineHeight: "14px",
                                    }}
                                  />
                                  <Tooltip />
                                </AreaChart>
                              </ResponsiveContainer>
                            </div>
                            <div className="ext-row ext-period-buttons ops-my-4 ops-mx-5">
                              <button
                                type="button"
                                className={
                                  graphInterval === "daily"
                                    ? "ext-period-btn-selected ops-btn op-btn-primary ops-btn-sm "
                                    : "ext-period-btn ext-btn ext-btn-sm "
                                }
                                onClick={() => {
                                  setGraphInterval("daily");
                                }}
                              >
                                1D
                              </button>
                              <button
                                type="button"
                                className={
                                  graphInterval === "weekly"
                                    ? "ext-period-btn-selected ops-btn op-btn-primary ops-btn-sm ext-col-2 "
                                    : "ext-period-btn ext-btn ext-btn-sm ext-col-2"
                                }
                                onClick={() => {
                                  setGraphInterval("weekly");
                                }}
                              >
                                1W
                              </button>
                              <button
                                type="button"
                                className={
                                  graphInterval === "monthly"
                                    ? "ext-period-btn-selected ops-btn op-btn-primary ops-btn-sm ext-col-2 "
                                    : "ext-period-btn ext-btn ext-btn-sm ext-col-2"
                                }
                                onClick={() => {
                                  setGraphInterval("monthly");
                                }}
                              >
                                1M
                              </button>
                              <button
                                type="button"
                                className={
                                  graphInterval === "yearly"
                                    ? "ext-period-btn-selected ops-btn op-btn-primary ops-btn-sm ext-col-2 "
                                    : "ext-period-btn ext-btn ext-btn-sm ext-col-2"
                                }
                                onClick={() => {
                                  setGraphInterval("yearly");
                                }}
                              >
                                1Y
                              </button>
                            </div>
                          </>
                        )}

                        <div className="ext-graph-attribute ext-row ops-m-3">
                          <div className="ext-col-6">
                            <div className="ext-graph-info-title">
                              P/E RATIO
                            </div>
                            <div className="ext-graph-info-value">
                              <span>
                                {graphParams?.company_pe_ratio !== undefined
                                  ? formateToDecimal(
                                      graphParams?.company_pe_ratio,
                                      2
                                    )
                                  : "N/A"}
                              </span>
                            </div>
                          </div>
                          <div className="ext-col-6">
                            <div className="ext-graph-info-title">
                              MARKET CAP
                            </div>
                            <div className="ext-graph-info-value">
                              <span>
                                {graphParams?.company_mkt_cap !== undefined
                                  ? `${formateToDecimal(
                                      graphParams?.company_mkt_cap,
                                      2
                                    )}B`
                                  : "N/A"}
                              </span>
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </Card>
              </div>
            )}
            {companyData?.company_details[0].ticker !== "AMGN" ? (
              <></>
            ) : (
              <div className="ext-company-products ext-col-md-12 ext-col-lg-4">
                <Card
                  cardHeader={`${
                    !isDataLoading
                      ? companyData?.company_details[0].name
                      : "Company"
                  } Products `}
                >
                  {
                    <div className="ext-company-products-list ops-px-4 ops-py-3">
                      {amgenProducts.map((item: any, index: number) => {
                        let notSelected = "ext-products-name";
                        const productName = item;

                        return (
                          <div
                            className={` ${notSelected} `}
                            key={item}
                            onClick={() => {
                              handleProductClick(productName);
                            }}
                            style={{ cursor: "pointer" }}
                          >
                            {formatText(productName)}
                          </div>
                        );
                      })}
                    </div>
                  }
                </Card>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CompanyProfile;
