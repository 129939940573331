import React, { Suspense, lazy, useContext, useEffect } from "react";
import { AppContext } from "../../utils/app-context";
import RequestPage from "../../components/request-access-page";
import { Loader } from "@gitlab-rtsensing/component-library";
import { lazyImport } from "../../utils/lazy-import";

const PeerBigSheet: React.FC = () => {
  const { authData } = useContext(AppContext);

  const PeerBigSheetUI = lazy(() =>
    lazyImport("@amgen/rtsensing-finance-peer-big-sheet")
  );

  useEffect(() => {}, [authData]);

  return authData && authData?.externalAuthorization?.peerBigSheet ? (
    <Suspense fallback={<Loader />}>
      <PeerBigSheetUI />
    </Suspense>
  ) : (
    <>{authData && <RequestPage />}</>
  );
};

export default PeerBigSheet;
