import { useState, useEffect, useContext, useRef } from "react";
// import WordCloud from "../../components/visx-word-cloud/wordcloud";
import { WordCloud, Card } from "@opsdti-global-component-library/amgen-design-system"
import { lastApiResponse } from "../../../../utils/commonMethods";
import {
  wordCloudNoDataMessage,
  apiErrorMessage,
} from "../../../../assets/errorMessageConstants";
import "./index.scss";
import { AppContext } from "../../../../utils/app-context";
// import { EarningsReport } from "../../../../api/get-earnings-word-cloud-dt";
import { useParentSize } from '@visx/responsive';
import { Loader } from "@gitlab-rtsensing/component-library";
import { CancelTokenSource } from "axios";

type WordDT = {
  keyword: string;
  detail: string;
  topic_frequency: number;
  topic_strength: number;
  sentiment_score: number;
  article_ids: string[];
  cluster_number: number;
  cluster_strength: number;
  number_of_articles: number;
}

const wordCategories = [
  { name: "Positive", color: "#0063C3", lightColor: "#E5EFF9"},
  { name: "Neutral",  color: "#7E7E7E", lightColor: "#F3F3F3"},
  { name: "Negative", color: "#FF6720", lightColor: "#FFF0E9"},
]

export function NewsWordCloud() {
  const [WordCloudData, setWordCloudData] = useState<any[]>([]);
  const [wordCloudErrorMessage, setWordCloudErrorMessage] = useState("");
  const [isDataLoading, setIsDataLoading] = useState<boolean>(false);
  const [wordCloudTabs, setwordCloudTabs] = useState([
    { name: "30 Days", active: true,  value: 30},
    { name: "60 Days", active: false, value: 60},
    { name: "90 Days", active: false, value: 90},
  ]);
  const [selectedNumberOfDays, setSelectedNumberOfDays] = useState(30);
  const { authData } = useContext(AppContext);
  const { parentRef: wordCloudRef, width: wordCloudWidth } = useParentSize({ debounceTime: 150 });
  let cancelTokenSource = useRef<CancelTokenSource | null>(null);

  const getCategory = (sentiment: number): string => {
    if (sentiment > 0.33) return "Positive";
    if (sentiment < -0.33) return "Negative";

    return "Neutral"
  }

  const fetchWordCloudData = async () => {
    setIsDataLoading(true);
    setWordCloudErrorMessage("");
    try {
      const res = await lastApiResponse("get", "news-cloud", cancelTokenSource, {}, `?number_of_days=${selectedNumberOfDays}`);
      if (res.data.status === "SUCCESS") {
        const transformAPIResponseToWords = (arr: Array<WordDT> | null) => {
          return arr?.map((item: WordDT) => ({
            "text": item.keyword,
            "value": item.topic_frequency,
            "category": getCategory(item.sentiment_score),
            "detail": item.detail
          })) || []
        }
        
        setWordCloudData(transformAPIResponseToWords(res.data.data[0].word_details))

      } else {
        setWordCloudData([]);
        setWordCloudErrorMessage(wordCloudNoDataMessage);
      }
      setIsDataLoading(false);
    } catch (error) {
      setWordCloudData([]);
      setIsDataLoading(false);
      setWordCloudErrorMessage(apiErrorMessage);
    }
  };

  useEffect(() => {
    if (
      authData &&
      Object.keys(authData).length > 0
    ) {
      fetchWordCloudData();
    }
  }, [authData, wordCloudTabs]);

  const handleDurationTabs = (selectedView: any) => {
    const updatedDurationViews = wordCloudTabs.map((view: any) => {
      if (view.name === selectedView.name) {
        view.active = true;
        setSelectedNumberOfDays(view.value);
      } else {
        view.active = false;
      }
      return view;
    });
    setwordCloudTabs([...updatedDurationViews]);
  };

  // Mobile specific scaling of font-sizes
  const getWordcloudFontSize = (width: number): [number, number] => {
    if(width < 576) return [10, 18];
    if(width < 768) return [12, 22];
    return [15, 40];
  }

  return (
    <Card className='ext-news-cloud' title="Trending News Topics">
      <div>
      <ul className="navbar-text ext-cursor-pointer ops-pt-2">
        {wordCloudTabs.map((view) => {
          return (
            <li
              key={view.name}
              className={`nav-item ops-px-4 ops-pb-2 ${view.active ? "active" : "inactive"}`}
              onClick={() => handleDurationTabs(view)}
            >
              {view.name}
            </li>
          );
        })}
      </ul>
      <div className="navbar-divider"/>
      {isDataLoading ? <div className="loading-container"><Loader /></div> :
        wordCloudErrorMessage ? <div className="loading-container">{wordCloudErrorMessage}</div> :
        <div ref={wordCloudRef} id="word-cloud" className="ext-wordcloud-wrapper">
          <WordCloud
            fontSizes={getWordcloudFontSize(wordCloudWidth)}
            fontFamily="Inter"
            width={wordCloudWidth} // Scaling w/ bootstrap grid
            height={250}
            categories={wordCategories}
            showLegend={true}
            words={WordCloudData}
            spiralType="archimedean"
            padding={2}
            deterministic={true}
            onPopoverContent={(word: any) => <div>{word.detail}</div>}
          />
        </div>
      }
      </div>
    </Card>
  );
};
