import { useContext, useEffect, useState, CSSProperties } from "react";
import { Link, useLocation } from "react-router-dom";
import { AppContext } from "../../utils/app-context";
import { useNavManager } from "../../hooks/use-nav-manager";
import Layout from "../layout"
import "./index.scss";
import { Breadcrumb, Flex,Title, HomeIcon } from "@opsdti-global-component-library/amgen-design-system"
import { 
  ExternalMegaMenuUtility, 
  IMegaMenuSection, 
  homePageSectionLabel, 
  homePageName 
} from "../../utility-module/external-mega-menu-utility";
import { BASE_ROUTE } from "../../utils/routing";
import { useParentSize } from '@visx/responsive';

const PageHeader = (props: any) => {
  const [selectedPage, setSelectedPage] = useState<string>("Trending");
  const [navDropdowns, setNavDropdowns] = useState<any>([]);
  const location: any = useLocation();
  const { authData } = useContext(AppContext);
  const { parentRef: bannerParentRef, width: bannerWidth } = useParentSize({ debounceTime: 10 });
  const newHomePageName = "Home";

  useEffect(() => {
    if(authData){
      const megaMenuUtility = new ExternalMegaMenuUtility();
      const navSections = megaMenuUtility.generateMegaMenuSections(authData);
      renameHomePageLabel(navSections);
      const navSectionsToDropdown = navSections.map((section: IMegaMenuSection, i) => {
        return {
          children: section.links.map((link, j) => {
            return {
              key: `${i}-${j}`,
              label: <Link to={link.url}>{link.label}</Link>,
              labelOnly: link.label,
              href: link.url,
              pageHref: link.url.split('/')[2] // 2 for /external/news-search --> news
            }
          }),
          key: section.sectionLabel,
          label: section.sectionLabel,
          type: 'group'
        }
      })
      setNavDropdowns(navSectionsToDropdown);
    }
  },[authData])

  const renameHomePageLabel = (navMenu: any[]) => {
    const sectionLabel = navMenu.find(item => item.sectionLabel == homePageSectionLabel);
    const homePageLabel = sectionLabel?.links.find((link: { label: string }) => link.label == homePageName);
    if (homePageLabel) {
      homePageLabel.label = newHomePageName;
      sectionLabel.sectionLabel = "";
    }
  }

  const findLabel = () => {
    let label = newHomePageName
    navDropdowns.forEach((section: any) => {
      section.children.forEach((row: any) => {
        const pathNamePageHref = location.pathname.split('/')[2];
        if(row.pageHref === pathNamePageHref){
          label = row.labelOnly;
        }
      })
    });
    return label;
  
  }
  useEffect(() => {
    const label = findLabel()
    setSelectedPage(label);
  }, [location.pathname, navDropdowns]);

  useEffect(() => {
    const label = findLabel()
    registerTabMatomo(label)
  }, [location.pathname.split('/')[1], location.pathname.split('/')[2]])

  const registerTabMatomo = (tabName: string) => {
    if ('_paq' in window && window._paq !== undefined && window._paq !== null) {
      const matamoWindow: any = window;
      const _paq = matamoWindow._paq || [];
      _paq.push(['trackPageView', 'Tab-' + tabName]);
    }
  }

  let dowJones: boolean = false;

  let hideMainNavbar = false
  let hideSideNavbar = false
  const params = window.location.href.split("news/");
  if (params[1] !== undefined && params[1] !== "") {
    dowJones = true;
    hideMainNavbar = true
    hideSideNavbar = true
  }
  useNavManager(authData, hideMainNavbar, hideSideNavbar)

  const isHomePage =() => {
    return selectedPage == newHomePageName;
  }

  const headerStyle = (): CSSProperties => {
    let style: CSSProperties = {}
    if (isHomePage())
      style = {
        position: "fixed",
        top: "48px",
        zIndex: "10",
        width: bannerWidth,
        height: "150px",
        backgroundImage: `url(${process.env.REACT_APP_DOMAIN}/Intelligence-banner.jpg)`,
        backgroundColor: "#093e75",
        color: "white",
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }
    return style
  }

  const headerTextStyle = (): CSSProperties => { 
    let style: CSSProperties = {}
    if (isHomePage())
      style = {
        color: "white",
      };
    return style
  }

  const pageStyle = (): CSSProperties => {
    let style: CSSProperties = {}
    if (isHomePage())
      style = {
        marginTop: "150px",
      }
    return style
  }

  const  breadcrumbClassName = () => {  
    if (isHomePage())
      return "header-text"
  }

  return (
    <div ref={bannerParentRef}>
      {authData && !dowJones && (
        <div style={headerStyle()}>
        <Layout>
          <div className="header-text-container">
          <Breadcrumb
            className={breadcrumbClassName()}
            items={[
              {
                title: 
                <Link to={BASE_ROUTE}
                  style={{ backgroundColor: "transparent"}}
                >
                  <Flex style={headerTextStyle()} align="center" gap={5}>
                    <HomeIcon height="100%" style={{transform: 'translateY(-1px)'}} width={14}/>
                    Intelligence
                  </Flex>
                </Link>
              },
              {
                menu: {
                  items: navDropdowns
                },
                title: selectedPage                
              }
            ]}
          />
          {isHomePage() &&
          <div>
           <Title style={{color:"white", marginTop:"40px"}} level={1}>Sensing Intelligence</Title>
           <Title style={{color:"white"}} level={3}>Real-time, relevant news and information</Title>
          </div>
          }
          </div>     
        </Layout>     
        </div>
        
      )}
      <div style={pageStyle()}>{props.children}</div>
    </div>
  );
};

export default PageHeader;
