/* eslint-disable react/react-in-jsx-scope */
import { useState } from "react";
import axios from "axios";
import { getOktaToken } from "../../utils/commonMethods";
import { getApiUrl } from "../../api/api-config";
import "./index.scss";
import MyCollection from "../my-collection";
import { Loader, Modal } from "@gitlab-rtsensing/component-library";
import { apiErrorMessage } from "../../assets/errorMessageConstants";

const CreateCollectionModal = ({
  show,
  hideModal,
  selectedArticle,
  getCollectionList,
  updateSelectedArticleList,
  vanish,
}: any) => {
  const [isCreated, setIsCreated] = useState(false);
  const [newCollectionName, setNewCollectionName] = useState("");
  const [editCollectionLoader, setEditCollectionLoader] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [inputErrorMessage, setInputErrorMessage] = useState(false);
  const [showCollection, setShowColection] = useState(false);
  const [newCollectionId, setNewCollectionId] = useState("");
  const [status, setStatus] = useState(false);
  const [deleteCollectionError, setDeleteCollectionError] = useState(false);
  // const [hideCreateModal, setHideCreateModal] = useState(false);
  const [duplicateCollectionName, setDuplicateCollectionName] = useState("");

  const oktaToken = getOktaToken();

  const createNewCollection = async () => {
    try {
      setStatus(false);
      setErrorMessage(false);
      setEditCollectionLoader(true);
      const payload = {
        collection_name: newCollectionName,
        collection_data: selectedArticle.map((a: any) => a.articleId),
      };
      const res = await axios({
        method: "post",
        url: getApiUrl("get-create-collection"),
        data: payload,
        headers: {
          Authorization: `Bearer ${oktaToken}`,
        },
      });
      if (res && res.data.status === "SUCCESS") {
        setIsCreated(true);
        updateSelectedArticleList([]);
        setNewCollectionId(res.data.data.Collection_ID);
        setTimeout(() => getCollectionList(), 3000);
      } else {
        if (res.data.message.includes("already")) {
          setStatus(true);
        }
        setErrorMessage(true);
      }
      setEditCollectionLoader(false);
    } catch (error) {
      setEditCollectionLoader(false);
      setErrorMessage(true);
      setStatus(false);
    }
  };

  const deleteMyCollection = async (collectionID: string) => {
    const payload = {
      collection_id: collectionID,
    };

    return axios({
      method: "delete",
      url: getApiUrl("delete-collection"),
      data: payload,
      headers: {
        Authorization: `Bearer ${oktaToken}`,
      },
    })
      .then(function (res) {
        if (res && res.data && res.data.status === "SUCCESS") {
          setShowColection(false);
          getCollectionList();
          vanish();
        }
      })
      .catch(function (error) {
        setDeleteCollectionError(true);
        setTimeout(() => setDeleteCollectionError(false), 3000);
      });
  };

  const callCreateCollection = () => {
    createNewCollection();
  };

  const createCollection = () => {
    setDuplicateCollectionName(newCollectionName);
    setNewCollectionName("");
    newCollectionName !== ""
      ? callCreateCollection()
      : setInputErrorMessage(true);
    setTimeout(() => setInputErrorMessage(false), 3000);
  };

  const hideCollection = () => {
    setShowColection(false);
  };

  const viewCollection = () => {
    setIsCreated(true)
    setShowColection(true);
    hideModal(false)
    
    // setHideCreateModal(true);
  };

  const completeRemove = () => {
    vanish();
  };

  return (
    <div>
      {
      <Modal 
        opened={show}
        onClose={() => {hideModal(!show)}}
        >
        <Modal.Header contentClassName="d-flex align-items-center">
          <Modal.Title title="Create New Article Collection" />
        </Modal.Header>
      <div className="ext-create-collection-modal">
              {editCollectionLoader ? (
                <Loader />
              ) : !isCreated || status ? (
                <div>
                  {status ? (
                    <div className="ext-modal-subheading ops-my-2">
                      <div>
                        An Article Collection named{" "}
                        <b>
                          {duplicateCollectionName
                            ? duplicateCollectionName
                            : newCollectionName}
                        </b>{" "}
                        already exists
                      </div>
                      <div>Please choose a different name:</div>
                    </div>
                  ) : (
                    <div className="ext-modal-subheading ops-my-2">
                      Enter a name for your new collection
                    </div>
                  )}

                  <input
                    value={newCollectionName}
                    className="form-control"
                    placeholder="Collection name"
                    onChange={(e) => setNewCollectionName(e.target.value)}
                  />
                </div>
              ) : (
                <div className="ext-modal-subheading ops-pt-6">
                  Your Article Collection{" "}
                  <span className="ext-fw-bold">{duplicateCollectionName}</span>{" "}
                  has been created
                </div>
              )}
              {inputErrorMessage && (
                <div className="ext-text-danger ext-fst-italic">
                  Please enter a name to continue
                </div>
              )}
              {errorMessage && !status && (
                <div className="ext-text-danger">{apiErrorMessage}</div>
              )}

              <div className="ext-footer-section ops-mt-5 ext-d-flex ext-ms-auto">
              <div>
                <button
                  type="button"
                  className="ext-primary-button"
                  onClick={() => {
                    hideModal(!show);
                    setIsCreated(false);
                    setNewCollectionName("");
                  }}
                >
                  {!isCreated ? "Cancel" : "Back to News"}
                </button>
                <button
                  type="button"
                  className="ext-secondary-button ops-ms-2 border-0"
                  onClick={() =>
                    !isCreated ? createCollection() : viewCollection()
                  }
                >
                  {!isCreated ? "Create Collection" : "View Collection"}
                </button>
              </div>
            </div>
            </div>
      </Modal>
      }
      {showCollection && (
        <MyCollection
          show={showCollection}
          onHideModal={() => hideCollection()}
          collectionId={newCollectionId}
          collectionName={duplicateCollectionName}
          onDeleteCollection={(id) => {
            deleteMyCollection(id);
          }}
          completeRemove={() => {
            completeRemove();
          }}
          deleteCollectionError={deleteCollectionError}
        />
      )}
    </div>
  );
};

export default CreateCollectionModal;
