interface Props {
  fill: string;
}

const SortDownIcon = ({ fill }: Props) => {
  return (
    <svg
      width="13"
      height="8"
      viewBox="0 0 13 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.59695 5.50496C5.19961 5.94738 4.50629 5.94738 4.10895 5.50496L0.705001 1.71479C0.12684 1.07103 0.583726 0.046608 1.449 0.0466081L8.25691 0.0466087C9.12218 0.0466087 9.57906 1.07103 9.0009 1.71479L5.59695 5.50496Z"
        fill={fill}
      />
    </svg>
  );
};

export default SortDownIcon;
