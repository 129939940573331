interface Props {
    fill: string;
    classname?: string;
  }
  
  const ExpandUpIcon = ({ fill, classname }: Props) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg"
            width="15"
            height="19"
            viewBox="0 0 1024 1024">
                <g transform="rotate(180 512 512)">
                    <path fill="currentColor" d="M104.704 338.752a64 64 0 0 1 90.496 0l316.8 316.8l316.8-316.8a64 64 0 0 1 90.496 90.496L557.248 791.296a64 64 0 0 1-90.496 0L104.704 429.248a64 64 0 0 1 0-90.496z"/>
                </g>
        </svg>

    );
  };
  
  export default ExpandUpIcon;