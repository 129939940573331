interface Props {
  classname?: string;
  fill?: string;
}
const PlusIcon = ({ classname, fill }: Props) => {
  return (
    <svg
      className={classname}
      width="10"
      height="10"
      viewBox="0 0 10 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.12653 1.11768V4.92282M5.12653 4.92282V8.72797M5.12653 4.92282H8.83167M5.12653 4.92282L1.42139 4.92282"
        stroke={fill}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default PlusIcon;
