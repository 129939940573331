interface Props {
  fill: string;
  classname?: string;
}

const KIQLogo = ({ fill, classname }: Props) => {
  return (
    <svg
      className={classname}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.2467 4.7334C11.197 4.61921 11.1163 4.52122 11.0138 4.45052C10.9113 4.37982 10.7911 4.33925 10.6667 4.3334H7.61333L8.46 1.1734C8.48696 1.07452 8.49077 0.970746 8.47111 0.870155C8.45146 0.769563 8.40887 0.674858 8.34667 0.593399C8.28456 0.512722 8.2048 0.447342 8.11351 0.402273C8.02222 0.357204 7.92181 0.333644 7.82 0.333399H3.15333C3.00282 0.328243 2.855 0.374199 2.73395 0.46378C2.61289 0.553361 2.52573 0.681291 2.48667 0.826732L0.7 7.4934C0.673034 7.59228 0.669232 7.69605 0.688888 7.79664C0.708544 7.89723 0.751128 7.99194 0.813333 8.0734C0.876123 8.15497 0.95695 8.22089 1.04948 8.266C1.14201 8.31111 1.24373 8.33418 1.34667 8.3334H3.92667L2.72 12.8267C2.68046 12.9716 2.69101 13.1256 2.74993 13.2638C2.80886 13.4019 2.9127 13.5161 3.04464 13.5879C3.17658 13.6596 3.32888 13.6847 3.47687 13.6591C3.62486 13.6335 3.75986 13.5587 3.86 13.4467L11.1267 5.44673C11.2132 5.35199 11.2705 5.23429 11.2918 5.10776C11.3131 4.98122 11.2974 4.85124 11.2467 4.7334V4.7334ZM4.72 10.5201L5.43333 7.8534C5.4603 7.75452 5.4641 7.65075 5.44445 7.55016C5.42479 7.44956 5.3822 7.35486 5.32 7.2734C5.25789 7.19272 5.17814 7.12734 5.08684 7.08227C4.99555 7.0372 4.89514 7.01364 4.79333 7.0134H2.23333L3.66 1.66673H6.94667L6.1 4.82673C6.07282 4.92744 6.06964 5.03311 6.09074 5.13527C6.11183 5.23742 6.1566 5.3332 6.22144 5.4149C6.28628 5.49661 6.36939 5.56195 6.46409 5.60569C6.55878 5.64943 6.66242 5.67034 6.76667 5.66673H9.14667L4.72 10.5201Z"
        fill={fill}
      />
    </svg>
  );
};
export default KIQLogo;
