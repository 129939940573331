import React, { useEffect, useState } from "react";
import axios from "axios";
import "./index.scss";

// Components
import { Button, Loader } from "@gitlab-rtsensing/component-library";

// Moodal
import { CustomModal } from "../modal";

// API
import { getApiUrl } from "../../api/api-config";
import { apiResponse, getOktaToken } from "../../utils/commonMethods";

// Assets
import { apiErrorMessage, noDataMessage } from "../../assets/errorMessageConstants";

type ViewAllInsightsProps = {
  showNotes?: any;
  onClosePopupModal?: any;
  quarter?: string;
  year?: string;
};

const ViewAllInsights: React.FC<ViewAllInsightsProps> = (
  props: ViewAllInsightsProps
) => {
    const { showNotes, onClosePopupModal, quarter, year } = props;
    const oktaToken = getOktaToken();
    const [companyList, setCompanyList]:any = useState([{id: "all", name: "All Companies"}]);
    const [allInsights, setAllInsights] = useState([]);
    const [companyListError, setCompanyListError] = useState("");
    const [companyListLoader, setCompanyListLoader] = useState(false);
    const [insightsDataLoader, setInsightsDataLoader] = useState(false);

  const getNotesCategories = async() => {
    setCompanyListLoader(true)
    try {
      const res = await apiResponse("get", "get-notes-categories", [], {});
      if (res) {
        if (res && res.data && res.data.data) {
          const respData = res.data.data[1].values;
          setCompanyList((oldelement:any) => [...oldelement, ...respData]);
          getInsightsData("all");
        }
      } else {
        setCompanyListError(noDataMessage)
      }
      setCompanyListLoader(false)
    } catch (error) {
      setCompanyListLoader(false)
      setCompanyListError(apiErrorMessage)
    }
    setCompanyListLoader(false)
  }

  const onChangeDropdownValue = (value: string) => {    
    getInsightsData(value);
  };

  const getInsightsData = async (selectedCompany:string) => {    
    setInsightsDataLoader(true)
    try {
      const res = await axios({
        method: "get",
        url: `${getApiUrl("get-all-insights")}?company_ticker=${selectedCompany}&quarter=${quarter}&year=${year}`,
        data: {},
        headers: {
          Authorization: `Bearer ${oktaToken}`,
        },
      });
      if (res.data.status === "SUCCESS" && res.data.data.length !== 0) {
        const respData = res.data.data;
        setAllInsights(respData);
      }else {
        setAllInsights([]);
      }
    } catch (error) {}
    setInsightsDataLoader(false);
  };

  useEffect(() => {
    getNotesCategories();
    getInsightsData("all");
  }, []);

  return (
        <CustomModal
          className="ext-earnings-insights-container"
          opened={showNotes}
          onClose={() => {
              onClosePopupModal();
          }}
        >
          <CustomModal.Header>
            <CustomModal.Title
              className="ext-earnings-insights-heading"
              title={'Earnings Insights'}
            />
          </CustomModal.Header>
          {
            companyListLoader ?
                <div className="ext-earnings-insights-loader ext-d-flex ext-justify-content-center ext-align-items-center">
                    <Loader />
                </div>
            :
            <>
            <div className="ext-d-flex ext-flex-column">
              {companyListError ? <p className="ext-earnings-insights-no-data-text ops-my-4">{companyListError}</p>
              :
              <div className="ext-earning-insights-company-dropdown-container">
                  <select
                  className="ext-earnings-insights-company-dropdown ops-my-4"
                  onChange={(e:any) => onChangeDropdownValue(e.target.value)}
                  >
                  {companyList && companyList.length ? companyList.map((company: any) => {
                      return (
                      <option
                          id={company.id}
                          key={company.id}
                          value={company.id}
                      >&nbsp;&nbsp; {company.name}
                      </option>
                      );
                  })
                  :
                  <div className="ext-earnings-insights-loader ext-d-flex ext-justify-content-center ext-align-items-center">
                      <Loader />
                  </div>} 
                  </select>
              </div>
              }
                  
              {
                  insightsDataLoader ?
                      <div className="ext-earnings-insights-loader ext-d-flex ext-justify-content-center ext-align-items-center">
                          <Loader />
                      </div>
                  :
                  <div className="ext-earnings-insights-data-container">
                      {allInsights && allInsights.length ? allInsights.map((insight: any, index:number) => {                        
                          return (
                          <div className="ops-my-3" key={index}>
                              <p className="ext-fw-bold" style={{fontSize: "14px"}}>{insight.company_short_name}</p>
                              <ol style={{
                                paddingLeft: insight.note_details && insight.note_details.length ? "20px" : "0", fontSize: "14px"}}>
                              {
                                  insight.note_details && insight.note_details.length ? insight.note_details.map((note: any, i: number) => {
                                  return (
                                      <li key={i} className="ext-earnings-insights-detail-header opx-my-2">{note}</li>
                                  )}
                                  )
                                  :
                                  <p className="ext-earnings-insights-no-data-text ops-my-4">No insights provided for this company.</p>
                              }
                              </ol>
                              
                          </div>
                          );
                          }) : <p className="ext-earnings-insights-no-data-text ops-my-4">No insights provided for this company.</p>
                          }
                  </div>
              }
            </div>
            <div className="ext-w-100 ops-my-1">
                <Button
                label="CLOSE"
                onClick={() => onClosePopupModal()}
                type="secondary"
                className="ext-earning-view-button"
                />
            </div>

            </> 
          }

        </CustomModal>
      );
};

export default ViewAllInsights;
