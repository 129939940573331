import "./index.scss";

const CustomRange = (props: any) => {

    const {minValue, maxValue, value} = props
    return (
        <div className="slidecontainer ops-mx-2 ">
   <input type="range" min={minValue} max={maxValue} value={value} className="slider" id="myRange" />
</div>
    )
}

export default CustomRange;