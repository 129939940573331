/* eslint-disable react/react-in-jsx-scope */
import { useState } from "react";
import axios from "axios";
import { getOktaToken } from "../../utils/commonMethods";
import { getApiUrl } from "../../api/api-config";
import "./index.scss";
import MyCollection from "../my-collection";

import { Loader, Modal } from "@gitlab-rtsensing/component-library";
import {
  apiErrorMessage,
  selectCollectionMsg,
} from "../../assets/errorMessageConstants";

const UpdateCollectionModal = ({
  show,
  hideModal,
  selectedArticle,
  getCollectionList,
  availableCollectionList,
  updateSelectedArticleList,
  vanish,
}: any) => {
  const [isAdded, setIsAdded] = useState(false);
  const [editCollectionLoader, setEditCollectionLoader] = useState(false);
  const [showCollection, setShowColection] = useState(false);
  const [errorMessage, setErrorMessage] = useState(" ");
  const [inputErrorMessage, setInputErrorMessage] = useState(false);
  const [selectedCollectionId, setSelectedCollectionId] = useState("");
  const [selectedCollectionName, setSelectedCollectionName] = useState("");
  const [deleteCollectionError, setDeleteCollectionError] = useState(false);

  const oktaToken = getOktaToken();

  const setSelectedCollectionData = (id: string) => {
    availableCollectionList?.filter((collection: any) => {
      if (collection.collection_id === id) {
        setSelectedCollectionId(collection.collection_id);
        setSelectedCollectionName(collection.collection_name);
        return true;
      }
      return false;
    });
  };

  const addArticleIntoCollection = async () => {
    setErrorMessage(" ");
    setEditCollectionLoader(true);
    try {
      const payload = {
        collection_id: selectedCollectionId,
        collection_data: selectedArticle.map((a: any) => a.articleId),
      };

      const res = await axios({
        method: "post",
        url: getApiUrl("get-update-collection"),
        data: payload,
        headers: {
          Authorization: `Bearer ${oktaToken}`,
        },
      });
      if (res && res.data.status === "SUCCESS") {
        setIsAdded(true);
        updateSelectedArticleList([]);
      } else {
        setErrorMessage(res.data.message);
      }
      setEditCollectionLoader(false);
    } catch (error) {
      setEditCollectionLoader(false);
      setErrorMessage(apiErrorMessage);
    }
  };

  const deleteMyCollection = async (collectionID: string) => {
    const payload = {
      collection_id: collectionID,
    };

    try {
      const res = await axios({
        method: "delete",
        url: getApiUrl("delete-collection"),
        data: payload,
        headers: {
          Authorization: `Bearer ${oktaToken}`,
        },
      });
      if (res && res.data && res.data.status === "SUCCESS") {
        setShowColection(false);
        getCollectionList();
        vanish();
      }
    } catch (error) {
      setDeleteCollectionError(true);
      setTimeout(() => setDeleteCollectionError(false), 3000);
    }
  };

  const callAddArticle = () => {
    // updateSelectedArticleList([]);
    addArticleIntoCollection();
  };
  const handleAddArticle = () => {

    selectedCollectionId !== "" ? callAddArticle() : setInputErrorMessage(true);

    setTimeout(() => setInputErrorMessage(false), 3000);
  };

  const hideCollection = () => {
    setShowColection(false);
    vanish();
  };
  const viewCollection = () => {
    setIsAdded(true);
    setShowColection(true);

  };
  const completeRemove = () => {
    vanish();
  };

  return (
    <div>
                        {!showCollection && (

                      <Modal 
                        opened={show}
                        onClose={() => {hideModal(!show);}} >
                          <Modal.Header contentClassName="d-flex align-items-center">
          <Modal.Title title="Add to Existing Article Collection" />
        </Modal.Header>

                      <>
                      {editCollectionLoader && <Loader />}

            {!editCollectionLoader && !isAdded && (
              <div className="ext-modal-body-margin ext-modal-update-height">
                <div className="ext-modal-subheading ops-my-2">
                  Select the collection you want to add articles to:
                </div>
                <select
                  className="form-select ext-fw-bold ext-article-card-select"
                  aria-label="Default select example"
                  onChange={(e) => {
                    setSelectedCollectionData(e.target.value);
                    setErrorMessage(" ");
                  }}
                  defaultValue={"Select Collection"}
                >
                  <option
                    selected
                    disabled
                    hidden
                    defaultValue={"Select Collection"}
                  >
                    Select Collection
                  </option>
                  {availableCollectionList?.map((item: any) => {
                    return (
                      <option
                        value={item.collection_id}
                        key={item.collection_id}
                      >
                        {item.collection_name}
                      </option>
                    );
                  })}
                </select>
              </div>
            )}
            {!editCollectionLoader && isAdded && (
              <div className="ext-modal-subheading ext-modal-update-height ops-pt-6">
                The selected article(s) have been added to your Article
                Collection{" "}
                <span className="ext-fw-bold">{selectedCollectionName}</span>
              </div>
            )}
            {inputErrorMessage && (
              <div className="ext-error-message ext-text-danger ext-fst-italic">
                {selectCollectionMsg}
              </div>
            )}
            {!editCollectionLoader && errorMessage && (
              <div className="ext-error-message ext-text-danger ext-fst-italic">
                {errorMessage}
              </div>
            )}
                      </>
                    
                      <>
                      <div className="ext-footer-section ext-d-flex ext-ms-auto">
              <div>
                <button
                  type="button"
                  className="ext-primary-button"
                  onClick={() => {
                    
                    setIsAdded(false);
                    hideModal(!show);
                    
                  }}
                >
                  {!isAdded ? "Cancel" : "Back to News"}
                </button>
                <button
                  type="button"
                  className="ext-secondary-button ops-ms-2 border-0"
                  onClick={() =>
                    !isAdded ? handleAddArticle() : viewCollection()
                  }
                >
                  {!isAdded ? "Add to collection" : "View Collection"}
                </button>
              </div>
            </div>
                      </>
                        </Modal>
                    )
                  }

      {showCollection && (
        <MyCollection
          show={showCollection}
          onDeleteCollection={(id) => {
            deleteMyCollection(id);
          }}
          onHideModal={() => hideCollection()}
          collectionId={selectedCollectionId}
          collectionName={selectedCollectionName}
          completeRemove={() => {
            completeRemove();
          }}
          deleteCollectionError={deleteCollectionError}
        />
      )}
    </div>
  );
};

export default UpdateCollectionModal;
