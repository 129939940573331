interface Props {
  fill?: string;
  classname?: string;
}

const EditIcon = ({ fill, classname }: Props) => {
  return (
    <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx="8" cy="8.5" r="8" fill="#0063C3"/>
<path d="M13.5 6.12001C13.5004 6.0542 13.4878 5.98897 13.4629 5.92806C13.438 5.86714 13.4013 5.81173 13.355 5.76501L11.235 3.64501C11.1883 3.59867 11.1329 3.56201 11.072 3.53712C11.011 3.51224 10.9458 3.49963 10.88 3.50001C10.8142 3.49963 10.749 3.51224 10.6881 3.53712C10.6271 3.56201 10.5717 3.59867 10.525 3.64501L9.11001 5.06001L3.64501 10.525C3.59867 10.5717 3.56201 10.6271 3.53712 10.6881C3.51224 10.749 3.49963 10.8142 3.50001 10.88V13C3.50001 13.1326 3.55269 13.2598 3.64645 13.3536C3.74022 13.4473 3.8674 13.5 4.00001 13.5H6.12001C6.18997 13.5038 6.25995 13.4929 6.32542 13.4679C6.39088 13.4429 6.45036 13.4045 6.50001 13.355L11.935 7.89001L13.355 6.50001C13.4006 6.45155 13.4378 6.39577 13.465 6.33501C13.4698 6.29515 13.4698 6.25486 13.465 6.21501C13.4673 6.19173 13.4673 6.16828 13.465 6.14501L13.5 6.12001ZM5.91501 12.5H4.50001V11.085L9.46501 6.12001L10.88 7.53501L5.91501 12.5ZM11.585 6.83001L10.17 5.41501L10.88 4.71001L12.29 6.12001L11.585 6.83001Z" fill="white"/>
</svg>
  );
};

export default EditIcon;
