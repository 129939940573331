import React from "react";
import ArticleCard from "../../components/article-card";
import { useNavigate, useLocation } from "react-router-dom";
import { Loader } from "@gitlab-rtsensing/component-library";
import ErrorMessage from "../../components/error-message";
import { getFormatedTagListWithFilter } from "../../utils/commonMethods";

type ArticleListProps = {
  articleList: any;
  isArticleLoading: boolean;
  selectedArticles: {
    articleId: string | undefined;
    articleName: string | undefined;
  }[];
  onSelectedArticlesChanged: (
    checkedArticles: {
      articleId: string | undefined;
      articleName: string | undefined;
    }[]
  ) => void;
  tagFilters: any;
  updateSelectedFilters: (value: {
    field: string;
    name: string;
    isChecked: boolean;
    category_name: string;
  }) => void;
  error: string;
};

const ArticleList: React.FC<ArticleListProps> = (props: ArticleListProps) => {
  const {
    articleList,
    isArticleLoading,
    selectedArticles,
    onSelectedArticlesChanged,
    tagFilters,
    updateSelectedFilters,
    error,
  } = props;

  const navigate = useNavigate();
  const location: any = useLocation();

  const updateSelectedArticleList = (checkedArticle: {
    isChecked: boolean;
    articleId: string | undefined;
    articleName: string | undefined;
  }) => {
    let updatedSelectedArticles: any[] = [];
    const existingSelectedArticles = [...selectedArticles];
    if (checkedArticle.isChecked) {
      existingSelectedArticles.push({
        articleName: checkedArticle?.articleName,
        articleId: checkedArticle?.articleId,
      });
      updatedSelectedArticles = existingSelectedArticles;
    } else {
      updatedSelectedArticles = existingSelectedArticles.filter((item) => {
        if (item.articleId !== checkedArticle.articleId) {
          return true;
        }
        return false;
      });
    }
    onSelectedArticlesChanged([...updatedSelectedArticles]);
  };

  const isChecked = (articleId: string) => {
    const filteredArticles = selectedArticles.filter((article) => {
      return article.articleId === articleId;
    });
    return filteredArticles.length > 0;
  };

  return (
    <>
      {isArticleLoading && <Loader />}
      {error && <ErrorMessage isIcon={false} message={error} />}
      {!isArticleLoading &&
        !error &&
        articleList?.map((article: any, index: number) => {
          let specialClassName =
            index % 2 === 0
              ? "ext-article-background-gray"
              : "ext-article-background-white";

          const tags = getFormatedTagListWithFilter(
            navigate,
            location,
            tagFilters,
            updateSelectedFilters,
            article.company_names,
            article.product_names,
            article.topic_names,
            article.kiq_names,
            article.av_company_names,
            article.corex_topic_names
          );

          return (
            <ArticleCard
              key={index}
              articleId={article.article_id}
              redirectUrl={article.url}
              specialClassName={specialClassName}
              title={article.title}
              publishDate={article.published}
              publishSource={article.source_name}
              tagList={tags}
              checkboxFlag={true}
              isChecked={isChecked(article.article_id)}
              trashFlag={false}
              onCheckboxClicked={updateSelectedArticleList}
            />
          );
        })}
    </>
  );
};

export default ArticleList;
