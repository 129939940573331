import React from "react";
import "./index.scss";
import axios from "axios";
import { CustomModal } from "../modal";

// API
import { getApiUrl } from "../../api/api-config";

// Assets
import CrossIcon from "../../assets/icons/cross-icon";
import {
  apiErrorMessage,
  noDataMessage,
} from "../../assets/errorMessageConstants";

// Components
import { Button, Loader } from "@gitlab-rtsensing/component-library";
import { TimeScaleData } from "../../api/get-year-and-quarter-list-dt";

// Library
import Multiselect from "multiselect-react-dropdown";

// Utils
import { getOktaToken } from "../../utils/commonMethods";
import EarningsDropdown from "../../components/earnings-dropdown";

type AddNoteModalProps = {
  isOpen: boolean;
  setClosedTagsModalList?: any;
  notesCategory?: any;
  notesCategoryLoader?: boolean;
  notesCategoryListError?: string;
  resetNotesCategories?: any;
  noteItemForEdit?: any;
  timeScaleData: TimeScaleData;
};

const AddNoteModal: React.FC<AddNoteModalProps> = (
  props: AddNoteModalProps
) => {
  const {
    isOpen,
    setClosedTagsModalList,
    notesCategory,
    notesCategoryLoader,
    notesCategoryListError,
    resetNotesCategories,
    timeScaleData,
  } = props;

  // List data for notes categories
  const [topicList, setTopicList]: any = React.useState([]);
  const [companyList, setCompanyList]: any = React.useState([]);
  const [productList, setProductList]: any = React.useState([]);
  const [associationList, setAssociationList]: any = React.useState("");
  const [notesText, setNotesText] =  React.useState<string[]>([""]);
  const [editYear, setEditYear] = React.useState<string>(
    Object.keys(timeScaleData)[1]
  );
  const [editQuarter, setEditQuarter] = React.useState<string>(
    timeScaleData[editYear][0]
  );

  // Loader and error for add note
  const [loaderOnAddNote, setLoaderOnAddNote]: any = React.useState(false);
  const [errorOnAddNote, setErrorOnAddNote]: any = React.useState("");

  // Validation Check
  const [validationError, setValidationError]: any = React.useState("");

  // State to track if the "+ Additional Note" button should be disabled
  const [isAddNoteDisabled, setIsAddNoteDisabled] = React.useState(true);

  const oktaToken = getOktaToken();

  const getNoteType = (category_name: string) => {
    if (category_name === "company") {
      category_name = "COMPANIES";
    } else if (category_name === "product") {
      category_name = "PRODUCTS";
    } else if (category_name === "topic") {
      category_name = "ASSOCIATIONS";
    } else if (category_name === "insights_categories") {
      category_name = "NOTE TYPE";
    }
    return category_name;
  };

  const onSelectCategoryItem = (selectedValue: any, categoryName: string) => {
    setValidationError("");
    if (categoryName === "company") {
      setCompanyList(selectedValue);
    }
    if (categoryName === "product") {
      setProductList(selectedValue);
    }
    if (categoryName === "topic") {
      setTopicList(selectedValue);
    }
    if (categoryName === "insights_categories") {
      setAssociationList(selectedValue[0].id);
    }
  };

  const onAddNoteBtnClick = async () => {
    setLoaderOnAddNote(true);
  
    const productPayload = productList.map((item: any) => item.id);
    const topicPayload = topicList.map((item: any) => item.id);
    const companyPayload = companyList.map((item: any) => item.id);
    const notePayload = notesText.filter((note: any) => note.trim() !== "");

    // Validation Check
    if (
      companyList.length === 0 ||
      notesText[0].trim() === "" ||
      notePayload.length === 0 ||
      associationList.length === 0
    ) {
      setValidationError("Please select all required fields");
      setLoaderOnAddNote(false);
      return;
    }

    const payload = {
      note_details: notePayload, 
      note_category: associationList,
      company_tag: companyPayload,
      product_tag: productPayload,
      association_tag: topicPayload,
      quarter: editQuarter[1],
      year: editYear,
    };
  
    try {
        const res: any = await axios({
          method: "post",
          url: getApiUrl("earnings-create-notes"),
          data: payload,
          headers: {
            Authorization: `Bearer ${oktaToken}`,
          },
        });
  
        if (res && res?.data && res?.status === 200) {
          resetNotesCategories();
          onCloseModal();
        } else {
          setErrorOnAddNote(noDataMessage);
        }
    } catch (error) {
      setErrorOnAddNote(apiErrorMessage);
    } finally {
      setLoaderOnAddNote(false);
    }
  };

  const onCloseModal = () => {
    setClosedTagsModalList(false);
    resetValues();
  };

  // Reset Values
  const resetValues = () => {
    setCompanyList([]);
    setProductList([]);
    setTopicList([]);
    setAssociationList("");
    setNotesText([""]);
    setValidationError("");
  };

  const addNoteField = () => {
    if (notesText.length < 5) {
      setNotesText([...notesText, ""]);
      setIsAddNoteDisabled(true);
    }
  };

  const handleNoteChange = (index: number, value: string) => {
    const newNotesText = [...notesText];
    newNotesText[index] = value;
    setNotesText(newNotesText);

    if (index === notesText.length - 1) {
      setIsAddNoteDisabled(value.trim() === "");
    } else {
      setIsAddNoteDisabled(newNotesText[index + 1].trim() === "");
    }
  };

  return (
    <>
      <CustomModal opened={isOpen} onClose={() => onCloseModal()}>
        <CustomModal.Header contentClassName="d-flex align-items-center">
          <CustomModal.Title title="Add Note" />
        </CustomModal.Header>
        {loaderOnAddNote ? (
          <div className="ext-add-notes-loader">
            <Loader />
          </div>
        ) : errorOnAddNote ? (
          <div className="ext-add-notes-error">{errorOnAddNote}</div>
        ) : (
          <div className="ext-add-edit-note-modal">
            {notesCategoryListError ? (
              <div className="ext-notes-category-error">
                {notesCategoryListError}
              </div>
            ) : (
              <>
                {notesCategoryLoader ? (
                  <div className="ext-insight-curation-loader">
                    <Loader />
                  </div>
                ) : (
                  <div className="ext-add-note-modal-responsive">
                    <div className="ext-add-note-modal-container">
                      {notesCategory.length > 0 &&
                        notesCategory.map((item: any, index: number) => {
                          return (
                            <div className="ops-mt-4" key={index}>
                              <span className="ext-note-label">
                                {getNoteType(item?.category_name)}
                                {item?.category_name === "topic" ||
                                item?.category_name === "product" ? (
                                  <></>
                                ) : (
                                  <span className="ext-note-required">*</span>
                                )}
                              </span>
                              <Multiselect
                                isObject={true}
                                onKeyPressFn={function noRefCheck() {}}
                                onRemove={function noRefCheck() {}}
                                onSearch={function noRefCheck() {}}
                                onSelect={(selectedItem) =>
                                  onSelectCategoryItem(
                                    selectedItem,
                                    item.category_name
                                  )
                                }
                                singleSelect={
                                  item.category_name === "insights_categories"
                                    ? true
                                    : false
                                }
                                options={item?.values}
                                selectedValues={[]}
                                placeholder="Select"
                                showArrow={true}
                                style={{
                                  chips: {
                                    background: "#E3E3E3",
                                    borderRadius: "6px",
                                    color: "black",
                                    fontSize: "12px",
                                    fontWeight: 500,
                                  },
                                }}
                                customCloseIcon={
                                  <CrossIcon
                                    fill="#585858"
                                    classname="custom-close-icon"
                                  />
                                }
                                hidePlaceholder={true}
                                displayValue="name"
                                avoidHighlightFirstOption={true}
                              />
                            </div>
                          );
                        })}
                      {timeScaleData && (
                        <div className="ops-mt-4 d-flex">
                          <EarningsDropdown
                            label="YEAR"
                            options={Object.keys(timeScaleData).reverse()}
                            selectedValue={editYear}
                            className="ext-col"
                            onMenuItemClick={(event) => {
                              setEditYear(event?.key);
                              setEditQuarter(timeScaleData[event?.key][0]);
                            }}
                          />
                          <EarningsDropdown
                            label="TIMEFRAME"
                            options={timeScaleData[editYear]}
                            selectedValue={editQuarter}
                            className="ext-col"
                            onMenuItemClick={(event) => {
                              setEditQuarter(event?.key);
                            }}
                          />
                        </div>
                      )}
                      {notesText.map((note: any, index: number) => (
                        <div className="ops-mt-4" key={index}>
                          <span className="ext-note-label">
                            {index === 0 ? "NOTE" : `NOTE ${index + 1}`}
                            {index === 0 && <span className="ext-note-required">*</span>}
                          </span>
                          <div>
                            <textarea
                              id={`note-${index}`}
                              name={`note-${index}`}
                              rows={4}
                              className="ext-note-textarea"
                              value={note}
                              onChange={(e) =>
                                handleNoteChange(index, e.target.value)
                              }
                            ></textarea>
                          </div>
                        </div>
                      ))}
                      {notesText.length < 5 && (
                        <div className="ops-mt-2">
                          <Button
                            label="+ Additional Note"
                            type="secondary"
                            className="ext-add-additional-note-btn"
                            onClick={addNoteField}
                            disabled={isAddNoteDisabled}
                          />
                        </div>
                      )}
                      <span className="ext-note-limit-message">
                        (MAXIMUM OF 5 NOTES PER SUBMISSION)
                      </span>
                      <div className="ops-mt-2">
                        {validationError && validationError.length ? (
                          <span className="ext-fs-12 ext-validation-error-msg">
                            {validationError}
                          </span>
                        ) : (
                          <></>
                        )}
                      </div>
                      <div className="ext-add-note-modal-footer ops-mt-5">
                        <></>
                        <Button
                          label={"ADD NOTE"}
                          type="secondary"
                          className="ext-add-note-btn"
                          onClick={() => onAddNoteBtnClick()}
                        />
                      </div>
                      {/* : <div className="ext-notes-category-error">{notesCategoryListError}</div>} */}
                    </div>
                  </div>
                )}
              </>
            )}
          </div>
        )}
      </CustomModal>
    </>
  );
};

export default AddNoteModal;
