interface Props {
  fill?: string;
  classname?: string;
}

const InsightIcon = ({ fill, classname }: Props) => {
  return (
   <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx="8" cy="8.5" r="8" fill="#0063C3"/>
<path d="M10.545 3.91003C10.0851 3.53183 9.54663 3.26105 8.9688 3.11746C8.39097 2.97386 7.78837 2.96108 7.20497 3.08003C6.42816 3.23695 5.71546 3.62107 5.15731 4.18367C4.59916 4.74628 4.22071 5.462 4.06997 6.24003C3.95989 6.82339 3.9799 7.42386 4.12855 7.9986C4.27721 8.57334 4.55085 9.1082 4.92997 9.56503C5.28181 9.96207 5.48349 10.4698 5.49997 11V12.5C5.49997 12.8979 5.65801 13.2794 5.93931 13.5607C6.22062 13.842 6.60215 14 6.99997 14H8.99997C9.3978 14 9.77933 13.842 10.0606 13.5607C10.3419 13.2794 10.5 12.8979 10.5 12.5V11.095C10.5168 10.5096 10.7318 9.94725 11.11 9.50003C11.7725 8.68042 12.0849 7.63244 11.9791 6.58385C11.8733 5.53525 11.3579 4.57078 10.545 3.90003V3.91003ZM9.49997 12.5C9.49997 12.6326 9.44729 12.7598 9.35352 12.8536C9.25976 12.9474 9.13258 13 8.99997 13H6.99997C6.86736 13 6.74019 12.9474 6.64642 12.8536C6.55265 12.7598 6.49997 12.6326 6.49997 12.5V12H9.49997V12.5ZM10.335 8.88003C9.83222 9.47633 9.5389 10.2211 9.49997 11H8.49997V9.50003C8.49997 9.36743 8.44729 9.24025 8.35352 9.14648C8.25976 9.05271 8.13258 9.00003 7.99997 9.00003C7.86736 9.00003 7.74019 9.05271 7.64642 9.14648C7.55265 9.24025 7.49997 9.36743 7.49997 9.50003V11H6.49997C6.48678 10.2341 6.20344 9.49741 5.69997 8.92003C5.36779 8.52203 5.14442 8.04474 5.05163 7.53471C4.95884 7.02467 4.9998 6.49929 5.17051 6.0098C5.34123 5.52031 5.63588 5.08341 6.02573 4.7417C6.41558 4.39999 6.88733 4.16513 7.39497 4.06003C7.83125 3.97021 8.28205 3.97866 8.71465 4.08477C9.14726 4.19088 9.5508 4.392 9.89599 4.67351C10.2412 4.95502 10.5194 5.30987 10.7103 5.71229C10.9013 6.11471 11.0002 6.55461 11 7.00003C11.0037 7.68497 10.7685 8.34977 10.335 8.88003Z" fill="white"/>
</svg>
  );
};

export default InsightIcon;
