import React, { Dispatch, SetStateAction }  from "react";
import "./index.scss";
import InfoIcon from "../../assets/icons/info-icon";
import { Button } from "@gitlab-rtsensing/component-library";
import CustomSwitch from "../toggle";
import classNames from 'classnames';
import { CloseIcon } from '../../assets/icons/close-icon';
import { Popover, Paragraph, Text } from "@opsdti-global-component-library/amgen-design-system";
//import colors from "../../utils/colors";

type Props = {
  cardHeader?: string;
  cardHeaderTag?: string;
  children: JSX.Element | JSX.Element[];
  isTooltip?: boolean;
  tooltipText?: string;
  tooltipTag?: string;
  isSubHeader?: string;
  subHeaderClass?: string;
  isEditable?: boolean;
  toggleQueryButon?: any;
  deleteAction?: any;
  showToggle?: boolean;
  toggleStatus?: boolean;
  setToggleStatus?: any;
  showBottomBorder?: boolean;
  imageSrc?: string;
  showImage?: boolean;
  showNoSpacingOnHeader?: boolean;
  noTitlePadding?: boolean;
  homeTilePadding?: boolean;
  noOutline?: boolean;
  newsCard?: boolean;
  showNewTag?: boolean;
  backgroundColor?: string;
  setIsOpen?: Dispatch<SetStateAction<boolean>>;
};

const Card: React.FC<Props> = (props: Props) => {
  const {
    cardHeader,
    cardHeaderTag,
    isTooltip,
    tooltipText,
    tooltipTag,
    subHeaderClass,
    isSubHeader,
    children,
    isEditable,
    toggleQueryButon,
    deleteAction,
    showToggle,
    toggleStatus,
    setToggleStatus,
    showBottomBorder = true,
    imageSrc,
    showImage = false,
    showNoSpacingOnHeader = false,
    noTitlePadding = false,
    homeTilePadding = false,
    noOutline = false,
    newsCard = false,
    showNewTag=false,
    backgroundColor,
    setIsOpen
  } = props;
  const headerClasses = classNames('ext-card-header card-header ext-d-flex', {
    'ops-mt-2': !noTitlePadding,
    'ext-card-header-spacing': !noTitlePadding,
    'ext-card-header-home-override': homeTilePadding,
  });

  return (
    <div className={noOutline ? "ext-card card" : "ext-card card ext-card-outline"} style={{backgroundColor:backgroundColor!=""?backgroundColor:'#FFF'}}>
      <div className="header-row">
        <div className={headerClasses} style={{backgroundColor:backgroundColor!=""?backgroundColor:'#FFF'}}>
          {showImage && (
            <div
              className="ext-earning-table-company-logo"
              style={{ paddingRight: "10px" }}
            >
              <img src={imageSrc} className="ext-table-company-icon" />
            </div>
          )}
          <div className="ext-card-header" style={{backgroundColor:backgroundColor!=""?backgroundColor:'#FFF'}}>
            <span className="ext-header-tag">{cardHeaderTag}</span>
            {cardHeaderTag !== undefined && " - "}
            {cardHeader}
          </div>
          {showNewTag && <span className="ext-card-header-btn ext-d-flex ext-header-tag-new" > <span>NEW</span> </span>}
          {isTooltip && (
            <div className="ext-tooltip">
                <Popover
                  id="tooltip"
                  data-html={true}
                  content={
                    <Paragraph>
                      <Text strong style={{color: "#d62728"}}>
                        {tooltipTag}
                        {tooltipTag !== undefined && ": "}
                      </Text>
                        {tooltipText}
                    </Paragraph>
                  }
                >
                  <a data-tooltip-id="tooltip">
                    <InfoIcon fill="#2F2F2F" />
                  </a>
                </Popover>
            </div>
          )}
          {isSubHeader && (
            <div className={`${subHeaderClass}`}>{isSubHeader}</div>
          )}

          {isEditable ? (
            <div className="ext-card-header-btn">
              <Button
                type="secondary"
                label="Edit"
                className="ext-card-btn-design ops-mx-3"
                onClick={() => toggleQueryButon(true)}
              />
              <Button
                type="secondary"
                label="Delete"
                className="ext-card-btn-design ops-mx-2"
                onClick={() => deleteAction(true)}
              />
            </div>
          ) : (
            <></>
          )}
          {showToggle ? (
            <span className="ext-card-header-btn ext-d-flex">
              {" "}
              <span className="ext-card-detaile-view ops-mx-3">
                DETAILED VIEW
              </span>{" "}
              <CustomSwitch
                activeToggle={toggleStatus}
                setActiveToggle={setToggleStatus}
              />
            </span>
          ) : (
            <></>
          )}
        </div>
        {setIsOpen !== undefined && <CloseIcon height={14} width={14} color="#2F2F2F" className="close-icon" onClick={() => setIsOpen(false)}/>}
      </div>
      {showBottomBorder && (
        <hr
          className={homeTilePadding ? `ext-header-styling-home` : noTitlePadding ? `ext-header-styling-no-padding` : `ext-header-styling ${showNoSpacingOnHeader && "ops-mt-0 ops-mb-0"}`}
        />
      )}
      <div className={`ext-card-body card-body ${newsCard ? 'ext-card-body-news' : ''}`}>{children}</div>
    </div>
  );
};

export default Card;
