
interface Props {
  fill?: string;
  classname?: string;
}

const MinusIcon = ({ classname, fill }: Props) => {
  return (
    <svg className={classname} width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="4.66675" y="7.73386" width="6.66667" height="1.33333" rx="0.666667" fill={ fill ? fill : "white"}/>
    </svg>
  ) ;
};

export default MinusIcon;
