import { Button, Modal } from "@gitlab-rtsensing/component-library";
import "./index.scss";
import Tag from "../tag";
import { formatAmgenVenture } from "../../utils/commonMethods";
type TagsProps = {
  isOpen: boolean;
  data?: any;
  headerName?: string;
  isModifySection?: boolean;
  getModifySectionflag? : any
  setClosedTagsModalList?: any;
  selectedTags: any;
  setUserSelectedTags:any;
};

const TagsModal = (props: TagsProps) => {
  const {
    isOpen,
    setClosedTagsModalList,
    data,
    headerName,
    selectedTags,
    setUserSelectedTags
  } = props;

  
  const isTagSelected = (category: string, tag: string) => {
    const selectedCategotyTags = selectedTags[category];
    if(selectedCategotyTags && selectedCategotyTags.includes(tag)){
        return true;
    }
    return false;
  }

  const onButtonClicked = () => {
        setClosedTagsModalList(!isOpen)
  }

  return (
    <div >
      <Modal opened={isOpen} onClose={() => setClosedTagsModalList(!isOpen) }>
        <Modal.Header contentClassName="d-flex align-items-center">
          <Modal.Title title={headerName} />
        </Modal.Header>

        <div className="ext-modal-header ops-my-4">Select tags to include in your query</div>
        <div className="ext-custom-tags-wrapper">
          {data.map((item: any, index:boolean) => {

            return (
              item.values.length > 0 && item.category_name === "company" ? 
              <div>
                <div className="ext-custom-tags-subheader">{item.category_name}</div>
                <div className="ext-custom-tags ops-my-3">
                  {item &&
                    item.values &&
                    item.values.map((tag: any, index: boolean) => {
                      const revisedValue = tag.split("$$");
                      return (
                        <Tag
                          code={revisedValue[2]}
                          key={revisedValue[0]}
                          name={revisedValue[1]}
                          type={item.category_name}
                          canClick={true}
                          onClick={() => {setUserSelectedTags({"category": item.category_name, "tag_name": revisedValue[2]})}}
                          canFilter={true}
                          onFilter={() => {}}
                          isFilteredOnTag={isTagSelected(item.category_name, revisedValue[2])}
                          showMinusIcon={true}
                          showAmgenBlueColor={true}
                        />
                      );
                    })}
                </div>
                </div> : item.values.length > 0 &&
                <div>
                <div className="ext-custom-tags-subheader">{item.category_name}</div>
                <div className="ext-custom-tags ops-my-3">
                  {item &&
                    item.values &&
                    item.values.map((tag: any, index: boolean) => {
                      return (
                        //create tag component with tickColor as prop and boolean value
                        <Tag
                          code={tag}
                          key={item}
                          name={formatAmgenVenture(tag)}
                          type={item.category_name}
                          canClick={true}
                          onClick={() => {setUserSelectedTags({"category": item.category_name, "tag_name": tag})}}
                          canFilter={true}
                          onFilter={() => {}}
                          isFilteredOnTag={isTagSelected(item.category_name, tag)}
                          showMinusIcon={true}
                          showAmgenBlueColor={true}
                        />
                      );
                    })}
                </div>
                </div>
            )
          })}
        </div>
        <div className="ext-submit-button ops-mt-2">
            <Button
            label="add selected tags"
            className="ext-submit-button-styling"
            onClick={() => { onButtonClicked();}}
            type="primary"
            />
        </div>
      </Modal>
    </div>
  );
};

export default TagsModal;
