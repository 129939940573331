import React, { useEffect } from "react";
import { CustomModal } from "../modal";
import axios from "axios";
import { Popover } from '@opsdti-global-component-library/amgen-design-system';
import InfoIcon from "../../assets/icons/info-icon";
import "./index.scss";
import { Button, Loader } from "@gitlab-rtsensing/component-library";
import {
  revenueEpsDropdownOptions,
  actualConsensusDropdownOptions,
} from "../../assets/constants/constants-text";
import { apiErrorMessage, noDataMessage } from "../../assets/errorMessageConstants";

import { getApiUrl } from "../../api/api-config";
import { getOktaToken } from "../../utils/commonMethods";
import EarningsDropdown from "../../components/earnings-dropdown";

type EarningModalProps = {
  isOpenEarningModal: boolean;
  selectedCompany: any;
  closedEarningModal: any;
  timeScaleData?: any;
  editableMetricsData?: any;
  editableMetricsDataError?: string;
  editableMetricsDataLoading?: boolean;
  editableMetricsPreviousData?: any;
  editableMetricsPreviousDataError?: string;
  editableMetricsPreviousDataLoading?: boolean;
  selectedQuarter: string;
  getFinancialEditableMetricsPreviousYearData?: any;
  getFinancialEditableMetricsCurrentYearData?: any;
  getTableData?: any;
  keysForComparisonData?: any;
  getEarningsComparativeAnalysisData?: any;
  selectedYear: string;
};

type Field = {
  metricName: string,
  metricShortName: string,
  value: number | string | undefined | null;
  isCurrentlyEdited: boolean; // Starts False: Ui sets after current user changes a value
  isEdited: boolean;          // DB value recieved is not original (updated by some user)
  valuePrev: number | string | undefined | null;
  isCurrentlyEditedPrev: boolean; // Starts False: Ui sets after current user changes a value
  isEditedPrev: boolean;          // DB value recieved is not original (updated by some user)
}

type Fields = Field[]

const EarningModal: React.FC<EarningModalProps> = (
  props: EarningModalProps
) => {
  const {
    isOpenEarningModal,
    selectedCompany,
    closedEarningModal,
    timeScaleData,
    editableMetricsData,
    editableMetricsDataError,
    editableMetricsDataLoading,
    editableMetricsPreviousData,
    selectedQuarter,
    selectedYear,
    getFinancialEditableMetricsCurrentYearData,
    getFinancialEditableMetricsPreviousYearData,
    getTableData,
    keysForComparisonData,
    getEarningsComparativeAnalysisData,
  } = props;

  const currentCurrency = editableMetricsData?.[0]?.currency;
  const previousCurrency = editableMetricsPreviousData?.[0]?.currency;
  const [editYear, setEditYear] = React.useState<string>(selectedYear);
  const [editQuarter, setEditQuarter] = React.useState<string>(selectedQuarter);

  const {amgenRankingValue} = keysForComparisonData;

  const getCurrencyIcon = (currency: string | undefined) => {
    if (currency == "US Dollar") return "$";
    if (currency == "Euro") return "€";
    if (currency == "Pound Sterling") return "£";
    if (currency == "Swiss Franc") return "CHF";
    if (currency == "Danish Krone") return "DKK";
    return ""
  };

  // Metric Values
  const [fields, setFields] = React.useState<Fields>([]);
  const [errorMessage, setErrorMessage] = React.useState("");

  const [saveButtonDisabled, setSaveButtonDisabled] = React.useState(true);

  useEffect(() => { // called everytime isOpenModal this gets called
    const fields_init: Fields = []
    if(editableMetricsData.length !== editableMetricsPreviousData.length) return

    // This for loop only runs if for each item in editableMetricsData and editableMetricsPrevious Data are truthy
    editableMetricsData && editableMetricsPreviousData && editableMetricsData.forEach((item: any, i: number) => {
      // splice removes 1 currency, there's still an additional
      if(item.metric_name === undefined || item.metric_name === "CURRENCY") return;
      const format_vals = (metric_name: string, val: number | string | null) => {
        let formatted = val
        if(typeof val === 'number') {
          if (["EPS","EPS CONSENSUS"].includes(metric_name)) formatted = val.toFixed(2)
          else formatted = val.toFixed(1)
        }
        return formatted
      }

      const formattedValue = format_vals(item.metric_name, item.metric_values?.value)
      const formattedValuePrev = format_vals(item.metric_name, editableMetricsPreviousData[i].metric_values?.value)

      fields_init.push({
        metricName: item.metric_name,
        metricShortName: item.metric_short_name,
        value: formattedValue,
        isEdited: item.metric_values?.is_edited, 
        isCurrentlyEdited: false,

        // Previous Data
        valuePrev: formattedValuePrev,
        isEditedPrev: editableMetricsPreviousData[i].metric_values?.is_edited,
        isCurrentlyEditedPrev: false
      })
    });
    setFields(fields_init)
  }, [editableMetricsData, editableMetricsPreviousData]);

  useEffect(() => {
    setSaveButtonDisabled(false)
  }, [isOpenEarningModal]);

  const onChangeInputValue = (metricName:string, value: any, prev:boolean = false) => {
    const isString = /^[^a-zA-Z]*$/.test(value);
    if (!isString) return;
    
    if(value === "" || value === null) {
      setSaveButtonDisabled(true)
    }
    else { 
      setSaveButtonDisabled(false)
    }

    let newFields = [...fields]
    const index = newFields.findIndex(item => item.metricName === metricName)

    if(prev){
      newFields[index].valuePrev = value
      newFields[index].isCurrentlyEditedPrev = true;
    } else {
      newFields[index].value = value
      newFields[index].isCurrentlyEdited = true;
    }
    setFields(newFields)
  }

  const oktaToken = getOktaToken();

  const onChangeDropdownValue = (metricName:string, value: any) => {
    let newFields = [...fields]
    const index = newFields.findIndex(item => item.metricName === metricName)

    // Previous values do not exist for dropdowns
    newFields[index].value = value
    newFields[index].isCurrentlyEdited = true;

    setFields(newFields)
  }

  const onSaveChanges = async () => {
    const prevInput:any = {};
    const currInput:any = {};
    let editMade: boolean = false; //tracker to see if any changes were actually made

    fields.forEach((item: any) => { //edit currInput and prevInput with the updated values for each metric
      if(item.isCurrentlyEdited) currInput[item.metricShortName] =  item.value
      if(item.isCurrentlyEditedPrev) prevInput[item.metricShortName] = item.valuePrev
      if (item.isCurrentlyEdited){
        editMade = true;
      }
    })
 
    if (editMade == false){ //if no changes were made, close the modal (same logic as cancel button)
      closedEarningModal(false);
      return;
    } 
    const payload = [
      {
        "year": Number(editYear),
        "quarter": editQuarter,
        "data": {
          ...currInput
        }
      },
      {
        "year": Number(editYear) - 1,
        "quarter": editQuarter,
        "data": {
          ...prevInput
        }
      }
    ]

    if(!saveButtonDisabled){
      
      try {
        const res:any = await axios({
          method: "post",
          url: `${getApiUrl(
            "edit-financial-summary-metrics"
          )}?company_ticker=${selectedCompany?.company_ticker}`,  
          data: payload,
          headers: {
            Authorization: `Bearer ${oktaToken}`,
          },
        });
        
        if(res && res?.data && res?.status === 200) {
          closedEarningModal(false);

          // To get earnings table data
          getTableData();

          // To get earnings comparative analysis data
          getEarningsComparativeAnalysisData("current", amgenRankingValue, editYear);
          getEarningsComparativeAnalysisData("previous", amgenRankingValue, Number(editYear) - 1);
        } else {
          setErrorMessage(noDataMessage);
        }
      } catch (error) {
        setErrorMessage(apiErrorMessage)
      }
    }
  }

  return (
    <CustomModal
      className="ext-earning-modal-container"
      opened={isOpenEarningModal}
      onClose={() => {
        setSaveButtonDisabled(true);
        closedEarningModal(false);
      }}
      unsetWidth={true}
    >
      <CustomModal.Header>
        <CustomModal.Title
          title={
            <div
              className="ext-earning-table-company-logo ext-d-flex"
              style={{ alignItems: "center" }}
            >
              <img
                src={selectedCompany?.["company_image"]}
                className="ext-table-company-icon"
              />
              <span
                style={{
                  paddingLeft: "10px",
                  color: "#2F2F2F",
                  fontSize: "14px",
                  fontWeight: 700,
                }}
              >{`Edit ${selectedCompany?.company_short_name} Earnings ${editQuarter === "Full Year" ? "FY" : editQuarter} ${editYear}`}</span>
            </div>
          }
        />
      </CustomModal.Header>
      {editableMetricsDataError ? (
        <div className="ext-earning-table-error">
          {editableMetricsDataError}
        </div>
      ) : editableMetricsDataLoading ? (
        <div className="ext-earning-table-loader">
          <Loader />
        </div>
      ) : (
        <>
        {
          errorMessage ? <div className="ext-earning-table-error">{errorMessage}</div> :
        
        <div className="ext-earning-table-label" 
          >
          <div style={{
            height: "500px",
            overflowY: "scroll",
            overflowX: "hidden",
          }}>
          <div className="ext-row ops-my-4">
            <EarningsDropdown
              label="YEAR"
              options={Object.keys(timeScaleData).reverse()}
              selectedValue={editYear}
              className="ext-col"
              onMenuItemClick={(event)=> {
                setEditYear(event?.key)
                setEditQuarter(timeScaleData[event?.key][0])
                getFinancialEditableMetricsCurrentYearData(
                  selectedCompany,
                  timeScaleData[event.key][0],
                  event?.key
                );
                getFinancialEditableMetricsPreviousYearData(
                  selectedCompany,
                  timeScaleData[event.key][0],
                  event?.key
                );
              }}
             
            />
            <EarningsDropdown
              label="TIMEFRAME"
              options={timeScaleData[editYear]}
              selectedValue={editQuarter}
              className="ext-col"
              onMenuItemClick={(event)=> {
                setEditQuarter(event?.key)
                getFinancialEditableMetricsCurrentYearData(
                  selectedCompany,
                  event?.key,
                  editYear
                );
                getFinancialEditableMetricsPreviousYearData(
                  selectedCompany,
                  event?.key,
                  editYear
                );
              }}
             
            />
          </div>

          <div style={{ borderBottom: "1px solid #E3E3E3" }}></div>

          <div className="ext-row ops-my-4">
            <div className="ext-col-lg-4 ext-col-md-4 ext-col-sm-4"></div>
            <div className="ext-col-lg-4 ext-col-md-4 ext-col-sm-4">
              {editQuarter} {editYear}
            </div>
            <div className="ext-col-lg-4 ext-col-md-4 ext-col-sm-4">
              {editQuarter} {Number(editYear) - 1}
            </div>
          </div>

          {/* Render first 12 components in the field */}
          { Object.keys(fields).length > 0 &&
            fields?.slice(0, 10)?.map((item: any, index: any) => {
              return (
                <div key={index} className="ext-row ops-my-4">
                  <div className="ext-col-lg-4 ext-col-md-4 ext-col-sm-4 ext-earning-table-label">
                    {item?.metricName}
                    {/* Display info hover over for REVENUE CONSENSUS and EPS CONSENSUS fields */}
                    { ["REVENUE CONSENSUS", "EPS CONSENSUS"].includes(item.metricName)?
                      <Popover
                        id="tooltip"
                        data-html={true}
                        content={"Consensus numbers are sourced from FactSet and updated at 6:00 PM PT one day before each peer's earnings call."}
                      >
                      <a data-tooltip-id="tooltip">
                        <InfoIcon fill="#9C9C9C" 
                        />
                      </a>
                      </Popover> : null
                    }     
                  </div>
                  <div className="ext-col-lg-4 ext-col-md-4 ext-col-sm-4 ext-d-flex ext-align-items-center">
                    <div className="ext-earning-text-box ext-d-flex ext-justify-content-between">
                      <div className="ext-earning-currency-icon">
                        { getCurrencyIcon(currentCurrency) }
                      </div>
                 
                      <input
                        type="text"
                        className="ext-earning-input-area"
                        value={item.value}
                        onChange={(event) => onChangeInputValue(item.metricName, event.target.value)}/>
                       
                        { ["EPS", "EPS CONSENSUS"].includes(item.metricName)?
                              null:
                             <span className="ext-earning-currency-label">
                              M
                             </span> 
                        }     
                    </div>
                    {item.isEdited && (
                      <div
                        style={{
                          height: "8px",
                          width: "8px",
                          backgroundColor: "#0063C3",
                          marginLeft: "10px",
                          borderRadius: "20px",
                        }}
                      ></div>
                    )}
                  </div>
                  {(index < 8 &&  !["REVENUE CONSENSUS", "EPS CONSENSUS"].includes(item.metricName)) ?
                   (
                    <div className="ext-col-lg-4 ext-col-md-4 ext-col-sm-4 ext-d-flex ext-align-items-center">
                      <div className="ext-earning-text-box ext-d-flex ext-justify-content-between">
                        <div className="ext-earning-currency-icon">
                          {getCurrencyIcon(previousCurrency)}
                        </div>
                        <input
                          type="text"
                          className="ext-earning-input-area"
                          value={item.valuePrev}
                          onChange={(event) => onChangeInputValue(item.metricName, event.target.value, true)}/>
                          { item.metricName === "EPS" ?
                              null:
                            <span className="ext-earning-currency-label">
                              M
                            </span>
                          }
                      </div>
                      {item.isEditedPrev && (
                        <div
                          style={{
                            height: "8px",
                            width: "8px",
                            backgroundColor: "#0063C3",
                            marginLeft: "10px",
                            borderRadius: "20px",
                          }}
                        ></div>
                      )}
                    </div>
                  ): null}
                </div>
              );
            })}

          <div className="ext-row ops-my-4 ext-revised-break">
            <div className="ext-col-lg-4 ext-col-md-4 ext-col-sm-4"></div>
            <div className="ext-col-lg-4 ext-col-md-4 ext-col-sm-4">
              REVISED
            </div>
            <div className="ext-col-lg-4 ext-col-md-4 ext-col-sm-4"></div>
          </div>

          {/* Render revised components in the field */}
          {Object.keys(fields).length > 0 && 
            fields?.slice(10, Object.keys(fields).length)?.map((item: any, index: number) => {
              const isGuidanceMetric = item.metricName.toLowerCase().includes("guidance change");
              return (
                <div key={index} className="ext-row ops-my-4 ext-align-items-center">
                  <div className="ext-col-lg-4 ext-col-md-4 ext-col-sm-4 ext-earning-table-label ops-mt-3">
                    {item.metricName}
                  </div>
                  <div className="ext-col-lg-4 ext-col-md-4 ext-col-sm-4">
                    {isGuidanceMetric ? (
                          <div className="ext-col-lg-4 ext-col-md-4 ext-col-sm-4 ext-d-flex ext-align-items-center">
                            <div className="ext-earning-text-box ext-d-flex ext-justify-content-between">
                            <input
                              type="text"
                              className="ext-earning-input-area"
                              defaultValue={item.value} // Use defaultValue for initial rendering
                              onChange={(event) => {
                                const newValue = event.target.value;
                                onChangeInputValue(item.metricName, newValue); 
                              }}
                              placeholder={item.value ? item.value : ""}
                            />
                                <span className="ext-earning-currency-label">
                                  %
                                </span>
                              </div>
                              {item.isEditedPrev && (
                                <div
                                  style={{
                                    height: "8px",
                                    width: "8px",
                                    backgroundColor: "#0063C3",
                                    marginLeft: "10px",
                                    borderRadius: "20px",
                                  }}
                                ></div>
                              )}
                            </div>
                    ) : (
                      <select
                        className="ext-article-card-select ext-w-100 ops-mt-2"
                        aria-label="Select Option"
                        defaultValue={"Select Option"}
                        onChange={(event) => onChangeDropdownValue(item.metricName, event?.target.value)}
                        value={item.value}
                      > 
                        <option
                          selected
                          defaultValue={"Select"}
                          value={""}
                          disabled
                        >
                          &nbsp;&nbsp; Select
                        </option>
                        {item?.metricName.toLowerCase().includes("guidance")
                          ? revenueEpsDropdownOptions.map((option: any) => (
                              <option key={option.value} value={option.value}>
                                &nbsp;&nbsp; {option.label}
                              </option>
                            ))
                          : actualConsensusDropdownOptions.map((option: any) => (
                              <option key={option.value} value={option.value}>
                                &nbsp;&nbsp; {option.label}
                              </option>
                            ))}
                      </select>
                    )}
                  </div>
                  <div className="ext-col-lg-4 ext-col-md-4 ext-col-sm-4">
                    {item.isEdited && (
                      <div
                        style={{
                          height: "8px",
                          width: "8px",
                          backgroundColor: "#0063C3",
                          borderRadius: "20px",
                        }}
                      ></div>
                    )}
                  </div>
                </div>
              );
            })}
 
              </div>

          <div className="ext-d-flex ext-align-items-center">
            <div
              style={{
                height: "8px",
                width: "8px",
                backgroundColor: "#0063C3",
                marginRight: "10px",
                borderRadius: "20px",
              }}
            ></div>
            <div>INDICATES THAT FACTSET DATA WAS OVERRIDDEN</div>
          </div>

          <div className="ext-d-flex ops-mt-3">
            <div className="ext-w-50 ops-my-1">
              <Button
                label="CANCEL"
                onClick={() => {
                  closedEarningModal(false);
                }}
                type="secondary"
                className="ext-earning-view-button"
              />
            </div>
            <div className="ext-w-50 ops-m-1">
              { !saveButtonDisabled ? (
                  <Button
                  className="ext-earning-save-button"
                  label="SAVE CHANGES"
                  onClick={() => onSaveChanges()}
                  type="secondary"
                />
                ) : (
                  <Button
                    disabled
                    label="SAVE CHANGES"
                    className="ext-w-100"
                    onClick={() => {}}
                    type="secondary"
                  />
                )}
            </div>
          </div>
        </div>}
      </>
      )}
    </CustomModal>
  );
};

export default EarningModal;
