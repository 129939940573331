export const dowJones = "dow-jones";
export const dowJonesSource = "Dow Jones & Company, Inc.";

export const questionSet = [
    {
      key: 1,
      question: "How are Lumakras sales doing?"
    },
    {
      key: 2,
      question: "How were Amgen's first quarter earnings?"
    },
    {
      key: 3,
      question: "What are some of Amgen's notable partnerships and recent acquisitions?"
    },
  ]
export const questionSetSupply = [
    {
      key: 1,
      question: "Whats the status of Right First Time?"
    },
    {
      key: 2,
      question: "What factors led to the On Time Delivery being Red?"
    },
    {
      key: 3,
      question: "Why are the alerts red for Commercial Product Supply?"
    },
  ]

export const earningConstants = {
  earningFinancialSummary: "Earnings Financial Summary",
  earningsComparativeAnalysis: "Earnings Comparative Analysis",
  notableEarningInsides: "Investor Relations Insights",
  earningsSentiment: "Earnings Sentiment",
}
export const earningsInsightsNoteTypes = [
  {label: "All", value: "all"},
  {label: "Earnings Insight", value: "Earnings Insight"},
  {label: "Investor Relations Insights", value: "Investor Relations Insights"},
]

export const unavailableNoteText = "No Note Available";
export const noDataAvailableText = "No Data Available";

export const revenueEpsDropdownOptions = [
  {label: "Raised", value: "RAISED"},
  {label: "Lowered", value: "LOWERED"},
  {label: "Re-affirmed", value: "REAFFIRMED"},
  {label: "Narrowed", value: "NARROWED"},
  {label: "Widened", value: "WIDENED"},
  {label: "N/A", value: "N/A"},
]

export const actualConsensusDropdownOptions = [
  {label: "Beat", value: "BEAT"},
  {label: "Miss", value: "MISS"},
  {label: "In-Line", value: "IN-LINE"},
  {label: "N/A", value: "N/A"},
]