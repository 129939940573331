// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(//fonts.googleapis.com/css?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ext-body{margin:0;font-family:"Inter";-webkit-font-smoothing:antialiased;-moz-osx-font-smoothing:grayscale}.ext-body #project{overflow-x:hidden}code{font-family:"Inter"}.ext-body::-webkit-scrollbar{display:none}`, "",{"version":3,"sources":["webpack://./src/index.scss"],"names":[],"mappings":"AAGA,UACE,QAAA,CACA,mBAAA,CACA,kCAAA,CACA,iCAAA,CACA,mBACE,iBAAA,CAIJ,KACE,mBAAA,CAGF,6BACE,YAAA","sourcesContent":["@import url(\"//fonts.googleapis.com/css?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap\");\n@import \"./assets/css/variables.scss\";\n\n.ext-body {\n  margin: 0;\n  font-family: \"Inter\";\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n  #project {\n    overflow-x: hidden;\n  }\n}\n\ncode {\n  font-family: \"Inter\";\n}\n\n.ext-body::-webkit-scrollbar {\n  display: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
