import React, { useEffect, useMemo } from "react";
import { MetricCard } from "@gitlab-rtsensing/component-library";
import "./index.scss";

type ModelProps = {
  title: React.ReactNode;
  icon?: JSX.Element;
  className?: string;
  children?: JSX.Element;
  closeEvent: () => void;
  visible: boolean;
  childClassName?: string;
  transcriptsModalWidthSizedClass?: string
};

const ExternalModal = (props: ModelProps) => {
  const { title, icon, className, children, visible, closeEvent, childClassName, transcriptsModalWidthSizedClass } = props;

  const classNames = ([...args]: string[]) => [...args].join(" ");

  useEffect(() => {
    const handelEsc = (e: KeyboardEvent) =>
      e.key === "Escape" ? closeEvent() : null;

    document.addEventListener("keydown", handelEsc, { once: true });
    return () => document.removeEventListener("keydown", handelEsc);
  }, [closeEvent]);

  const visibleClasses = classNames([
    "ext-d-block",
    "ext-d-md-flex",
    "ext-justify-content-center"
  ]);

  const classes = useMemo(
    () =>
      classNames([
        visible ? visibleClasses : "d-none",
        "ext-position-fixed",
        "ext-top-0",
        "ext-start-0",
        "ext-h-100",
        "ext-w-100",
        "ext-modal",
        "ext-align-items-center"
      ]),
    [visible, visibleClasses]
  );

  const metricCardClasses = useMemo(
    () =>
      classNames([
        className ? className : "",
        "ops-mt-8",
        "ext-modal-content",
        "ext-overflow-hidden",
        "ext-slide-up",
        "ext-align-items-center"
      ]),
    [className]
  );

  return (
    <div onClick={() => closeEvent()} className={classes} role="dialog">
      <span className={`${
        transcriptsModalWidthSizedClass ? transcriptsModalWidthSizedClass : "ext-modal-width-sized"
          } ext-d-flex ext-justify-content-center ext-align-items-center`}
          onClick={(e) => e.stopPropagation()}>
        <MetricCard className={metricCardClasses}>
          <>
            <MetricCard.Header className="ext-d-flex ext-justify-content-between ops-m-3 modal-header">
              <>{title}</>
              <button
                aria-label="modal-close"
                onClick={() => closeEvent()}
                className="ext-modal-btn"
              >
                {icon}
              </button>
            </MetricCard.Header>
            <MetricCard.Content className={`${childClassName && childClassName} "ext-d-flex ext-mh-100 ext-flex-grow-1 ext-flex-column ops-pb-5"`}>
              <div>{children}</div>
            </MetricCard.Content>
          </>
        </MetricCard>
      </span>
    </div>
  );
};

export default ExternalModal;
