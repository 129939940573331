import React from "react";
import { Popover, Label, DatePicker } from '@opsdti-global-component-library/amgen-design-system';
import "./index.scss";
import InfoIcon from "../../assets/icons/info-icon";
import dayjs, { Dayjs } from "../../utils/dayjs";

type Props = {
  label?: string;
  maxDate: Dayjs;
  minDate: Dayjs;
  verbageText?: string;
  selectedDate: Dayjs;
  onChange: (date: Dayjs) => void;
};

const DateSelector: React.FC<Props> = (props: Props) => {
  const { label, verbageText, selectedDate, maxDate, minDate } = props;
  const minLimitDate: Dayjs = minDate ? minDate : dayjs().subtract(89, 'days');
  const maxLimitDate: Dayjs = maxDate ? maxDate : dayjs();
  return (
    <div className="ext-custom-date-picker">
      <div className="date-picker-label">
        <Label>{label}</Label>
        {verbageText && (
          <Popover
            className="date-picker-popover"
            id="tooltip"
            data-html={true}
            content={verbageText}
          >
            <a data-tooltip-id="tooltip">
              <InfoIcon fill="#9C9C9C" />
            </a>
          </Popover>
        )}
      </div>
      <div>
        <DatePicker
          value={selectedDate}
          disabledDate={(current) => (current > maxLimitDate.endOf('day') || current < minLimitDate.endOf('day'))}
          format={"MM/DD/YYYY"}
          onChange={(date) => props.onChange(date as Dayjs)}
          allowClear={false}
          className="date-picker"
        ></DatePicker>
      </div>
    </div>
  );
};

export default DateSelector;
