type IconProps = {
  height?: number;
  width?: number;
  onClick?: () => void;
  className?: string;
  color?: string;
};

export const CloseIcon = (props: IconProps) => {
  const { width = 20, height = 20, onClick, color = "#9C9C9C", className } = props;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
    >
      <path
        d="M17.5 17.4556L2.5 2.87341"
        stroke={color}
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.5 2.87341L2.5 17.4557"
        stroke={color}
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
