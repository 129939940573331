import './index.scss';

type Props = {
    activeToggle? : boolean;
    setActiveToggle?: any;
}

const CustomSwitch = (props: Props) => {
    const { activeToggle, setActiveToggle } = props;

    const toggleSwitch = () => {
        setActiveToggle(!activeToggle);
    }
    return (
             
        <div onClick={() => toggleSwitch()} className="ToggleSwitch">
          <div className={activeToggle ? 'knob active' : 'knob '} />
        </div>
    )
} 

export default CustomSwitch;

  