interface Props {
  fill: string;
  classname?: string;
}

const Location = ({ fill, classname }: Props) => {
  return (
    <svg
      width="8"
      height="10"
      viewBox="0 0 8 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4 0C2.93913 0 1.92172 0.421427 1.17157 1.17157C0.421427 1.92172 0 2.93913 0 4C0 6.7 3.525 9.75 3.675 9.88C3.76557 9.95747 3.88082 10 4 10C4.11918 10 4.23443 9.95747 4.325 9.88C4.5 9.75 8 6.7 8 4C8 2.93913 7.57857 1.92172 6.82843 1.17157C6.07828 0.421427 5.06087 0 4 0ZM4 8.825C2.935 7.825 1 5.67 1 4C1 3.20435 1.31607 2.44129 1.87868 1.87868C2.44129 1.31607 3.20435 1 4 1C4.79565 1 5.55871 1.31607 6.12132 1.87868C6.68393 2.44129 7 3.20435 7 4C7 5.67 5.065 7.83 4 8.825ZM4 2C3.60444 2 3.21776 2.1173 2.88886 2.33706C2.55996 2.55682 2.30362 2.86918 2.15224 3.23463C2.00087 3.60009 1.96126 4.00222 2.03843 4.39018C2.1156 4.77814 2.30608 5.13451 2.58579 5.41421C2.86549 5.69392 3.22186 5.8844 3.60982 5.96157C3.99778 6.03874 4.39991 5.99913 4.76537 5.84776C5.13082 5.69638 5.44318 5.44004 5.66294 5.11114C5.8827 4.78224 6 4.39556 6 4C6 3.46957 5.78929 2.96086 5.41421 2.58579C5.03914 2.21071 4.53043 2 4 2ZM4 5C3.80222 5 3.60888 4.94135 3.44443 4.83147C3.27998 4.72159 3.15181 4.56541 3.07612 4.38268C3.00043 4.19996 2.98063 3.99889 3.01921 3.80491C3.0578 3.61093 3.15304 3.43275 3.29289 3.29289C3.43275 3.15304 3.61093 3.0578 3.80491 3.01921C3.99889 2.98063 4.19996 3.00043 4.38268 3.07612C4.56541 3.15181 4.72159 3.27998 4.83147 3.44443C4.94135 3.60888 5 3.80222 5 4C5 4.26522 4.89464 4.51957 4.70711 4.70711C4.51957 4.89464 4.26522 5 4 5Z"
        fill="#585858"
        className={classname}
      />
    </svg>
  );
};

export default Location;
