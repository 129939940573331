import React from 'react';
import './index.scss';
import { BASE_ROUTE } from "../../../../utils/routing";
import { Title } from '@opsdti-global-component-library/amgen-design-system';
import { MoreIntelligenceCard } from './more-intelligence-card';

const PEER_BIG_SHEET_SUMMARY = "Review key financial metrics for Amgen and 16 peers, including revenue, operating income, EPS, stock data, P/E ratios, and total shareholder return, sourced from FactSet. Also access both prior year actuals and current year estimates."
const ONCOLOGY_SUMMARY = "Track external events that may impact our oncology portfolio, using AI to classify news by disease area, product, target, modality, phase, and competitors, ensuring focused insights."
const AMGEN_VENTURES_SUMMARY = "Get external updates and news for over 50 portfolio companies and monitor insights on progress and key developments relevant to our investments."

export const MoreIntelligence: React.FC = () => {

    return (
        <div style={{marginTop: '40px'}}className="ext-more-intelligence">
          <Title style={{ marginBottom: '16px'}} level={2}>Explore More Intelligence Features</Title>
          <div className="ext-more-intelligence-container">
            <MoreIntelligenceCard 
              title="Peer Big Sheet" 
              summary={PEER_BIG_SHEET_SUMMARY} 
              buttonLink={`${BASE_ROUTE}/peer-big-sheet`} 
              imageSrc={`${process.env.REACT_APP_DOMAIN}/PeerBigSheet.png`}
              imageAlt="Peer Big Sheet Image" 
            />
            <MoreIntelligenceCard 
              title="Oncology News" 
              summary={ONCOLOGY_SUMMARY}
              buttonLink={`${BASE_ROUTE}/ta/oncology`}
              imageSrc={`${process.env.REACT_APP_DOMAIN}/Oncology.png`}
              imageAlt="Oncology Image" 
            />
            <MoreIntelligenceCard 
              title="Amgen Ventures"
              summary={AMGEN_VENTURES_SUMMARY}
              buttonLink={`${BASE_ROUTE}/amgen-ventures`}
              imageSrc={`${process.env.REACT_APP_DOMAIN}/AmgenVentures.png`}
              imageAlt="Amgen Ventures Image"
            />
          </div>
      </div>
    );
  };
