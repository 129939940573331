/* eslint-disable import/no-unresolved */
// import { useNavigate } from "react-router-dom";
// const navigate = useNavigate();

import axios from "axios";
import { getApiUrl } from "../api/api-config";
import { dowJones } from "../assets/constants/constants-text";
import { BASE_ROUTE } from './routing'
import dayjs from './dayjs'

export const formateToDecimal = (value: any, decimal: number) => {
  return parseFloat(value).toFixed(decimal);
};

export const getOktaDetails = () => {
  const oktaToken: any = localStorage.getItem("sensing-okta-token");
  const oktaTokenObj = JSON.parse(oktaToken);
  if (oktaTokenObj && oktaTokenObj.accessToken !== undefined) {
    return oktaTokenObj;
  }
  return null;
};

export const getOktaToken = () => {
  const oktaTokenDetails = getOktaDetails();
  if (oktaTokenDetails !== null) {
    return oktaTokenDetails.accessToken.accessToken;
  }
  return "";
};

export const filterCountFn = (facet: any) => {
  let filterCount = 0;
  let totalFilter: any = Object.values(facet);
  totalFilter.forEach((totalFilter: string | any[]) => {
    filterCount = filterCount + totalFilter.length;
  });
  return filterCount;
};

export const formatCount = (num: string) => {
  var nf = Intl.NumberFormat();
  var x = Number(num);
  const realDigits = nf.format(x);
  return realDigits;
};

export const formatKiqName = (text: string) => {
  const splitTry = text.split("_");

  const outPut = splitTry.map((val) => {
    return val.toUpperCase();
  });
  return outPut.join(" ");
};


export const formatAmgenVenture = (text: string) => {
  const splitTry = text.split("_");

  const outPut = splitTry.map((val) => {
    return val.charAt(0).toUpperCase() + val.slice(1);
  });
  return outPut.join(" ");
};

export const formatDate = (oldDate: any) => {
  const dateArr = oldDate && oldDate.split("-");
  type monthType = {
    [key: string]: string;
  };

  const months: monthType = {
    "01": "January",
    "02": "February",
    "03": "March",
    "04": "April",
    "05": "May",
    "06": "June",
    "07": "July",
    "08": "August",
    "09": "September",
    "10": "October",
    "11": "November",
    "12": "December",
  };
  if (dateArr && dateArr.length > 0) {
    const monthNumber: any = dateArr[1];
    // @ts-ignore
    const newDate =
      months[monthNumber] +
      " " +
      `${
        dateArr[2].includes("T")
          ? dateArr[2].substring(0, dateArr[2].indexOf("T"))
          : dateArr[2]
      }` +
      ", " +
      dateArr[0];
    return newDate;
  }
};

export const formatGraphDate = (oldDate: any, range: string) => {
  const date = dayjs(oldDate);
  if (
    (date.date() || date.year()) &&
    (range === "weekly" || range === "monthly")
  ) {
    // const monthName = new Intl.DateTimeFormat("en-US", { month: "short" })
    //   .format;
    const longName = date.format('MMM');
    const newDate = longName + " " + date.date() + "," + date.year();
    return newDate;
  } else if ((date.hour() || date.minute()) && range === "daily") {
    const timetype = date.hour() < 12 ? "AM" : "PM";
    const minutes = date.minute();
    const newDate =
      (date.hour() > 12 ? date.hour() - 12 : date.hour()) +
      ":" +
      (minutes <= 9 ? "0" + minutes : minutes) +
      ` ${timetype}`;
    return newDate;
  } else if ((date.date() || date.year()) && range === "yearly") {
    const longName = date.format('MMM');
    const newDate = longName + " " + date.year();
    return newDate;
  }
  return oldDate;
};

export const formatText = (text: string) => {
  const splitTry = text.split("_");
  const outPut = splitTry.map((val) => {
    return val.charAt(0).toUpperCase() + val.substring(1);
  });
  return outPut.join(" ");
};

export const getFormatedTags = (
  list: [string],
  type: string,
  canFilter: boolean,
  isFilteredOnTag: boolean,
  onFilter: (tag: any) => void,
  canClick: boolean,
  onClick: (tag: any) => void,
  filters: any = []
) => {
  const tagData: any = [];
  list?.forEach((item) => {
    const tagArr: any = type === "company" ? item && item.split("$$")  : item;
    isFilteredOnTag = false;
    if (canFilter && filters) {
      if (type === "company") {
        const typeFilter = filters.COMPANY;
        isFilteredOnTag = typeFilter.length
          ? typeFilter.includes(tagArr[2])
          : false;
      } else if (type === "product") {
        const typeFilter = filters.PRODUCT;
        isFilteredOnTag = typeFilter.length
          ? typeFilter.includes(tagArr)
          : false;
      } else if (type === "topic") {
        const typeFilter = filters.TOPIC;
        isFilteredOnTag = typeFilter.length
          ? typeFilter.includes(tagArr)
          : false;
      } else if (type === "corexTopic") {
        const typeFilter = filters.COREX_TOPIC;
        isFilteredOnTag = typeFilter.length
          ? typeFilter.includes(tagArr)
          : false;
      } else if (type === "kiq") {
        const typeFilter = filters.KIQs;
        isFilteredOnTag = typeFilter?.length
          ? typeFilter.includes(tagArr)
          : false;
      } else if (type === "ventureCompany") {
        const typeFilter = filters.VENTUREs;
        isFilteredOnTag = typeFilter.length
          ? typeFilter.includes(tagArr)
          : false;
      }
    }

    const tag = {
      id: type === "company" ? tagArr[0] : tagArr,
      name: formatText(type === "company" ? tagArr[1] : tagArr),
      ticker: tagArr[2],
      type: type,
      canClick: canClick,
      onClick: () => {
        onClick(tagArr);
      },
      canFilter: canFilter,
      isFilteredOnTag: isFilteredOnTag,
      onFilter: () => {
        onFilter(tagArr);
      },
    };
    tagData.push(tag);
  });
  return tagData;
};

export const getFormatedTagList = (
  navigate: any,
  location: any,
  companies: any,
  products: any,
  topics: any,
  kiqs: any,
  amngeVentures: any,
  corexTopics?: any
) => {
  const onCompanyClicked = (tag: any) => {
    navigate(`${BASE_ROUTE}/companies/${tag[2]}`, {
      state: { companyId: tag[2], callingPath: location },
    });
  };

  const onProductClicked = (tag: any) => {
    navigate(`${BASE_ROUTE}/products/${tag}`, {
      state: { productName: tag, callingPath: location },
    });
  };

  const onTopicClicked = (tag: any) => {
    navigate(`${BASE_ROUTE}/topics/${tag.split(" ").join("_")}`, {
      state: { topicName: tag, callingPath: location },
    });
  };

  const onKiqClicked = (tag: any) => {
    navigate(`${BASE_ROUTE}/corporate-strategy/${tag}`, {
      state: { productName: tag, callingPath: location },
    });
  };

  const onAmgenVentureClick = (tag: any) => {
    navigate(`${BASE_ROUTE}/amgen-ventures/${tag}`, {
      state: { ventureCompany: tag, callingPath: location },
    });
  };

  const companyTag = getFormatedTags(
    companies,
    "company",
    false,
    false,
    () => {},
    true,
    onCompanyClicked,
    []
  );

  const productTag = getFormatedTags(
    products,
    "product",
    false,
    false,
    () => {},
    true,
    onProductClicked,
    []
  );

  const topicTag = getFormatedTags(
    topics,
    "topic",
    false,
    false,
    () => {},
    true,
    onTopicClicked,
    []
  );

  const corexTopicTag = getFormatedTags(
    corexTopics,
    "corexTopic",
    false,
    false,
    () => {},
    true,
    onTopicClicked,
    []
  );

  const kiqTag = getFormatedTags(
    kiqs,
    "kiq",
    false,
    false,
    () => {},
    true,
    onKiqClicked
  );

  const ventureCompanyTag = getFormatedTags(
    amngeVentures,
    "ventureCompany",
    false,
    false,
    () => {},
    true,
    onAmgenVentureClick
  )

  const tags: any[] = [...companyTag, ...productTag, ...topicTag, ...corexTopicTag, ...kiqTag, ...ventureCompanyTag];

  return tags;
};

export const getFormatedExperimentTagList = (
  navigate: any,
  location: any,
  experiments:any,
  selectedTab: any
  
) => {
  
  const onExperimentClicked = (tag: any) => {
    navigate(`${BASE_ROUTE}/experimental/${selectedTab}/${ tag.split(" ").join("_")}`, {
      state: { experimentName: tag, callingPath: location },
    });
  };


  const experimentTag = getFormatedTags(
    experiments,
    "experiment",
    false,
    false,
    () => {},
    true,
    onExperimentClicked,
    []
  );

  
  const tags: any[] = [...experimentTag];

  return tags;
};

export const getFormatedTagListWithFilter = (
  navigate: any,
  location: any,
  filters: any,
  changeFilters: (value: any) => void,
  companies: [string],
  products: [string],
  topics: [string],
  kiqs: [string],
  amngeVentures: [string],
  corexTopics: [string]
) => {
  const onCompanyTagFiltered = (tag: any) => {
    let tagId: string = tag[2];
    let selectedList: any[] = filters.COMPANY;
    const value = {
      name: tagId,
      field: "COMPANY",
      isChecked: !selectedList.includes(tagId),
    };
    changeFilters(value);
  };

  const onCompanyClicked = (tag: any) => {
    navigate(`${BASE_ROUTE}/companies/${tag[2]}`, {
      state: { companyId: tag[2], callingPath: location },
    });
  };

  const onProductTagFitered = (tag: any) => {
    let tagId: string = tag;
    let selectedList: any[] = filters.PRODUCT;
    const value = {
      name: tagId,
      field: "PRODUCT",
      isChecked: !selectedList.includes(tagId),
    };
    changeFilters(value);
  };

  const onProductClicked = (tag: any) => {
    navigate(`${BASE_ROUTE}/products/${tag}`, {
      state: { productName: tag, callingPath: location },
    });
  };

  const onTopicTagFiltered = (tag: any) => {
    let tagId: string = tag;
    let selectedList: any[] = filters.TOPIC;
    const value = {
      name: tagId,
      field: "TOPIC",
      isChecked: !selectedList.includes(tagId),
    };
    changeFilters(value);
  };

  const onCorexTopicTagFiltered = (tag: any) => {
    let tagId: string = tag;
    let selectedList: any[] = filters.COREX_TOPIC;
    const value = {
      name: tagId,
      field: "COREX_TOPIC",
      isChecked: !selectedList.includes(tagId),
    };
    changeFilters(value);
  };

  const onTopicClicked = (tag: any) => {
    navigate(`${BASE_ROUTE}/topics/${ tag.split(" ").join("_")}`, {
      state: { productName: tag, callingPath: location },
    });
  };

  const onKiqTagFiltered = (tag: any) => {
    let tagId: string = tag;
    let selectedList: any[] = filters.KIQs;
    const value = {
      name: tagId,
      field: "KIQs",
      isChecked: !selectedList.includes(tagId),
    };
    changeFilters(value);
  };

  const onKiqClicked = (tag: any) => {
    navigate(`${BASE_ROUTE}/corporate-strategy/${tag}`, {
      state: { productName: tag, callingPath: location },
    });
  };

  const onAmgenVentureFiltered = (tag: any) => {
    let tagId: string = tag;
    let selectedList: any[] = filters.VENTUREs;
    const value = {
      name: tagId,
      field: "VENTUREs",
      isChecked: !selectedList.includes(tagId),
    };
    changeFilters(value);
  };

  const onAmgenVentureClicked = (tag: any) => {
    navigate(`${BASE_ROUTE}/amgen-ventures/${tag}`, {
      state: { productName: tag, callingPath: location },
    });
  };

  const companyTag = getFormatedTags(
    companies,
    "company",
    true,
    false,
    onCompanyTagFiltered,
    true,
    onCompanyClicked,
    filters
  );

  const productTag = getFormatedTags(
    products,
    "product",
    true,
    false,
    onProductTagFitered,
    true,
    onProductClicked,
    filters
  );

  const topicTag = getFormatedTags(
    topics,
    "topic",
    true,
    false,
    onTopicTagFiltered,
    true,
    onTopicClicked,
    filters
  );

  const corexTopicTag = getFormatedTags(
    corexTopics,
    "corexTopic",
    true,
    false,
    onCorexTopicTagFiltered,
    true,
    onTopicClicked,
    filters
  );

  const kiqTag = getFormatedTags(
    kiqs,
    "kiq",
    true,
    false,
    onKiqTagFiltered,
    true,
    onKiqClicked,
    filters
  );

  const ventureCompanyTag = getFormatedTags(
    amngeVentures,
    "ventureCompany",
    true,
    false,
    onAmgenVentureFiltered,
    true,
    onAmgenVentureClicked,
    filters
  );

  const tags: any[] = [...companyTag, ...productTag, ...topicTag, ...corexTopicTag, ...kiqTag, ...ventureCompanyTag];

  return tags;
};

export const apiResponse = async (
  method: string,
  url: string,
  urlIdentifier: any,
  payload: any
) => {
  const oktaToken = getOktaToken();
  let finalUrl = "";
  if (urlIdentifier.length > 0) {
    if (urlIdentifier.length > 1) {
      finalUrl = `${getApiUrl(url)}/${urlIdentifier[0]}/${urlIdentifier[1]}`;
    } else if (url.includes(dowJones)) {
      finalUrl = `${getApiUrl(url)}${urlIdentifier[0]}`;
    } else {
      finalUrl = `${getApiUrl(url)}/${urlIdentifier[0]}`;
    }
  
  } else {
    finalUrl = getApiUrl(url);
  }
  const res = await axios({
    method: method,
    url: finalUrl,
    data: payload,
    headers: {
      Authorization: `Bearer ${oktaToken}`,
    },
  });
  return res;
};



export const lastApiResponse = async (
  method: string,
  url: string,
  urlIdentifier: any,
  payload: any,
  cancelTokenSource: any
) => {
  // Cancel the previous request if it exists
  if (cancelTokenSource.current) {
    cancelTokenSource.current.cancel('Previous request canceled');
  }
  // Create a new CancelToken for the new request
  cancelTokenSource.current = axios.CancelToken.source();

  const oktaToken = getOktaToken();
  let finalUrl = "";
  if (urlIdentifier.length > 0) {
    if (urlIdentifier.length > 1) {
      finalUrl = `${getApiUrl(url)}/${urlIdentifier[0]}/${urlIdentifier[1]}`;
    } else if (url.includes(dowJones)) {
      finalUrl = `${getApiUrl(url)}${urlIdentifier[0]}`;
    } else {
      finalUrl = `${getApiUrl(url)}/${urlIdentifier[0]}`;
    }
  
  } else {
    finalUrl = getApiUrl(url);
  }
  const res = await axios({
    method: method,
    url: finalUrl,
    data: payload,
    cancelToken: cancelTokenSource.current.token,
    headers: {
      Authorization: `Bearer ${oktaToken}`,
    },
  });
  return res;
};