import React, { useEffect, useContext, useState } from "react";
import { useOktaAuth } from "@okta/okta-react";
import { toRelativeUrl } from "@okta/okta-auth-js";
import { Outlet } from "react-router-dom";
import { Loader } from "@gitlab-rtsensing/component-library";

import { authorizationApi } from "../../utils/auth-config";
import { AppContext } from "../../utils/app-context";

export const RequiredAuth: React.FC = () => {
  const { oktaAuth, authState } = useOktaAuth();
  const [availableState, setAvailableState] = useState(false);
  const { data, error, isLoading } = authorizationApi(availableState);
  const { setAuthData } = useContext(AppContext);
  useEffect(() => {
    if (data?.sensingAuthorization?.externalAuthorization) {
      setAuthData(data.sensingAuthorization);
    }
  }, [data]);

  useEffect(() => {
    if (authState?.isAuthenticated) {
      setAvailableState(true);
    }
    if (!authState) {
      return;
    }

    if (!authState?.isAuthenticated) {
      const originalUri = toRelativeUrl(
        window.location.href,
        window.location.origin
      );
      oktaAuth.setOriginalUri(originalUri);
      oktaAuth.signInWithRedirect();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [oktaAuth, !!authState, authState?.isAuthenticated]);

  if (!authState || !authState?.isAuthenticated) {
    return <></>;
  }

  return data?.sensingAuthorization?.externalAuthorization &&
    !isLoading &&
    !error ? (
    <Outlet />
  ) : (
    <div className="homepage-loader">
      <Loader />
    </div>
  );
};
