import React, { useState } from "react";
import { Text } from '@opsdti-global-component-library/amgen-design-system'

interface AccordionProps {
  header: string | JSX.Element;
  children?: JSX.Element;
}

const Accordion: React.FC<AccordionProps> = (props: AccordionProps) => {
  const [isOpen, setisOpen] = useState(false);
  const { header, children } = props;
  return (
    <div>
      <div
        onClick={() => setisOpen(!isOpen)}
      >
        <Text>{header}</Text>
      </div>
      {isOpen && <>{children}</>}
    </div>
  );
};

export default Accordion;
