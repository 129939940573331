import "./index.scss";
import { Dayjs } from "dayjs";
import { useState } from "react";
import { Input, Label, Modal, Button, Flex } from "@opsdti-global-component-library/amgen-design-system";
import React from "react";

type SaveFilterNameProps = {
    isOpen: boolean;
    setIsOpen?: any;
    saveFilters?: any;
    startDate: Dayjs;
    endDate: Dayjs;
  };

const SaveFilterNameModal = (props: SaveFilterNameProps) => {
  const {
    isOpen,
    setIsOpen,
    saveFilters,
    startDate,
    endDate
  } = props;

  const [filterName, setFilterName] = useState("");
  const [savedClicked, setSavedClicked] = useState(false);

  return (
    <div>
      <Modal open={isOpen} onCancel={() => setIsOpen(false)} style={{width: "736"}} styles={{header: {display:"none"}}} className="ext-save-filters">
            {(savedClicked )
            ? <Modal.Title title="Search Saved" />
            : <Modal.Title title="Save Search" /> }
            <hr className="ext-divider-bar"></hr>

            <div className="ext-search-name-div">
            <div className="ext-search-name-label">
                {!savedClicked && 
                 <Label> SEARCH NAME </Label>}
            </div>

            {!savedClicked &&
            <div>
                <Input
                    className="ext-input-box"
                    value={filterName}
                    onChange={e => setFilterName(e.target.value)}
                    placeholder="Enter a name for this saved search"
                />
            </div>}

            {savedClicked &&
            <div>
                <p className="ext-success-message">This search has been added to your <b>Saved Searches.</b></p>
            </div>}
        </div>

        <hr className="ext-divider-bar"></hr>
        <Flex gap={8} className="ext-buttons">
            {!savedClicked && <React.Fragment>
            <Button
                text="Cancel"
                type="secondary"
                block
                onClick={()=> setIsOpen(false)}
                />
            <Button
                text="Save"
                type="secondary"
                block
                onClick={()=> {saveFilters(startDate, endDate, filterName), setSavedClicked(true)}}
                /></React.Fragment>}           
            {savedClicked &&  
            <Button
                text="Continue"
                type="secondary"
                block
                onClick={()=> {setIsOpen(false), setSavedClicked(false), setFilterName("")}}
                />}
        </Flex>
        </Modal>
    </div>
  )
};

export default SaveFilterNameModal;