import React, {
  Children,
  cloneElement,
  useState,
  MouseEventHandler,
  useCallback,
  useEffect,
} from 'react';
import { createPortal } from 'react-dom';
import {classes}  from '../../utils/classes';
import ModalHeader from './modal-header';
import ModalTitle from './modal-title';
import './index.scss';

export type Props = {
  opened: boolean;
  onClose: (
    event:
      | React.MouseEvent<HTMLDivElement, MouseEvent>
      | React.MouseEvent<HTMLButtonElement, MouseEvent>
      | KeyboardEvent,
    reason?: 'BACKDROP' | 'CLOSE' | 'ESCAPE',
  ) => void;
  disableBackdrop?: boolean;
  disableBackdropDismiss?: boolean;
  children: JSX.Element | JSX.Element[];
  className?: string;
  unsetWidth?: boolean;
};

export type ModalContentProps = {
  isOpened: boolean;
  mountState: 'unmounted' | 'pre-mount' | 'pre-unmount' | 'mounted';
  mountHandler: (
    value: React.SetStateAction<
      'unmounted' | 'pre-mount' | 'pre-unmount' | 'mounted'
    >,
  ) => void;
} & Props;

export const ModalContent = (props: ModalContentProps) => {
  const {
    mountHandler,
    mountState,
    isOpened,
    onClose,
    children,
    className = '',
    disableBackdrop = false,
    disableBackdropDismiss = false,
    unsetWidth = false,
  } = props;
  const appBody = document && document?.getElementsByTagName('body')[0];
  const backdropClass = 'ops-modal-backdrop';
  const wrapperClass = 'ops-modal-wrapper';
  const cardClass = 'ops-modal-card';

  const classNames = classes([className]);
  const backdropClassNames = classes([
    backdropClass,
    `${backdropClass}--${isOpened ? 'opened' : 'closed'}`,
  ]);
  const wrapperClassNames = classes([
    'd-flex',
    'align-items-center',
    'justify-content-center',
    wrapperClass,
    `${wrapperClass}--${isOpened ? 'opened' : 'closed'}`,
  ]);

  const cardClassNames = classes([
    'ops-py-4',
    'ops-px-6',
    cardClass,
    `${cardClass}--${isOpened ? 'opened' : 'closed'}`,
    `${unsetWidth && 'width-unset'}`,
  ]);

  const invokeClose = useCallback(
    (
      e: React.MouseEvent<HTMLDivElement, MouseEvent> | KeyboardEvent,
      reason?: 'BACKDROP' | 'CLOSE' | 'ESCAPE',
    ) => {
      e.stopPropagation();
      if (e.type === 'keydown') {
        const keyEvent: KeyboardEvent = e as KeyboardEvent;
        if (keyEvent.key === 'Escape') {
          onClose(e, 'ESCAPE');
        }
      } else {
        onClose(e, reason);
      }
    },
    [onClose],
  );

  useEffect(() => {
    if (mountState === 'pre-mount') {
      setTimeout(() => mountHandler('mounted'), 100);
    }
    if (mountState === 'pre-unmount') {
      setTimeout(() => mountHandler('unmounted'), 300);
    }
  }, [mountState, mountHandler]);

  useEffect(() => {
    window.addEventListener('keydown', invokeClose);

    return () => window.removeEventListener('keydown', invokeClose);
  }, [invokeClose]);

  const handleCloseInvoked: MouseEventHandler<HTMLDivElement> = e => {
    invokeClose(e, 'BACKDROP');
  };

  return createPortal(
    <div className={`${classNames} ext-custom-modal`}>
      {!disableBackdrop && <div className={backdropClassNames} />}
      <div
        className={wrapperClassNames}
        onMouseDown={!disableBackdropDismiss ? handleCloseInvoked : undefined}
      >
        <div className={`${cardClassNames}`} onMouseDown={e => e.stopPropagation()}>
          {Children.map(children, (child: JSX.Element) => {
            return cloneElement(child, {
              onClose: invokeClose,
            });
          })}
        </div>
      </div>
    </div>,
    appBody,
  ) as JSX.Element;
};

const CustomModal = (props: Props) => {
  const [mountState, setMountState] = useState<
    'unmounted' | 'pre-mount' | 'pre-unmount' | 'mounted'
  >('unmounted');
  const { opened, unsetWidth = false } = props;

  useEffect(() => {
    if (opened) {
      setMountState('pre-mount');
    } else {
      setMountState('pre-unmount');
    }
  }, [opened]);

  const isVisible =
    mountState === 'pre-mount' ||
    mountState === 'mounted' ||
    mountState === 'pre-unmount';

  return (
    <>
      {isVisible && (
        <ModalContent
          {...props}
          isOpened={mountState === 'mounted'}
          mountHandler={(completedStep: any) => setMountState(completedStep)}
          mountState={mountState}
          unsetWidth={unsetWidth}
        />
      )}
    </>
  );
};

CustomModal.Header = ModalHeader;
CustomModal.Title = ModalTitle;

export { CustomModal };
