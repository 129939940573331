import React from "react";
import "./index.scss";

type Props = {
    selectedToggleValue: any;
    changesToggleState: any;
}

const CustomToggle: React.FC<Props> = (props: Props) => {
    const {selectedToggleValue, changesToggleState} = props;

    return ( 
    <div className="ext-customr-toggle-button-wrapper ext-d-flex ext-justify-content-between">
        <div className={`toggle-styling ${selectedToggleValue === "OR" ? "toggle-left-selected-section" : "toggle-reset-left"}`} onClick={() => {changesToggleState( "OR" )}}>ANY </div>
        <div className={`toggle-styling ${selectedToggleValue === "AND" ? "toggle-right-selected-section" : "toggle-reset-right"}`} onClick={() => {changesToggleState( "AND" )}}>ALL </div>
    </div>
    )
}

export default CustomToggle


