import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import "./index.scss";

// Assets
import ExpandDownIcon from "../../assets/icons/expand-down-icon";
import ExpandUpIcon from "../../assets/icons/expand-up-icon";
import ExternalLinkLogo from "../../assets/icons/external-link-logo";

// Components
import { Button } from "@gitlab-rtsensing/component-library";
import { BASE_ROUTE } from "../../utils/routing";

type EarningsPopupModalProps = {
  summaryData?: any;
  toggleValue?: string;
  companyPageUrl?: string;
  presignedUrl?: string;
};

const EarningsPopupModal: React.FC<EarningsPopupModalProps> = (
  props: EarningsPopupModalProps
) => {
  const { summaryData, companyPageUrl, presignedUrl } =
    props;
  const navigate = useNavigate();
  const currentRoute = useLocation();
  const [showGuidanceContent, setShowGuidanceContent] = useState(0);
  const arr = Object.keys(summaryData[0]);
  return (
    <div className="ext-earnings-notable-popup-container">
      <div className="ext-earnings-popup-scrollable-content">
        <div className="ext-earnings-insights-collapsible-container">
          {arr.map((item: any, index: number) => {
            let listData = summaryData?.[0]?.[item]?.slice(2)?.split("\n- ");
            const isExpanded = showGuidanceContent === index;
            return (
              <>
                <div
                  className="ext-earnings-collapsible-content-header ops-my-3"
                  key={index}
                  onClick={() => {
                    setShowGuidanceContent(isExpanded ? -1 : index);
                  }}
                  style={{ cursor: "pointer" }}
                >
                  <p style={{ fontSize: "14px", fontWeight: 500 }}>{item}</p>
                  <div>
                    {isExpanded ? (
                      <span className="ext-row">
                        <ExpandUpIcon fill={"#2F2F2F"} />
                      </span>
                    ) : (
                      <span className="ext-row">
                        <ExpandDownIcon fill={"#2F2F2F"} />
                      </span>
                    )}
                  </div>
                </div>
                {isExpanded && (
                  <>
                    <ol className="ext-earning-list-content">
                      {listData?.map((listItem: any, listIndex: number) => {
                        return (
                          <li
                            key={listIndex}
                            style={{ listStyleType: "disc" }}
                          >
                            {listItem?.includes("<End>")
                              ? listItem?.slice(0, listItem?.length - 5)
                              : listItem}
                          </li>
                        );
                      })}
                    </ol>
                  </>
                )}
              </>
            );
          })}
        </div>
      </div>
      <div className="ext-d-flex">
        <div className="ext-w-50 ops-my-1">
          <Button
            label="GO TO COMPANY PAGE"
            onClick={() => {
              navigate(`${BASE_ROUTE}/companies/${companyPageUrl}`, {
                state: {
                  companyId: companyPageUrl,
                  callingPath: currentRoute,
                },
              });
            }}
            type="secondary"
            className="ext-earning-view-button"
          />
        </div>
        <div className="ext-w-50 ops-m-1">
          <Button
            className="ext-earning-view-button"
            label="GO TO EARNINGS TRANSCRIPT"
            onClick={() => window.open(presignedUrl, "_blank")}
            type="secondary"
            icon={
              <ExternalLinkLogo classname="ext-vertical-align" fill="#0063C3" />
            }
            iconPosition="right"
          />
        </div>
      </div>
    </div>
  );
};

export default EarningsPopupModal;
