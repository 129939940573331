import React from "react";
import ReactDOM from "react-dom";
import singleSpaReact from "single-spa-react";
import ExternalSensing from "./ExternalSensing";
import "./index.scss";

const reactLifecycles = singleSpaReact({
  React,
  ReactDOM,
  rootComponent: ExternalSensing,
  errorBoundary(err: any, info: any, props: any) {
    return <div>This renders when a catastrophic error occurs</div>;
  },
});

let styleElements: Array<Element>;

export const bootstrap = reactLifecycles.bootstrap;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const mount = [
  // Add CSS styles to the page before React app is loaded
  async () => {
    if (styleElements) {
      document.head.append(...styleElements);
    }
  },
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (props: any) => {
    if (!props?.permissions?.external) {
      props.singleSpa.navigateToUrl("/unauthorized");
    }
    return reactLifecycles.mount(props);
  },
  // Keep references to added style tags for the app so they can be remounted again
  async () => {
    if (!styleElements) {
      styleElements = Array.from(
        document.getElementsByClassName("amgen-rtsensing-external")
      );
    }
  },
];
export const unmount = [
  reactLifecycles.unmount,
  // Remove styles once the app has unmounted
  async () => {
    styleElements.forEach((element) => element.remove());
  },
];
