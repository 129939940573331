import React from 'react';
import {classes}  from '../../../utils/classes';
import cls from './modal-header.module.scss';
import { CloseIcon } from '../../../assets/icons/close-icon';
// import { CloseIcon } from 'components/elements/icons/svgs/close-icon';

export type Props = {
  className?: string;
  contentClassName?: string;
  children?: JSX.Element | JSX.Element[];
  onClose?: (
    event:
      | React.MouseEvent<HTMLDivElement, MouseEvent>
      | React.MouseEvent<HTMLButtonElement, MouseEvent>,
    reason?: 'BACKDROP' | 'CLOSE',
  ) => void;
};

const ModalHeader = (props: Props): JSX.Element => {
  const { className, onClose, contentClassName, children } = props;

  const classNames = classes([
    cls['ops-modal-header'],
    'ops-pb-2',
    'd-flex',
    'align-items-center',
    'justify-content-between',
    className,
  ]);
  const contentClassNames = classes(['flex-fill', contentClassName]);

  const handleCloseClicked: React.MouseEventHandler<HTMLButtonElement> = e => {
    if (onClose) onClose(e, 'CLOSE');
  };

  return (
    <div className={classNames}>
      <div className={contentClassNames ?? ''}>{children}</div>
      <button
        onClick={handleCloseClicked}
        className={classes([
          cls['ops-modal-header-close'],
          'ops-pb-2',
          'd-flex',
          'align-items-center',
          'justify-content-center',
        ])}
      >
        {/* <CloseIcon height={28} width={28} /> */}
        <CloseIcon height={20} width={20} className="close-icon" />
      </button>
    </div>
  );
};

export default ModalHeader;
