interface Props {
  fill: string;
  classname?: string;
}

const Topics = ({ fill, classname }: Props) => {
  return (
    <svg
      className={classname}
      width="10"
      height="10"
      viewBox="0 0 10 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.50001 2.00001C2.40112 2.00001 2.30445 2.02933 2.22222 2.08427C2.14 2.13921 2.07591 2.2173 2.03807 2.30867C2.00022 2.40003 1.99032 2.50056 2.00962 2.59755C2.02891 2.69454 2.07653 2.78364 2.14646 2.85356C2.21638 2.92349 2.30547 2.97111 2.40246 2.9904C2.49945 3.00969 2.59999 2.99979 2.69135 2.96195C2.78271 2.9241 2.8608 2.86002 2.91574 2.77779C2.97068 2.69557 3.00001 2.5989 3.00001 2.50001C3.00001 2.3674 2.94733 2.24022 2.85356 2.14646C2.75979 2.05269 2.63262 2.00001 2.50001 2.00001ZM9.85501 4.89001L5.11501 0.160008C5.06839 0.10974 5.01193 0.069596 4.94914 0.0420712C4.88635 0.0145465 4.81857 0.000228905 4.75001 8.35715e-06H1.75001C1.68421 -0.000372089 1.61897 0.0122404 1.55806 0.0371227C1.49714 0.062005 1.44173 0.0986677 1.39501 0.145008L0.145008 1.39001C0.0986677 1.43673 0.062005 1.49214 0.0371227 1.55306C0.0122404 1.61397 -0.000372089 1.6792 8.35715e-06 1.74501V4.74501C0.0018501 4.87739 0.0536415 5.00419 0.145008 5.10001L4.89001 9.85001C4.98583 9.94138 5.11263 9.99317 5.24501 9.99501C5.31081 9.99539 5.37604 9.98278 5.43696 9.95789C5.49788 9.93301 5.55329 9.89635 5.60001 9.85001L9.85501 5.59501C9.90135 5.54829 9.93801 5.49288 9.96289 5.43196C9.98778 5.37104 10.0004 5.30581 10 5.24001C9.99692 5.10936 9.94522 4.98456 9.85501 4.89001ZM5.24501 8.79501L1.00001 4.54501V1.95001L1.95001 1.00001H4.54001L8.79001 5.24501L5.24501 8.79501Z"
        fill="#585858"
      />
    </svg>
  );
};

export default Topics;
