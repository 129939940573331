import "./index.scss";
import Card from "../card";
import axios from "axios";
import { useState, useEffect } from "react";
import { getApiUrl } from "../../api/api-config";
import { getOktaToken } from "../../utils/commonMethods";
import { Button, Text } from "@opsdti-global-component-library/amgen-design-system";

interface KnowledgeGraphProps {
}

const KnowledgeGraph = (props: KnowledgeGraphProps) => {
    const [knowledgeGraphLink, setKnowledgeGraphLink] = useState<string>('');
    const [knowledgeGraphErrorMessage, setKnowledgeGraphErrorMessage] = useState<string>('');

    const oktaToken = getOktaToken();

    useEffect(() => {
        const getKnowledgeGraphLink = async () => {
            setKnowledgeGraphLink('');
            try {
                const res = await axios({
                    method: "get",
                    url: `${getApiUrl("knowledge-graph")}`,
                    headers: {
                        Authorization: `Bearer ${oktaToken}`,
                    },
                });
                if (res.data.status === "SUCCESS") {
                    setKnowledgeGraphLink(res.data.data);
                } else {
                    setKnowledgeGraphErrorMessage("Failed to fetch knowledge graph url")
                }
            } catch (error) {
                setKnowledgeGraphErrorMessage("Failed to fetch knowledge graph url")
            }
        };

        getKnowledgeGraphLink();
    }, [oktaToken]);
    
    return (
        <Card
            cardHeader="Knowledge Graph"
            cardHeaderTag="Experimental"
        >
            <div className="content-container">
                {knowledgeGraphLink ? (
                    <>
                        <Text>
                            Explore our Knowledge graph, a visual data model ilustrating the connections
                            between pharmaceutical companies, products, and therapeutic areas.
                        </Text>
                        <img
                            src={`${process.env.REACT_APP_DOMAIN}/knowledge-graph.png`}
                            alt="Knowledge Graph"
                            className="knowledge-graph-image ops-mt-4"
                        />
                        <div className="ops-mt-4">
                            <Button
                                text="VIEW KNOWLEDGE GRAPH"
                                type="secondary"
                                block
                                href={knowledgeGraphLink}
                                target="_blank"
                            />
                        </div>
                    </>
                    ) : (
                        <Text>{knowledgeGraphErrorMessage}</Text>
                    )
                }
            </div>
        </Card>
    );
}

export default KnowledgeGraph;
