import React, { useEffect, useState, useContext } from "react";
import Card from "../../components/card";
import ArticleCard from "../../components/article-card";

import {
  apiResponse,
  formatText,
  getFormatedTagList,
} from "../../utils/commonMethods";
import { TopicListType } from "../../api/get-topic-data-dt";
import { Loader } from "@gitlab-rtsensing/component-library";
import { AppContext } from "../../utils/app-context";
import RequestPage from "../../components/request-access-page";
import { Pagination } from "../../components/pagination";
import {
  apiErrorMessage,
  articlesMsg,
  noDataMessage,
} from "../../assets/errorMessageConstants";
import ErrorMessage from "../../components/error-message";
import { useLocation, useNavigate } from "react-router-dom";
import "./index.scss";
import BackBtn from "../../components/back-btn";
import FilterIcon from "../../assets/icons/filter-icon";
import { BASE_ROUTE, getBackButtonText } from "../../utils/routing";
import dayjs from '../../utils/dayjs';

type Props = {
  topicsList: any;
  isLoading: boolean;
  errorMessage: string;
};

const Topics: React.FC<Props> = (props: Props) => {
  const [topicList, setTopicList] = useState<TopicListType[]>([]);

  const [selectedTopicId, setSelectedTopicId] = useState("");
  const [selectedTopicName, setSelectedTopicName] = useState("");
  const [selectedNewName, setSelectedNewName] = useState("");

  const [isNewsListLoading, setIsNewsListLoading] = useState<boolean>(false);
  const [errorMessageNewsList, setErrorMessageNewsList] = useState<string>("");
  const [newsList, setNewsList] = useState([]);
  const [showBackBtn, setShowBackBtn] = useState(false);

  const [page, setPage] = useState(1);
  const [totalArticles, setTotalArticles] = useState(0);
  const [backButtonRoute, setBackButtonRoute] = useState<any>(-1);
  const pageLimit = 50;

  const { authData } = useContext(AppContext);

  const navigate = useNavigate();
  const location: any = useLocation();

  const loadTopicDetails = async (topicId: string, topicName: string, newName: string) => {
    setSelectedTopicId(topicId);
    setSelectedTopicName(`${topicName}`);
    setSelectedNewName(newName);
    setNewsList([]);
    setIsNewsListLoading(true);
    setErrorMessageNewsList("");
    setTotalArticles(0);

    const params = window.location.pathname.split("topics/");
    const topicCode = params[1];
    let topicCategoryName: string = '';
    for (const item of props.topicsList) {
      if(item.name === topicCode){
        topicCategoryName = item.category_name;
        break;
      }
    }

    const payload = {
      sort_by_value: "desc",
      page_offset: page - 1,
      end_date: dayjs(),
      start_date: dayjs().subtract(89, 'days'),
      limit: 50,
      filters: { [topicCategoryName]: [`${newName}`] },
    };

    try {
      const res = await apiResponse("post", "get-news-with-tags", [], payload);
      if (res.data.status === "SUCCESS" && res.data.data.response && res.data.data.count !== 0) {
        setNewsList(res.data.data.response);
        setTotalArticles(res.data.data.count);
        setErrorMessageNewsList("");
      } else {
        setNewsList([]);
        setTotalArticles(0);
        setErrorMessageNewsList(noDataMessage);
      }
      setIsNewsListLoading(false);
    } catch (error) {
      setNewsList([]);
      setTotalArticles(0);
      setErrorMessageNewsList(apiErrorMessage);
      setIsNewsListLoading(false);
    }
  };

  const handlePages = async (updatePage: number) => {
    setPage(updatePage);
  };

  const handleTopicClick = (topicName: string) => {
    setShowBackBtn(false);
    navigate(`${BASE_ROUTE}/topics/${topicName}`, {
      state: { callingPath: location },
    });
  };

  const getUpperLimit = () => {
    const upperLimit = page * pageLimit;
    if (upperLimit > totalArticles) {
      return totalArticles;
    }
    return upperLimit;
  };

  const showBtn = () => {
    const pathName = location.state.callingPath
      ? location.state.callingPath.pathname
      : "";

    setShowBackBtn(!pathName.includes(`${BASE_ROUTE}/topics`));
  };

  useEffect(() => {
    if (selectedTopicId && selectedTopicName && selectedNewName) {
      setIsNewsListLoading(true);
      setErrorMessageNewsList("");
      loadTopicDetails(selectedTopicId, selectedTopicName, selectedNewName);
    }
  }, [page]);

  useEffect(() => {
    setShowBackBtn(false);
    if (
      authData &&
      Object.keys(authData).length > 0 &&
      authData?.externalAuthorization?.topics &&
      props.topicsList.length > 0
    ) {
      setPage(1);

      if (location.state) {
        showBtn();
      } else {
        setBackButtonRoute(`${BASE_ROUTE}/topics`);
      }
      const topics = props.topicsList;
      setTopicList(topics);
      const params = window.location.pathname.split("topics/");
      const topicCode = params[1];
      const firstTopic = topics[0];
      if (!topicCode) {
        navigate(`${BASE_ROUTE}/topics/${firstTopic.name}`, {
          state: { callingPath: location },
        });
      } else {
        const selectedTopics = topics.filter((topic: any) => {
          if (topic.name === topicCode) {
            return true;
          }
          return false;
        });

        if (selectedTopics.length === 0) {
          navigate(`${BASE_ROUTE}/topics/${firstTopic.name}`, {
            state: { callingPath: location },
          });
        }
        const selectedTopic = selectedTopics[0];
        setIsNewsListLoading(true);
        loadTopicDetails(selectedTopic.id, selectedTopic.name, selectedTopic.newName);
      }
    }
  }, [location.state && location.state]);

  const onTopicsSearch = (e:any) => {
    e.preventDefault();
    // When search field is cleared
    if(!e.target.value) {
      setTopicList(props.topicsList)
    }
    if (e.target.value.length > 0) {
      const resultTopicList = props.topicsList.filter((topic:any) => {
      // toLowerCase is used as match is case-sensitive
      return topic.name.toLowerCase().match(e.target.value.toLowerCase());
      });
      setTopicList(resultTopicList)
    };
  };

  return authData && authData?.externalAuthorization?.topics ? (
    <div className="ext-news-container ext-topic-container ext-row ops-px-4 ops-mb-2">
        {showBackBtn && (
          <div className="ext-topic-page-navigation ops-mb-2">
            <BackBtn
              navigateFn={() => navigate(backButtonRoute)}
              backBtnText={getBackButtonText(location)}
            ></BackBtn>
          </div>
        )}
        <div className="ext-left-panel ext-col-lg-3 ext-col-md-12 ops-mb-4 ops-mb-sm-0 ops-mb-md-3 ops-mb-lg-0">
          <Card cardHeader="Topic List">
            <div className="ops-my-2 ops-ms-2 fs-12 ext-fw-bold ext-amgen-color ext-topics-list">
              <div className="ext-search-bar ops-px-1">
                <input
                  className="ext-topics-search-input"
                  type="text"
                  placeholder="Filter Topics"
                  onChange={(e) => onTopicsSearch(e)}
                  />
                <FilterIcon fill="#9C9C9C" classname="ext-filter-icon"/>
              </div>
              {!props.isLoading &&
                !props.errorMessage &&
                <div className="ext-topics-list-container">{
                topicList.length ?
                  topicList.map((item: any) => {
                    return (
                      <div
                        onClick={() => {
                          setIsNewsListLoading(true);
                          handleTopicClick(item.name);
                        }}
                        className={` ${
                          selectedTopicId === item.id
                            ? "ext-topics-name-selected "
                            : "ext-topics-name"
                        } `}
                        key={item.name}
                      >
                        {formatText(item.name)}
                      </div>
                    );
                  })
                  :
                  <div className="ext-no-results-text">No results found!</div>
                }</div>
              }
              {!props.isLoading && props.errorMessage && (
                <ErrorMessage isIcon={false} message={props.errorMessage} />
              )}
              {props.isLoading && (
                <div
                  className="ext-spinner-topics-style ext-d-flex ext-justify-content-center ext-topic-loader"
                  role="status"
                >
                  <Loader />
                </div>
              )}
            </div>
          </Card>
        </div>
        {
          <div className="ext-right-panel ext-col-lg-9 ext-col-md-12 ops-mb-4 ops-mb-sm-0 ">
            <Card
              cardHeader={
                selectedTopicName === "loading..."
                  ? formatText(selectedTopicName)
                  : `${formatText(selectedTopicName)} in the news`
              }
            >
              <div className="ext-article-list-wrapper ext-border-top-0">
                <div className="ext-article-message ext-fst-italic ext-col-md-9 ops-ps-4 ops-py-2">
                  {articlesMsg}
                </div>
                {isNewsListLoading && (
                  <div
                    className="ext-spinner-style ext-d-flex ext-justify-content-center ext-topic-loader"
                    role="status"
                  >
                    <Loader />
                  </div>
                )}

                {!isNewsListLoading && errorMessageNewsList && (
                  <ErrorMessage isIcon={false} message={errorMessageNewsList} />
                )}

                {!isNewsListLoading &&
                  !errorMessageNewsList &&
                  newsList?.map((article: any, index: any) => {
                    let specialClassName =
                      index % 2 === 0
                        ? "ext-article-background-white"
                        : "ext-article-background-gray";

                    const tags = getFormatedTagList(
                      navigate,
                      location,
                      article.company_names,
                      article.product_names,
                      article.topic_names,
                      article.kiq_names,
                      article.av_company_names,
                      article.corex_topic_names
                    );

                    return (
                      <ArticleCard
                        specialClassName={specialClassName}
                        key={index}
                        redirectUrl={article.url}
                        title={article.title}
                        articleId={article.article_id}
                        publishDate={article.published}
                        publishSource={article.source_name}
                        tagList={tags}
                        checkboxFlag={false}
                        trashFlag={false}
                      />
                    );
                  })}
              </div>
              <div className="ext-article-pagination ops-mt-3">
                <div className="container">
                  <div className="ext-article-pagination-wrapper">
                    {!isNewsListLoading &&
                      newsList.length !== 0 &&
                      totalArticles !== 0 && (
                        <>
                          <div className="ext-text-pagination ext-col">
                            Showing {(page - 1) * pageLimit + 1} -{" "}
                            {getUpperLimit()} of {totalArticles} results
                          </div>
                          <div className="pagination-wrapper ext-col">
                            <Pagination
                              page={page}
                              totalPages={Math.ceil(totalArticles / pageLimit)}
                              handlePagination={handlePages}
                            />
                          </div>
                        </>
                      )}
                  </div>
                </div>
              </div>
            </Card>
          </div>
        }
    </div>
  ) : (
    <>{authData && <RequestPage />}</>
  );
};

export default Topics;
