import React from "react";
import "./index.scss";
export interface Props {
  page: number;
  totalPages: number;
  handlePagination: (pagess: number) => void;
}
const PaginationComponent: React.FC<Props> = ({
  page,
  totalPages,
  handlePagination,
}) => {
  return (
    <div className="external-pagination">
      {page !== 0 && (
        <div className="external-pagination-wrapper">
          {page !== 1 && (
            <button
              onClick={() => handlePagination(page - 1)}
              type="button"
              className="pageItem sides"
            >
              &lt;
            </button>
          )}
          <button
            onClick={() => handlePagination(1)}
            type="button"
            className={`pageItem ${page === 1 ? "page-active" : null}`}
          >
            {1}
          </button>
          {page > 3 && <div className="separator">...</div>}
          {page === totalPages && totalPages > 3 && (
            <button
              onClick={() => handlePagination(page - 2)}
              type="button"
              className="pageItem"
            >
              {page - 2}
            </button>
          )}
          {page > 2 && (
            <button
              onClick={() => handlePagination(page - 1)}
              type="button"
              className="pageItem"
            >
              {page - 1}
            </button>
          )}
          {page !== 1 && page !== totalPages && (
            <button
              onClick={() => handlePagination(page)}
              type="button"
              className="pageItem page-active"
            >
              {page}
            </button>
          )}
          {page < totalPages - 1 && (
            <button
              onClick={() => handlePagination(page + 1)}
              type="button"
              className="pageItem"
            >
              {page + 1}
            </button>
          )}
          {page === 1 && totalPages > 3 && (
            <button
              onClick={() => handlePagination(page + 2)}
              type="button"
              className="pageItem"
            >
              {page + 2}
            </button>
          )}
          {page < totalPages - 2 && <div className="separator">...</div>}
          {totalPages !== 1 && (
            <button
              onClick={() => handlePagination(totalPages)}
              type="button"
              className={`pageItem ${
                page === totalPages ? "page-active" : null
              }`}
            >
              {totalPages}
            </button>
          )}
          {page !== totalPages && (
            <button
              onClick={() => handlePagination(page + 1)}
              type="button"
              className="pageItem sides"
            >
              &gt;
            </button>
          )}
        </div>
      )}
    </div>
  );
};
export const Pagination = PaginationComponent;
