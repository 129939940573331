import React from 'react';
import './index.scss';
import { Dropdown, DropdownProps, Label, Text} from '@opsdti-global-component-library/amgen-design-system';


interface EarningsDropdownProps {
    label: string;
    options: string[];
    className?: string;
    style?: React.CSSProperties;
    dropdownStyle?: React.CSSProperties;
    selectedValue: string;
    onMenuItemClick?: DropdownProps['onMenuItemClick'];}
const EarningsDropdown: React.FC<EarningsDropdownProps> = ({ label, options, selectedValue, className = "", style, dropdownStyle, onMenuItemClick }: EarningsDropdownProps) => {
    return (
        <Text className={`ext-earnings-dropdown ext-d-flex ${className}`} style={style}>
            <Label>
                 {label}
            </Label>
            <div className="ext-earnings-dropdown-options">
                <Dropdown
                    items={options.map((item) => ({ key: item, label: item }))}
                    className="ops-me-4"
                    onMenuItemClick={onMenuItemClick}
                    type="button"
                    style={dropdownStyle}
                >
                    {selectedValue}
                </Dropdown>
            </div>
        </Text>
    );
};

export default EarningsDropdown;