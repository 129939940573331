import React, { useEffect, useState, useRef, useContext } from "react";
import axios from "axios";

import "./index.scss";
import "../../assets/css/variables.scss";

import { getApiUrl } from "../../api/api-config";

import { useLocation, useNavigate } from "react-router-dom";
import { renderToString } from "react-dom/server";

import { getFormatedTagList, getOktaToken } from "../../utils/commonMethods";
import { formatDate } from "../../utils/commonMethods";
import { AppContext } from "../../utils/app-context";

import { emailErrorMessage } from "../../assets/errorMessageConstants";
import TickCircle from "../../assets/icons/tick-circle";
import { CloseIcon } from "../../assets/icons/close-icon";
// import EmailHeader from "../../assets/images/AmgenEmailHeader.png";

import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

import ExternalModal from "../external-custom-modal";

import { Loader } from "@gitlab-rtsensing/component-library";
import { Button } from "@gitlab-rtsensing/component-library";

type collectionData = {
  collection_id: string;
  collection_name: string;
};

type Props = {
  show: boolean;
  onHideModal: () => void;
  backToNews: () => void;
  collectionData: collectionData;
};

const ModalGenerateEmail: React.FC<Props> = ({
  show,
  onHideModal,
  backToNews,
  collectionData,
}) => {
  const [articlesList, setArticlesList] = useState([]);
  const [isArticlesLoading, setIsArticlesLoading] = useState(true);
  const [isEmailSending, setIsEmailSending] = useState(false);

  const [toEmail, setToEmail] = useState("");
  const [ccEmail, setCCEmail] = useState("");
  const [bccEmail, setBCCEmail] = useState("");
  const [toEmailError, setToEmailError] = useState(false);
  const [ccEmailError, setCCEmailError] = useState(false);
  const [bccEmailError, setBCCEmailError] = useState(false);
  const [subjectEmail, setSubjectEmail] = useState("");
  const [subjectEmailError, setSubjectEmailError] = useState(false);
  const [insightsAndComments, setInsightsAndComments] = useState(`<p>
  Article collection <b>${collectionData.collection_name}</b>
</p>`);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [isEmailSent, setIsEmailSent] = useState(false);

  const defaultContentRef = useRef<HTMLHeadingElement>(null);

  ClassicEditor.defaultConfig = {
    toolbar: {
      items: [
        "bold",
        "italic",
        "underline",
        "bulletedList",
        "numberedList",
        "redo",
      ],
    },
    list: {
      properties: {
          styles: true,
          startIndex: true,
          reversed: true
      }
    },
    placeholder: "Enter your insights and/or comments about this collection here"
  };

  const postCollectionArticles = async (formData: FormData) => {
    setErrorMessage("");
    setIsEmailSending(true);
    setIsEmailSent(false);

    const oktaToken = getOktaToken();
    try {
      const res = await axios({
        method: "post",
        url: getApiUrl("create_insights_log"),
        data: formData,
        headers: {
          Authorization: `Bearer ${oktaToken}`,
        },
      });
      if (res.data.status === "SUCCESS") {
        setToEmail("");
        setCCEmail("");
        setBCCEmail("");
        setSubjectEmail("");
        setInsightsAndComments(`<p>
        Article collection <b>${collectionData.collection_name}</b>
      </p>`);

        setToEmailError(false);
        setCCEmailError(false);
        setBCCEmailError(false);
        setSubjectEmailError(false);
        
        setIsEmailSent(true);
        setIsEmailSending(false);
        setErrorMessage("");
      } else {
        setErrorMessage(res.data.message);
        setIsEmailSending(false);
        setIsEmailSent(false);
      }
    } catch (error) {
      setErrorMessage("Something went wrong, please try again");
      setIsEmailSending(false);
      setIsEmailSent(false);
    }
  };

  const getCollectionArticles = async () => {
    setIsEmailSent(false);
    setIsArticlesLoading(true);
    const payload = {
      collection_id: collectionData.collection_id,
      page_offset: 0,
      limit: 50,
    };

    const oktaToken = getOktaToken();
    try {
      const res = await axios({
        method: "post",
        url: getApiUrl("get-collections-article"),
        data: payload,
        headers: {
          Authorization: `Bearer ${oktaToken}`,
        },
      });
      if (res.data.status === "SUCCESS" && res.data.data.response) {
        setArticlesList(res.data.data.response);
      } else {
        setErrorMessage("");
      }
      setIsArticlesLoading(false);
    } catch (error) {
      setErrorMessage("Something went wrong, please try again");
      setIsArticlesLoading(false);
    }
  };

  const navigate = useNavigate();
  const location: any = useLocation();

  const getCapitalizeData = (str: any) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };
  
  const getHeaderHtml = () => {
    return (
      <div>
        <table style={{
            width: "100%",
            height: "50px",
            background: "#0063c3",
            color: "#fff",
          }}>
          <tr>
          <td>
              <div
                style={{
                  fontWeight: 700,
                  fontFamily: "Arial",
                  fontSize: "20px",
                  color: "#FFFFFF",
                  textAlign: "center",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <img src={`${process.env.REACT_APP_DOMAIN}/sensing-logo.png`} alt="amgen logo" style={{width: '99.36px !important', height:'24.26px !important', marginTop: '5px !important',
                                                  left:'505px !important'}}></img>
                <span style={{marginLeft:"2px", fontSize: '24px', fontFamily: 'Roboto', lineHeight:'29px'}} >Sensing</span> 
              </div>
            </td>
          </tr>
         </table>
      </div>
           
    );
  };
  const getArticleListHtml = () => {
    let titleStyle: any = {
      color: "#0063c3",
      fontSize: "16px",
      fontWeight: "bold",
      paddingLeft: "9px",
    };

    let infoStyle = {
      color: "#848484",
      fontSize: "14px",
      fontWeight: "400",
      height: "20px",
    };

    let infoDateStyle = {
      height: "20px",
      marginRight: "5px",
    };

    let tagStyle = {
      color: "#757575",
      cursor: "pointer",
      backgroundColor: "#eeeeee",
      overflow: "hidden",
      borderRadius: "4px",
      fontSize: "12px",
      border: "#e0e0e0 1px solid",
    };

    return (
      <div
        style={{
          paddingLeft: "5px",
          paddingRight: "5px",
          paddingTop: "5px",
          paddingBottom: "5px",
          width: '1023',
        }}
      >
        {articlesList.length > 0 &&
          articlesList.map((article: any, index: number) => {
            let cardStyle = {
              borderBottom: "#EEE thin solid",
              backgroundColor: "#FFF",
              padding: "9px 17px 9px 17px"
            };

            if (index % 2 === 0) {
              cardStyle.backgroundColor = "#f9f9f9";
            } else {
              cardStyle.backgroundColor = "#FFFFFF";
            }

            const tags = getFormatedTagList(
              navigate,
              location,
              article.company_names,
              article.product_names,
              article.topic_names,
              article.kiq_names,
              article.av_company_names
            );

            return (
              <div style={cardStyle}>
                <div style={{ height: "5px" }}>&nbsp;</div>
                <div style={{ paddingLeft: "10px", paddingRight: "10px" }}>
                  <div style={titleStyle}>
                    <a
                      href={article.url}
                      target="_blank"
                      rel="noreferrer"
                      style={{
                        color: "#0063c3",
                        textDecoration: "underline",
                        textUnderlinePosition: "under",
                      }}
                    >
                      {article.title}
                    </a>
                  </div>
                  <div style={{ height: "5px" }}>&nbsp;</div>
                  <div style={infoStyle}>
                    <div style={infoDateStyle}>
                      {formatDate(article.published)} |{" "}
                      {getCapitalizeData(article.source_name)}
                    </div>
                  </div>
                  <div style={{ height: "5px" }}>&nbsp;</div>
                  <div>
                    {tags.map((tag: any, index: number) => {
                      return (
                        <>
                          <span style={tagStyle}>
                            <span>&nbsp;{tag.name}&nbsp;</span>
                          </span>
                          <span>&nbsp;</span>
                        </>
                      );
                    })}
                  </div>
                </div>
                <div style={{ height: "5px" }}>&nbsp;</div>
              </div>
            );
          })}
        {articlesList.length === 0 && <div>No articles in the collection</div>}
      </div>
    );
  };

  const getFooterHtml = () => {
    let linkStyle = {
        fontSize: "14px",
        color: "#0063c3",
        textDecoration: "none",
        fontWeight: "bold", 
        paddingLeft: "2px",     
    }

    return (
      <div >
      <table style={{ width: "684px", alignContent: "left"}}>
        <tr >
          <td style={{ width: "684px", padding: "0px 5px" }}>
            <div
              style={{
                padding: "5px, 12px, 5px, 12px",
                background: "#fff",
                textAlign: "center",
              }}
            >
              <p style={{
                  textAlign: "start",
                  fontSize: "14px",
                  textDecoration: "none",
                }}>
              Additional Resources: <a
                rel="noreferrer"
                target="_blank"
                href={`${process.env.REACT_APP_TRENDING_URL}`}
                style={linkStyle}
                > More Amgen News </a>
        
              • <a
                rel="noreferrer"
                target="_blank"
                href={`${process.env.REACT_APP_EMAIL_URL}`}
                style={linkStyle}
                > Sensing External News
              </a>
                </p>
            </div>
          </td>
        </tr>
        <tr>
          <td style={{ width: "684px", padding: "0px 5px" }}>
            <div
              style={{
                padding: "5px, 12px, 5px, 12px",
                background: "#fff",
                textAlign: "center",
              }}
            >
              <p style={{
                  textAlign: "start",
                  fontSize: "14px",
                  textDecoration: "none",
                }}>
              Powered by: <a
                rel="noreferrer"
                target="_blank"
                href={`${process.env.REACT_APP_SENSING_URL}`}
                style={linkStyle}
                > Amgen Sensing </a>
               </p>
            </div>
          </td>
        </tr>
      </table>
      </div>
    );
  };

  const sendEmail = async () => {
    let isErrorPresent = false;
    const emailRegExp = new RegExp(
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );

    const toEmailArray = toEmail.split(",");
    const validToEmails = toEmailArray.filter((email) => {
      if (emailRegExp.test(email) && email.endsWith("@amgen.com")) {
        return true;
      }
      return false;
    });
    if (toEmailArray.length > validToEmails.length) {
      setToEmailError(true);
      isErrorPresent = true;
    }

    const ccEmailArray = ccEmail.split(",");
    const validCcEmails = ccEmailArray.filter((email) => {
      if (emailRegExp.test(email) || email === "") {
        return true;
      }
      return false;
    });
    if (ccEmailArray.length > validCcEmails.length) {
      setCCEmailError(true);
      isErrorPresent = true;
    }

    const bccEmailArray = bccEmail.split(",");
    const validBccEmails = bccEmailArray.filter((email) => {
      if (emailRegExp.test(email) || email === "") {
        return true;
      }
      return false;
    });
    if (bccEmailArray.length > validBccEmails.length) {
      setBCCEmailError(true);
      isErrorPresent = true;
    }

    if (subjectEmail.trim() === "") {
      setSubjectEmailError(true);
      isErrorPresent = true;
    }

    if (isErrorPresent) {
      return false;
    }

    let formData = new FormData();
    formData.append("to", toEmail);
    formData.append("cc", ccEmail);
    formData.append("bcc", bccEmail);
    formData.append("subject", subjectEmail);
    formData.append('collection_id', collectionData.collection_id);
    formData.append("insights_comments", insightsAndComments)
    let mainBody = insightsAndComments;

    mainBody =
      renderToString(getHeaderHtml()) +
      mainBody +
      renderToString(getArticleListHtml()) +
      renderToString(getFooterHtml());

    formData.append("email_body", mainBody);
    postCollectionArticles(formData);
  };

  const isValidEmail = (emails: string) => {
    setToEmailError(false);
    const emailRegExp = new RegExp(
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
    const emailArray = emails.split(",");
    if (emails.length === 0) {
      return true;
    }
    const validEmails = emailArray.filter((indEmail) => {
      if (emailRegExp.test(indEmail) && indEmail.endsWith("@amgen.com")) {
        return true;
      }
      return false;
    });
    if (emailArray.length === validEmails.length) {
      return true;
    }
    return false;
  };
  const { authData } = useContext(AppContext);
  useEffect(() => {
    if (
      authData &&
      Object.keys(authData).length > 0 &&
      authData?.externalAuthorization?.newsCurator
    ) {
      getCollectionArticles();
    }
  }, []);

  return (

   
    <ExternalModal
     title={<b>{isEmailSent ? "Email Confirmation" : "Generate Email"} </b>}
     visible={show}
     closeEvent={() => {
      onHideModal();
      backToNews();
    }}
    icon={<CloseIcon height={20} width={20} className="close-icon" />}
    >
      <>
      <>
      {isArticlesLoading ? (
          <div className="loader-style ext-d-flex ext-justify-content-center">
            <Loader />
          </div>
        ) : (
          <div>
            <div>
              {!isEmailSent && !errorMessage && (
                <form className="ext-generate-email-modal-size" >
                  <div className="ext-row ops-my-2">
                    <div className="ext-col-12 ops-px-5">
                      <div className="form-group">
                        <label className="ops-px-1 ops-py-2 ext-col-12">
                          <text className="ext-text-generate-mail">
                            To <sup>*</sup>
                          </text>
                        </label>
                        <input
                          type="text"
                          className="form-control ext-mail-input-text ext-col-12"
                          placeholder="Type e-mail addresses separated by a comma"
                          onChange={(e) => {
                            setToEmail(e.target.value);
                            
                          }}
                          onBlur={(e) => {
                            setToEmailError(!isValidEmail(e.target.value));
                          }}
                          value={toEmail}
                        />
                        {toEmailError && (
                          <div className="ops-px-1 ops-py-1">
                            <small className="form-text validation-error">
                              {emailErrorMessage}
                            </small>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="ext-d-flex ext-row ops-my-4 ">
                    <div className="ext-col-sm-12 ext-col-lg-6 ops-px-5">
                      <div className="form-group">
                        <label className="ops-px-1 ops-py-2">
                          <text className="ext-text-generate-mail ext-col-12">CC</text>
                        </label>
                        <input
                          type="text"
                          className="hola form-control ext-mail-input-text ext-col-12"
                          placeholder="Type e-mail addresses separated by a comma"
                          onChange={(e) => {
                            setCCEmail(e.target.value);
                          }}
                          onBlur={(e) => {
                            if (e.target.value.trim()) {
                              setCCEmailError(!isValidEmail(e.target.value));
                            }
                            if (ccEmail.length === 0) {
                              setCCEmailError(false);
                            }
                          }}
                          value={ccEmail}
                        />
                        {ccEmailError && (
                          <div className=" ops-px-1 ops-py-1">
                            <small className="form-text validation-error">
                              {emailErrorMessage}
                            </small>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="ext-col-sm-12 ext-col-lg-6 ops-px-5">
                      <div className="form-group">
                        <label className="ops-px-1 ops-py-2 ext-col-12">
                          <text className="ext-text-generate-mail">BCC</text>
                        </label>
                        <input
                          type="text"
                          className="ext-mail-input-text form-control  ext-col-12"
                          placeholder="Type e-mail addresses separated by a comma"
                          onChange={(e) => {
                            if (e.target.value.trim() !== "") {
                              setSubjectEmailError(false);
                            }
                            setBCCEmail(e.target.value);
                          }}
                          onBlur={(e) => {
                            if (e.target.value.trim()) {
                              setBCCEmailError(!isValidEmail(e.target.value));
                            }
                            if (bccEmail.length === 0) {
                              setBCCEmailError(false);
                            }
                          }}
                          value={bccEmail}
                        />
                        {bccEmailError && (
                          <div className="ops-px-1 ops-py-1">
                            <small className="form-text validation-error">
                              {emailErrorMessage}
                            </small>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="ext-row ops-my-4">
                    <div className="ext-col-12 ops-px-5">
                      <div className="form-group">
                        <label className="ops-px-1 ops-py-2">
                          <text className="ext-text-generate-mail ext-col-12">
                            Subject <sup>*</sup>
                          </text>
                        </label>
                        <input
                          type="text"
                          className="form-control ext-mail-input-text ext-col-12"
                          placeholder="My Article Collection on Drug Pricing in the US"
                          onChange={(e) => {
                            if (e.target.value.trim() !== "") {
                              setSubjectEmailError(false);
                            }
                            setSubjectEmail(e.target.value);
                          }}
                          value={subjectEmail}
                        />
                        {subjectEmailError && (
                          <div className="ops-px-1 ops-py-1">
                            <small className="form-text validation-error">
                              Subject is required
                            </small>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="ext-row ops-my-4" >
                    <div className="ext-col-12 ops-px-5">
                      <div className="form-group">
                        <label className="ext-col-12 ops-px-1 ops-py-2 ">
                          <text className="ext-text-generate-mail">Insights and Comments</text>
                        </label>
                      <div className=" ext-rich-text" id="editor">
                          <CKEditor
                            editor={ClassicEditor}
                            onReady={(editor: any) => {}}
                            onChange={(event: any, editor: any) => {
                              const data = editor.getData();
                              setInsightsAndComments(data);
                            }}
                          />
                        </div>
                      </div>
                      </div>
                    </div>
                  <div className="ext-row ops-my-2">
                    <div className="ext-col-12 ops-px-5">
                      <div className="form-group">
                        <label className="ops-mt-1 ops-px-1 ops-mb-1 ops-py-2">
                          <text className="ext-text-generate-mail">
                            Articles Included
                          </text>
                        </label>
                        <div
                          ref={defaultContentRef}
                          className="ext-editor-arcile-list"
                        >
                          {getArticleListHtml()}
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              )}
              {!isEmailSent && errorMessage && (
                <div className="email-sent-popup ops-py-5 ext-d-flex ops-px-2 ops-my-2 title">
                  <text className="popup-message">{errorMessage}</text>
                </div>
              )}
              {isEmailSent && (
                <div className="email-sent-popup ops-py-2 ops-px-2 ops-my-2 title ext-d-flex">
                  <div className="tick-icon ops-mt-2">
                    <TickCircle
                      fill="#08ad56"
                      classname="ext-check-icon-success ext-vertical-align"
                    />
                  </div>
                  <text className="popup-message ops-mt-5">
                    An email with your selected articles has been sent to the
                    recipients you specified.
                  </text>
                </div>
              )}
            </div>
          </div>
        )}
      </>
      <>
      <div className="ext-d-flex ops-mt-3 ext-row ext-text-margin-left">
          {!isEmailSent && <div className=" ext-col-lg-7 ext-col-md-8 ext-col-sm-6 ext-text-styling">Please note that when distributing this email<b> all tags </b> (e.g., Amgen Ventures or KIQs which are confidential) shown in the articles you have chosen will be included.</div>}
          <div className=" ext-col-lg-5 ext-col-md-4 ext-col-sm-6  ops-mt-5">
          {isEmailSending && (
            <div>
              <button
                type="button"
                className=" ops-mb-1 btn btn-outline-primary btn-sm rounded-pill ext-secondary-button ext-custom-button ops-ms-2"
              >
                <div
                  className="ext-d-flex ext-justify-content-center"
                  role="status"
                >
                  ...Sending
                  <div className="spinner-border" role="status"></div>
                </div>
              </button>
            </div>
          )}
          {!isEmailSending && (
            <div>
              {!isEmailSent && !errorMessage && (
                <div>
                <Button
                label="Cancel"
                onClick={() => {
                  onHideModal();
                  backToNews();
                }}
                type="secondary"
              />
                <button
                  type="button"
                  className=" ops-mb-1 btn btn-outline-primary btn-sm rounded-pill ext-secondary-button ext-custom-button ops-ms-2"
                  onClick={() => {
                    sendEmail();
                  }}
                >
                  Send Email
                </button>
                </div>
              )}
              {!isEmailSent && errorMessage && (
                <button
                  type="button"
                  className=" ops-mb-1 btn btn-outline-primary btn-sm rounded-pill ext-secondary-button ext-custom-button ops-ms-2"
                  onClick={() => {
                    setIsEmailSent(false);
                    setErrorMessage("");
                  }}
                >
                  Try again!
                </button>
              )}
              {isEmailSent && (
                <button
                  type="button"
                  className=" ops-mb-0 btn btn-outline-primary btn-sm rounded-pill ext-secondary-button ext-custom-button ops-ms-2"
                  onClick={() => {
                    backToNews();
                    onHideModal();
                  }}
                >
                  Return to News Curator
                </button>
              )}
            </div>
          )}
          </div>
        </div>
      </>
      </>

    </ExternalModal>
  );
};

export default ModalGenerateEmail;
