import React from "react";
import LessThan from "../../assets/icons/less-than";
import "./index.scss";

type Props = {
  navigateFn: any;
  backBtnText: string;
};

const BackBtn: React.FC<Props> = (props: Props) => {
  return (
    <div className="ext-back-btn" onClick={props.navigateFn}>
      <div className="ext-nav-back-btn">
        <LessThan fill="white" classname="ext-nav-button-icon"></LessThan>
        {props.backBtnText}
      </div>
    </div>
  );
};

export default BackBtn;
