import React, { useState, useEffect, useContext, useRef } from "react";
import axios , { CancelTokenSource } from "axios";
import { Loader } from "@gitlab-rtsensing/component-library";
import "./index.scss";

// Components
import Card from "../../components/card";
import ArticleCard from "../../components/article-card-dscu";
import RequestPage from "../../components/request-access-page";
import CardFilter from "../../components/cards-filter";
import DateSelector from "../../components/date-picker";
import FilterDropdown from "../../components/filter-dropdown";
import { 
  Button,
  Title,
  Pagination,
  Collapse,
  Switch,
  Select,
  Paragraph,
} from '@opsdti-global-component-library/amgen-design-system'

// Utils
import { AppContext } from "../../utils/app-context";
import {
  apiResponse,
  lastApiResponse,
} from "../../utils/commonMethods";
import { 
  getFormattedTagListWithFilter,
  filterCountFn,
} from "../news-utils";
import {
  Tag,
  Article,
  NewsCurrentlyFilteredList,
  FilterApiResponseData
} from '../news-types'
import dayjs, { Dayjs } from "../../utils/dayjs";

// Consts
import {
  apiErrorMessage,
  noDataMessage,
} from "../../assets/errorMessageConstants";
import { SEARCH_TEXT, SWITCH_SUMMARIES_TEXT, SELECT_OPTIONS } from '../news-consts'
import ErrorMessage from "../../components/error-message";

// type ApocFilterApiResponseData = {
//   apoc_product: Array<Filter>
//   apoc_company: Array<Filter>
//   modality: Array<Filter>
//   disease_area: Array<Filter>
//   institution: Array<Filter>
//   target: Array<Filter>
//   phase: Array<string>
// }

const PAGE_LIMIT: number = 50;
const Oncology: React.FC = () => {
  const { authData } = useContext(AppContext);

  const [filterList, setFilterList] = useState<FilterApiResponseData>({
    apoc_product: [],
    apoc_company: [],
    modality: [],
    disease_area: [],
    institution: [],
    target: [],
    phase: []
  });
  const [isFilterListLoading, setIsFilterListLoading] =
  useState<boolean>(false);
  const [filterListError, setFiltersListError] = useState("");

  const [sortOrder, setSortOrder] = useState<string>("desc");
  const [page, setPage] = useState<number>(1);
  const [upperLimit, setUpperLimit] = useState<number>(0);
  const [lowerLimit, setLowerLimit] = useState<number>(0);

  const [newsList, setNewsList] = useState<Array<Article>>();
  const [newsListError, setNewsListError] = useState("");
  const [newsListLoading, setNewsListLoading] = useState<boolean>(false);

  const [newsCount, setNewsCount] = useState<number | null>(null);
  const [paginationDate, setPaginationDate] = useState<any>("");
  const [totalFilterCount, setTotalFilterCount] = useState<number>(0);

  const [endDate, setEndDate] = useState<Dayjs>(dayjs());
  const [startDate, setStartDate] = useState<Dayjs>(dayjs().subtract(89, 'days'));
  let cancelTokenSource = useRef<CancelTokenSource | null>(null);

  const [showArticleSummary, setShowArticleSummary] = useState(true);

  const [currentlyFilteredList, setCurrentlyFilteredList] = useState<NewsCurrentlyFilteredList>({
    apoc_product: [],
    apoc_company: [],
    modality: [],
    disease_area: [],
    institution: [],
    target: [],
    phase: []
  });

  const currentLimit =
    upperLimit < (newsCount || 0) ? upperLimit : newsCount;
  const todayDate: Dayjs = dayjs();

  const checkFilterBox = (value: {
    field: string;
    name: string;
    isChecked: boolean;
  }) => {
    if(value.field === 'PRODUCT'){
      value.field = 'apoc_product';
    }
    if(value.field === 'COMPANY'){
      value.field = 'apoc_company';
    }
    const currentlyFilteredListKey = value.field.toLowerCase() as keyof NewsCurrentlyFilteredList
    const filterObject: Array<string> = currentlyFilteredList[currentlyFilteredListKey];
    let updatedFilterObject: any[] = [];

    if (value.isChecked) {
      filterObject.push(value.name);
      updatedFilterObject = filterObject;
    } else {
      updatedFilterObject = filterObject.filter((item: any) => {
        if (item !== value.name) {
          return true;
        }
        return false;
      });
    }

    setCurrentlyFilteredList((prevState: any) => {
      prevState[currentlyFilteredListKey] = updatedFilterObject;
      return { ...prevState };
    });
    setPage(1);
  };

  const getFilterList = async () => {
    setIsFilterListLoading(true);
    const payload = {
      filters: [
        'apoc_product',
        'apoc_company',
        'modality',
        'disease_area',
        'institution',
        'target',
        'phase',
      ]
    }
    try {
      const res = await apiResponse("post", "get-filters", [], payload);
      if (res?.data.data) {
          setFilterList(res.data.data);
      }
      setIsFilterListLoading(false);
    } catch (error) {
      setIsFilterListLoading(false);
      setFiltersListError(apiErrorMessage);
    }
  };

  const getArticleList = async (
    page: number,
    startDateSelected: Dayjs,
    endDateSelected: Dayjs
  ) => {
    setNewsList([]);
    setNewsListLoading(true);
    setNewsListError("");

    const usersTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const startDateLocal = startDateSelected.locale('en-gb').tz(usersTimeZone).format("YYYY-MM-DD")
    const endDateLocal = endDateSelected.locale('en-gb').tz(usersTimeZone).format("YYYY-MM-DD");

    const payload = {
      sort_by_value: sortOrder,
      start_date: startDateLocal,
      end_date: endDateLocal,
      page_offset: page - 1,
      limit: PAGE_LIMIT,
      filters: {
        apoc_product: currentlyFilteredList.apoc_product,
        apoc_company: currentlyFilteredList.apoc_company,
        modality: currentlyFilteredList.modality,
        disease_area: currentlyFilteredList.disease_area,
        institution: currentlyFilteredList.institution,
        target: currentlyFilteredList.target,
        phase: currentlyFilteredList.phase,
      }
    };
    try {
      const res = await lastApiResponse("post", "get-apoc-news", [], payload, cancelTokenSource);
      if (res.data.status === "SUCCESS" && 
          (res.data.data.response && res.data.data.response.length !== 0) &&
          (res.data.data.count !== 0 && res.data.data.count !== null)) {
        setNewsList(res.data.data.response);
        setNewsListError("");
        setPaginationDate(res.data.data.ingested_date);
        setNewsCount(res.data.data.count);
        setLowerLimit((page - 1) * PAGE_LIMIT + 1);
        setUpperLimit(page * PAGE_LIMIT);
      } else {
        setNewsListError(noDataMessage);
      }
      setNewsListLoading(false);
    } catch (error) {
      if (!axios.isCancel(error)) {
        setNewsList([]);
        setNewsListLoading(false);
        setNewsListError(apiErrorMessage);
      }
    }
  };

  const onDateChange = (data: any) => {
    setPage(1)
    data.fieldName === "fromDate"
      ? setStartDate(data?.data)
      : setEndDate(data?.data);
  };

  useEffect(() => {
    if (
      authData &&
      Object.keys(authData)?.length > 0 &&
      authData?.externalAuthorization?.oncology
    ) {
      getArticleList(page, startDate, endDate);
      setTotalFilterCount(filterCountFn(currentlyFilteredList));
    }
  }, [
    sortOrder,
    page,
    currentlyFilteredList.apoc_product.length,
    currentlyFilteredList.apoc_company.length,
    currentlyFilteredList.modality.length,
    currentlyFilteredList.disease_area.length,
    currentlyFilteredList.institution.length,
    currentlyFilteredList.target.length,
    currentlyFilteredList.phase.length,
    startDate,
    endDate,
  ]);

  useEffect(() => {
    if (
      authData &&
      Object.keys(authData)?.length > 0 &&
      authData?.externalAuthorization?.oncology
    ) {
      getFilterList()
    }
  }, []);

  const resetFilter = () => {
    setCurrentlyFilteredList({  
      apoc_product: [],
      apoc_company: [],
      modality: [],
      disease_area: [],
      institution: [],
      target: [],
      phase: []
    });
    setPage(1);
    setStartDate(dayjs().subtract(89, 'days'));
    setEndDate(todayDate);
  };

  const formatFilterCount = (filterCount: number) => {
    if(filterCount === 0) return ""
    return `(${filterCount})`
  }

  return authData && authData?.externalAuthorization?.oncology ? (
    <div className="ext-oncology">
        <Title level={1}>Oncology</Title>
        <div className="ext-news-container">
          <div className="ext-news-left-panel">
            <CardFilter
              cardHeader="Filter Articles"
              totalFilterCount={totalFilterCount}
            >
              <div className="ext-filter-element-container">
                <DateSelector
                  selectedDate={startDate}
                  label="PUBLISHED BETWEEN"
                  verbageText="Select publish date"
                  minDate={dayjs("2022-01-03")}
                  maxDate={endDate}
                  onChange={(date: Dayjs) =>
                    onDateChange({
                      data: date,
                      fieldName: "fromDate",
                    })
                  }
                />
                <DateSelector
                  selectedDate={endDate}
                  label="AND"
                  minDate={startDate}
                  maxDate={todayDate}
                  onChange={(date) =>
                    onDateChange({ data: date, fieldName: "toDate" })
                  }
                />
                <div style={{minHeight: 24, width: '100%'}}/>
                <Collapse
                  defaultActiveKey={1}
                  items={[
                    {
                      children: <FilterDropdown
                        label="DISEASE_AREA"
                        options={filterList.disease_area}
                        isLoading={isFilterListLoading}
                        checkedOptions={currentlyFilteredList.disease_area}
                        onClickCheckbox={checkFilterBox}
                        error={filterListError}
                      />,
                      key: '1',
                      label: `Disease area ${formatFilterCount(currentlyFilteredList['disease_area'].length)}`,
                    },
                    {
                      children: <FilterDropdown
                        label="PRODUCT"
                        options={filterList.apoc_product}
                        isLoading={isFilterListLoading}
                        checkedOptions={currentlyFilteredList.apoc_product}
                        onClickCheckbox={checkFilterBox}
                        error={filterListError}
                      />,
                      key: '2',
                      label: `Product ${formatFilterCount(currentlyFilteredList['apoc_product'].length)}`,
                    },
                    {
                      children: <FilterDropdown
                        label="TARGET"
                        options={filterList.target}
                        isLoading={isFilterListLoading}
                        checkedOptions={currentlyFilteredList.target}
                        onClickCheckbox={checkFilterBox}
                        error={filterListError}
                      />,
                      key: '3',
                      label: `Target ${formatFilterCount(currentlyFilteredList['target'].length)}`,
                    },
                    {
                      children: <FilterDropdown
                        label="MODALITY"
                        options={filterList.modality}
                        isLoading={isFilterListLoading}
                        checkedOptions={currentlyFilteredList.modality}
                        onClickCheckbox={checkFilterBox}
                        error={filterListError}
                      />,
                      key: '4',
                      label: `Modality ${formatFilterCount(currentlyFilteredList['modality'].length)}`,
                    },
                    {
                      children: <FilterDropdown
                        label="PHASE"
                        options={filterList.phase}
                        isLoading={isFilterListLoading}
                        checkedOptions={currentlyFilteredList.phase}
                        onClickCheckbox={checkFilterBox}
                        error={filterListError}
                      />,
                      key: '5',
                      label: `Phase ${formatFilterCount(currentlyFilteredList['phase'].length)}`,
                    },
                    {
                      children: <FilterDropdown
                        label="APOC_COMPANY"
                        options={filterList.apoc_company}
                        isLoading={isFilterListLoading}
                        checkedOptions={currentlyFilteredList.apoc_company}
                        onClickCheckbox={checkFilterBox}
                        error={filterListError}
                      />,
                      key: '6',
                      label: `Company ${formatFilterCount(currentlyFilteredList['apoc_company'].length)}`,
                    },
                    {
                      children: <FilterDropdown
                        label="INSTITUTION"
                        options={filterList.institution}
                        isLoading={isFilterListLoading}
                        checkedOptions={currentlyFilteredList.institution}
                        onClickCheckbox={checkFilterBox}
                        error={filterListError}
                      />,
                      key: '7',
                      label: `APoC Institutions ${formatFilterCount(currentlyFilteredList['institution'].length)}`,
                    }
                  ]}
                />
              </div>
              <div className="ext-reset-filters">
                <Button
                  text="RESET FILTERS"
                  type="link"
                  onClick={() => resetFilter()}
                />
              </div>
            </CardFilter>
          </div>
          <div className="ext-news-right-panel">
            <Card cardHeader="Oncology Articles" newsCard={true}>
              <div className='ext-article-container'>
                <div className="ext-article-sorting-wrapper">
                    <Paragraph className="ext-article-card-note">
                      {SEARCH_TEXT}
                    </Paragraph>
                    <div className="ext-card-toggle-options">
                      <div className="ext-show-summary-parent">
                        <Switch
                          checked={showArticleSummary}
                          onChange={() => setShowArticleSummary(!showArticleSummary)}
                          inlineText={SWITCH_SUMMARIES_TEXT}
                        />                        
                      </div>
                      <Select
                        options={SELECT_OPTIONS}
                        defaultValue="desc"
                        onChange={(val) => setSortOrder(val)}
                      />
                  </div>
                </div>
                <div className="ext-article-list-wrapper">
                  {newsListLoading && (
                    <div className="ext-news-loader">
                      <Loader />
                    </div>
                  )}
                  {newsListError && (
                    <ErrorMessage isIcon={false} message={newsListError} />
                  )}
                  {!newsListLoading &&
                    !newsListError &&
                    newsList?.map((article: any, index: number) => {
                      const isEven = (index % 2 === 0)

                      const tags: Array<Tag> = getFormattedTagListWithFilter(
                        {
                          "disease_area": article.disease_area_names,
                          "institution": article.institution_names,
                          "modality": article.modality_names,
                          "apoc_product": article.apoc_product_names,
                          "apoc_company": article.apoc_company_names,
                          "target": article.target_names,
                          "phase": article.phase_names,
                        },
                        {
                          "apoc_company": article.apoc_full_company_names,
                        },
                        currentlyFilteredList,
                        checkFilterBox,
                      )

                      return (
                        <ArticleCard
                          key={index}
                          redirectUrl={article.url}
                          isEven={isEven}
                          title={article.title}
                          articleId={article.article_id}
                          publishDate={article.published}
                          publishSource={article.source_name}
                          tagList={tags}
                          checkboxFlag={false}
                          trashFlag={false}
                          summary={showArticleSummary ? article.article_summary : null}
                          ellipsisRequired={true}
                        />
                      );
                    })}
                </div>

                <div className="ext-article-pagination">
                  <div className="pagination-container">
                    {!newsListLoading && newsList?.length !== 0 &&
                      <div className="ext-article-pagination-wrapper">
                        <Paragraph>
                          Last update at {paginationDate?.replace("T", "T on")}
                        </Paragraph>
                        <div>
                          <Pagination
                            showSizeChanger={false}
                            showTotal={(total) => `${lowerLimit} - ${currentLimit} of ${newsCount} items`}
                            current={page}
                            total={newsCount || 0}
                            pageSize={PAGE_LIMIT}
                            onChange={(page: number) => setPage(page)}
                          />
                        </div>
                      </div>
                      }
                  </div>
                </div>
              </div>
            </Card>
          </div>
        </div>
    </div>
    ) : (
    <>{authData && <RequestPage />}</>
  );
};

export default Oncology;
