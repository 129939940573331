export const apiErrorMessage = "Error while fetching data, please try again";
export const noDataMessage = "No data available";
export const wordCloudNoDataMessage = "Earnings sentiment is still pending for this quarter."
export const atleastOneArticleMsg =
  "Please select at least one article from given articles";
export const noCollectionMsg =
  "There are no existing collection, please create a new one";
export const selectCollectionMsg =
  "Please select any collection from the given list";
export const emailErrorMessage =
  "Only Amgen ID/IDs are allowed (Emails should be comma separated)";
export const articlesMsg = "Articles for last 90 days are shown here";
export const toBeUpdated = "To be updated"
