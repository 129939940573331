interface Props {
  fill: string;
  classname: string;
}

const ChevronUpIcon = ({ fill, classname }: Props) => {
  return (
    <svg
      className={classname}
      width="9"
      height="6"
      viewBox="0 0 9 6"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.53816 3.77983L4.96316 0.246499C4.8857 0.168392 4.79353 0.106397 4.69198 0.0640893C4.59043 0.0217821 4.48151 0 4.3715 0C4.26149 0 4.15257 0.0217821 4.05102 0.0640893C3.94947 0.106397 3.8573 0.168392 3.77983 0.246499L0.246499 3.77983C0.168392 3.8573 0.106396 3.94947 0.0640889 4.05102C0.0217817 4.15257 0 4.26149 0 4.3715C0 4.48151 0.0217817 4.59043 0.0640889 4.69198C0.106396 4.79353 0.168392 4.8857 0.246499 4.96316C0.402634 5.11837 0.613844 5.20549 0.833998 5.20549C1.05415 5.20549 1.26536 5.11837 1.4215 4.96316L4.3715 2.01316L7.3215 4.96316C7.47672 5.11712 7.68621 5.20391 7.90483 5.20483C8.0145 5.20547 8.12322 5.18445 8.22475 5.14297C8.32628 5.1015 8.41863 5.0404 8.4965 4.96316C8.57739 4.88848 8.64269 4.79851 8.68861 4.69845C8.73453 4.59839 8.76017 4.49021 8.76405 4.38019C8.76792 4.27016 8.74995 4.16045 8.71118 4.0574C8.67241 3.95436 8.61361 3.86002 8.53816 3.77983Z"
        fill={fill}
      />
    </svg>
  );
};

export default ChevronUpIcon;
