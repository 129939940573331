import { Modal } from "@gitlab-rtsensing/component-library";
import "./index.scss";

interface Props {
  show: boolean;
  onHideDeleteModal: (modalState: boolean) => void;
  onDeleteClick: (id: string) => void;
  // articleId: any;
  articleId: string;
  articleName: string;
}

const DeleteArticleModal = ({
  show,
  onHideDeleteModal,
  onDeleteClick,
  articleId,
  articleName,
}: Props) => {
  return (
    <Modal
      opened={show}
      onClose={() => {
        onHideDeleteModal(!show);
      }}>
        <Modal.Header contentClassName="d-flex align-items-center">
          <Modal.Title title="Remove Article" />
        </Modal.Header>
      <>
      <div className="ext-modal-body-div">
          Are you sure you want to remove the Article <b>{articleName}</b> from
          this collection?{" "}
        </div>
      <hr />
      <div className="ext-footer-section ext-d-flex ext-ms-auto">
          <div>
            <button
              type="button"
              className="ext-cancel-button"
              onClick={() => {
                onHideDeleteModal(!show);
              }}
            >
              Cancel
            </button>
            <button
              type="button"
              className="ext-delete-button ops-ms-2 border-0"
              onClick={() => {
                onDeleteClick(articleId);
                onHideDeleteModal(!show);
              }}
            >
              Remove Article
            </button>
          </div>
        </div>
        </>
      </Modal>


    // <Modal
    //   show={show}
    //   onHide={() => {
    //     onHideDeleteModal(!show);
    //     // !show;
    //   }}
    //   // aria-labelledby="contained-modal-title-vcenter"
    //   centered
    //   dialogClassName="ext-modal"
    // >
    //   <Modal.Header closeButton>
    //     <div className="ext-title ext-fw-bold">Remove Article </div>
    //   </Modal.Header>
    //   <Modal.Body className="ops-mt-0 ops-pt-0 ext-body ">
    //     <div className="ext-modal-body-div">
    //       Are you sure you want to remove the Article <b>{articleName}</b> from
    //       this collection?{" "}
    //     </div>
    //   </Modal.Body>
    //   <Modal.Footer>
    //     <div className="ext-footer-section ext-d-flex ext-ms-auto">
    //       <div>
    //         <button
    //           type="button"
    //           className="ext-cancel-button"
    //           onClick={() => {
    //             onHideDeleteModal(!show);
    //           }}
    //         >
    //           Cancel
    //         </button>
    //         <button
    //           type="button"
    //           className="ext-delete-button ops-ms-2 border-0"
    //           onClick={() => {
    //             onDeleteClick(articleId);
    //             onHideDeleteModal(!show);
    //           }}
    //         >
    //           Remove Article
    //         </button>
    //       </div>
    //     </div>
    //   </Modal.Footer>
    // </Modal>
  );
};

export default DeleteArticleModal;
