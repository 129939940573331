import React, { useContext, useEffect, useState, useRef } from "react";
import axios, { CancelTokenSource } from "axios";
import { Loader } from "@gitlab-rtsensing/component-library";
import "../oncology/index.scss";

import { useLocation, useNavigate } from "react-router-dom";

// cutom imports
import Card from "../../components/card";
import ArticleCard from "../../components/article-card-dscu";
import RequestPage from "../../components/request-access-page";
import CardFilter from "../../components/cards-filter";
import DateSelector from "../../components/date-picker";
import FilterDropdown from "../../components/filter-dropdown";
import { 
  Button,
  Title,
  Pagination,
  Collapse,
  Switch,
  Select,
  Paragraph,
} from '@opsdti-global-component-library/amgen-design-system'

import { AppContext } from "../../utils/app-context";
import {
  apiResponse,
  lastApiResponse,
} from "../../utils/commonMethods";
import { getFormattedTagListWithFilter, filterCountFn } from '../news-utils'
import dayjs, { Dayjs } from "../../utils/dayjs";

import BackBtn from "../../components/back-btn";
import { BASE_ROUTE, getBackButtonText } from '../../utils/routing'

import {
  apiErrorMessage,
  noDataMessage,
} from "../../assets/errorMessageConstants";
import { SEARCH_TEXT, SWITCH_SUMMARIES_TEXT, SELECT_OPTIONS } from '../news-consts'
import ErrorMessage from "../../components/error-message";
import { FilterApiResponseData, Tag, Article, NewsCurrentlyFilteredList } from "../news-types";

type Props = {
  amgenCompanyList: any;
  isLoading: boolean;
  errorMessage: string;
};

const PAGE_LIMIT = 50;
const AmgenVenture: React.FC<Props> = (props: Props) => {
  const { authData } = useContext(AppContext);
  const navigate = useNavigate();
  const location: any = useLocation();

  const [filterList, setFilterList ] = useState<FilterApiResponseData>({
    av_company: [],
  })
  const [isFilterListLoading, setIsFilterListLoading] =
  useState<boolean>(false);
  const [filterListError, setFiltersListError] = useState("");

  const [sortOrder, setSortOrder] = useState<string>("desc");
  const [page, setPage] = useState<number>(1);
  const [upperLimit, setUpperLimit] = useState<number>(0);
  const [lowerLimit, setLowerLimit] = useState<number>(0);

  const [newsList, setNewsList] = useState<Array<Article>>();
  const [newsListError, setNewsListError] = useState("");
  const [newsListLoading, setNewsListLoading] = useState<boolean>(false);

  const [newsCount, setNewsCount] = useState<number | null>(null);
  const [paginationDate, setPaginationDate] = useState<any>("");
  const [totalFilterCount, setTotalFilterCount] = useState<number>(0);

  const [endDate, setEndDate] = useState<Dayjs>(dayjs());
  const [startDate, setStartDate] = useState<Dayjs>(dayjs().subtract(89, 'days'));
  let cancelTokenSource = useRef<CancelTokenSource | null>(null);

  const [showArticleSummary, setShowArticleSummary] = useState(true);

  const [showBackBtn, setShowBackBtn] = useState(false);
  const [backButtonRoute, setBackButtonRoute] = useState<any>(-1);

  const [currentlyFilteredList, setCurrentlyFilteredList] = useState<NewsCurrentlyFilteredList>({
    av_company: [],
  });
  
  const currentLimit =
    upperLimit < (newsCount || 0) ? upperLimit : newsCount;
  const todayDate: Dayjs = dayjs();

  const checkFilterBox = (value: {
    field: string;
    name: string;
    isChecked: boolean;
  }) => {
    const currentlyFilteredListKey = value.field.toLowerCase() as keyof NewsCurrentlyFilteredList
    const filterObject: Array<string> = currentlyFilteredList[currentlyFilteredListKey];
    let updatedFilterObject: any[] = [];

    if (value.isChecked) {
      filterObject.push(value.name);
      updatedFilterObject = filterObject;
    } else {
      updatedFilterObject = filterObject.filter((item: any) => {
        if (item !== value.name) {
          return true;
        }
        return false;
      });
    }

    setCurrentlyFilteredList((prevState: any) => {
      prevState[currentlyFilteredListKey] = updatedFilterObject;
      return { ...prevState };
    });
    setPage(1);
  };

  const getFilterList = async () => {
    setIsFilterListLoading(true);
    try {
      const res = await apiResponse("post", "get-filters", [], {filters: ['av_company']});
      if (res?.data.data) {
        setFilterList(res.data.data);
      }
      setIsFilterListLoading(false);
    } catch (error) {
      setIsFilterListLoading(false);
      setFiltersListError(apiErrorMessage);
    }
  };

  const getArticleList = async (pageSelected: number, startDateSelected: Dayjs, endDateSelected: Dayjs) => {
    setNewsList([]);
    setNewsListLoading(true);
    setNewsListError("");

    const usersTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const startDateLocal = startDateSelected.locale('en-gb').tz(usersTimeZone).format("YYYY-MM-DD")
    const endDateLocal = endDateSelected.locale('en-gb').tz(usersTimeZone).format("YYYY-MM-DD");
  
    let filteredOrAll: string[] = []
    if(currentlyFilteredList['av_company'].length === 0){
      filterList['av_company'].forEach((filter) => {
        filteredOrAll.push(filter.name)
      });
    } else {
      filteredOrAll = currentlyFilteredList.av_company
    }
    const payload = {
      sort_by_value: sortOrder,
      start_date: startDateLocal,
      end_date: endDateLocal,
      page_offset: pageSelected - 1,
      limit: PAGE_LIMIT,
      filters: {avCompany: filteredOrAll},
    };

    try {
      const res = await lastApiResponse("post", "get-news-with-tags", [], payload, cancelTokenSource);
      if (res.data.status === "SUCCESS" && 
        (res.data.data.response && res.data.data.response.length !== 0) &&
        (res.data.data.count !== 0 && res.data.data.count !== null)) {
          setNewsList(res.data.data.response);
          setNewsListError("");
          setPaginationDate(res.data.data.ingested_date);
          setNewsCount(res.data.data.count);
          setLowerLimit((page - 1) * PAGE_LIMIT + 1);
          setUpperLimit(page * PAGE_LIMIT);
      } else {
        setNewsListError(noDataMessage);
      }
      setNewsListLoading(false);
    } catch (error) {
      if (!axios.isCancel(error)) {
        setNewsList([]);
        setNewsListLoading(false);
        setNewsListError(apiErrorMessage);
      }
    }
  };

  const onDateChange = (data: any) => {
    setPage(1)
    data.fieldName === "fromDate"
      ? setStartDate(data?.data)
      : setEndDate(data?.data);
  };

  useEffect(() => {
    if (
      authData &&
      Object.keys(authData)?.length > 0 &&
      authData?.externalAuthorization?.avCompany
    ) {
      getArticleList(page, startDate, endDate);
      setTotalFilterCount(filterCountFn(currentlyFilteredList));
    }
  }, [
    sortOrder,
    page,
    currentlyFilteredList,
    startDate,
    endDate,
    filterList
  ]);

  useEffect(() => {
    if (
      authData &&
      Object.keys(authData)?.length > 0 &&
      authData?.externalAuthorization?.avCompany
    ) {
      if (location.state) {
        showBtn();
      } else {
        setBackButtonRoute(`${BASE_ROUTE}/amgen-ventures`);
      }

      getFilterList()
    }
  }, [authData]);

  const resetFilter = () => {
    setCurrentlyFilteredList({  
      av_company: [],
    });
    setPage(1);
    setStartDate(dayjs().subtract(89, 'days'));
    setEndDate(todayDate);
  };

  const formatFilterCount = (filterCount: number) => {
    if(filterCount === 0) return ""
    return `(${filterCount})`
  }

  const showBtn = () => {
    const pathName = location.state.callingPath
      ? location.state.callingPath.pathname
      : "";

    setShowBackBtn(!pathName.includes(`${BASE_ROUTE}/amgen-ventures`));
  };

  // useEffect(() => {
  //   if (
  //     authData &&
  //     Object.keys(authData).length > 0 &&
  //     authData?.externalAuthorization?.avCompany &&
  //     props.amgenCompanyList.length > 0
  //   ) {
  //     setPage(1);

  //     const companyNames = props.amgenCompanyList;
  //     setCompanyList(companyNames);
  //     const firstCompanyCode = companyNames[0];

  //     const params = window.location.pathname.split("amgen-ventures/");
  //     const urlCompanyName = params[1];
  //     setSelectedCompanyName(urlCompanyName || firstCompanyCode.name);
  //     getAvDetails(1, urlCompanyName || firstCompanyCode.name);
  //   }
  // }, [location.state]);

  return authData && authData?.externalAuthorization?.avCompany ? (
    <div className="ext-oncology">
      <Title level={1}>Amgen Ventures</Title>
      {showBackBtn && (
        <div className="ext-topic-page-navigation mb-2">
          <BackBtn
            navigateFn={() => navigate(backButtonRoute)}
            backBtnText={getBackButtonText(location)}
          ></BackBtn>
        </div>
      )}
      <div className="ext-news-container">
        <div className="ext-news-left-panel">
          <CardFilter
            cardHeader="Filter Articles"
            totalFilterCount={totalFilterCount}
          >
            <div className="ext-filter-element-container">
              <DateSelector
                selectedDate={startDate}
                label="PUBLISHED BETWEEN"
                verbageText="Select publish date"
                minDate={dayjs("2022-01-03")}
                maxDate={endDate}
                onChange={(date: Dayjs) =>
                  onDateChange({
                    data: date,
                    fieldName: "fromDate",
                  })
                }
              />
              <DateSelector
                selectedDate={endDate}
                label="AND"
                minDate={startDate}
                maxDate={todayDate}
                onChange={(date) =>
                  onDateChange({ data: date, fieldName: "toDate" })
                }
              />
              <div style={{minHeight: 24, width: '100%'}}/>
              <Collapse
                defaultActiveKey={1}
                items={[
                  {
                    children: <FilterDropdown
                      label="av_company"
                      options={filterList.av_company}
                      isLoading={isFilterListLoading}
                      checkedOptions={currentlyFilteredList.av_company}
                      onClickCheckbox={checkFilterBox}
                      error={filterListError}
                    />,
                    key: '1',
                    label: `Portfolio Companies ${formatFilterCount(currentlyFilteredList['av_company'].length)}`,
                  },
                ]}
              />
              </div>
              <div className="ext-reset-filters">
                <Button
                  text="RESET FILTERS"
                  type="link"
                  onClick={() => resetFilter()}
                />
              </div>
          </CardFilter>
        </div>
        <div className="ext-news-right-panel">
          <Card
            cardHeader={`Amgen Ventures Articles`}
            newsCard={true}
          >
            <div className="ext-article-container">
              <div className="ext-article-sorting-wrapper">
                <Paragraph className="ext-article-card-note">
                  {SEARCH_TEXT}
                </Paragraph>
                <div className="ext-card-toggle-options">
                  <div className="ext-show-summary-parent">
                    <Switch
                      checked={showArticleSummary}
                      onChange={() => setShowArticleSummary(!showArticleSummary)}
                      inlineText={SWITCH_SUMMARIES_TEXT}
                    />                        
                  </div>
                  <Select
                    options={SELECT_OPTIONS}
                    defaultValue="desc"
                    onChange={(val) => setSortOrder(val)}
                  />
                  </div>
              </div>
              <div className="ext-article-list-wrapper">
                {newsListLoading && (
                    <div className="ext-news-loader">
                      <Loader />
                    </div>
                  )}
                  {newsListError && (
                    <ErrorMessage isIcon={false} message={newsListError} />
                )}
                {!newsListLoading &&
                  !newsListError &&
                  newsList?.map((article: any, index: any) => {
                    const isEven =
                      index % 2 === 0

                    const tags: Array<Tag> = getFormattedTagListWithFilter(
                      {
                        "av_company": article.av_company_names,
                      },
                      {},
                      currentlyFilteredList,
                      checkFilterBox
                    )
                    return (
                      <ArticleCard
                        key={index}
                        redirectUrl={article.url}
                        isEven={isEven}
                        title={article.title}
                        articleId={article.article_id}
                        publishDate={article.published}
                        publishSource={article.source_name}
                        tagList={tags}
                        checkboxFlag={false}
                        trashFlag={false}
                        summary={showArticleSummary ? article.article_summary : null}
                        ellipsisRequired={true}
                      />
                    );
                  })}
              </div>

              <div className="ext-article-pagination">
                  <div className="pagination-container">
                    {!newsListLoading && !newsListError &&
                      <div className="ext-article-pagination-wrapper">
                        <Paragraph>
                          Last update at {paginationDate?.replace("T", "T on")}
                        </Paragraph>
                        <div>
                          <Pagination
                            showSizeChanger={false}
                            showTotal={(total) => `${lowerLimit} - ${currentLimit} of ${newsCount} items`}
                            current={page}
                            total={newsCount || 0}
                            pageSize={PAGE_LIMIT}
                            onChange={(page: number) => setPage(page)}
                          />
                        </div>
                      </div>
                      }
                  </div>
                </div>
            </div>
          </Card>
        </div>
      </div>
    </div>
  ) : (
    <>{authData && <RequestPage />}</>
  );
};

export default AmgenVenture;
