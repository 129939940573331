import React from "react";
import Tag from "../tag";
import parse from 'html-react-parser';
import "./index.scss";
import Calender from "../../assets/icons/calender";
import { formatDate } from "../../utils/commonMethods";
import Trash from "../../assets/icons/trash-can";

type ArticleProps = {
  specialClassName?: string;
  redirectUrl?: string;
  title?: string;
  articleId?: string;
  publishDate?: string;
  publishSource?: string;
  tagList?: any[];
  summary?: string;
  ellipsisRequired?: boolean;
  checkboxFlag: boolean;
  isChecked?: boolean;
  trashFlag: boolean;
  onCheckboxClicked?: (checkedArticle: {
    isChecked: boolean;
    articleId: string | undefined; // We should fix type in main file so we do not need to add undefined here
    articleName: string | undefined;
  }) => void;
  handleDelete?: (selectedData: any, title?: string) => void;
};

const ArticleCard: React.FC<ArticleProps> = (props: ArticleProps) => {
  const {
    specialClassName,
    redirectUrl,
    title,
    publishDate,
    publishSource,
    checkboxFlag,
    isChecked,
    trashFlag,
    articleId,
    tagList,
    summary,
    ellipsisRequired,
    onCheckboxClicked,
    handleDelete,
  } = props;

  const handleTopicClick = (articleId: any) => {
    if (props.redirectUrl === "" || props.redirectUrl === null) {
      let params = window.location.href.split("intelligence/");
      let finalUrl: string;
      let trendingDowJones = window.location.pathname.split("intelligence/");

      if (trendingDowJones[1] == null) {
        finalUrl = params[0] + "/news/" + articleId + ".html";
        window.open(finalUrl);
        return;
      }

      finalUrl = params[0] + "intelligence/news/" + articleId + ".html";
      window.open(finalUrl);

      return;
    }
    let finalUrlLink=redirectUrl?.replace(/^http:\/\//i, "https://");
    window.open(finalUrlLink);
  };

  const getCapitalizeData = (str: any) => {
    return str && str.charAt(0).toUpperCase() + str.slice(1);
  };

  const getmodifiedContent = (data: string) => {

    const regex = /(- |\n- )/g
    const finalData = data && data.replace(regex, ' ');
    // const finalData = data && data.replace(new RegExp('\r?<n>','g'), '<br>');
    return <div className={ellipsisRequired ? "ext-article-card-summary" : "ext-article-card-content-summary"}>{parse(finalData)}</div>
  }

  return (
    <div className={`ext-article-card ${specialClassName}`}>
      <div className="article-details ext-d-flex ext-border-top-0">
        {checkboxFlag ? (
          <div className="ext-article-checkbox">
            <input
              className="form-check-input ext-article-card-checkbox ops-mt-2"
              type="checkbox"
              value=""
              checked={isChecked}
              onChange={(event) =>
                onCheckboxClicked?.({
                  isChecked: event.target.checked,
                  articleId: articleId,
                  articleName: title,
                })
              }
              id="flexCheckDefault"
            ></input>
          </div>
        ) : null}
        <div className="ext-flex-grow-1">
          <div
            onClick={() => handleTopicClick(articleId)}
            className="ext-article-title ext-amgen-color"
          >
            {title}
          </div>
          <div className="ext-article-info">
            <Calender fill="none" classname="ext-date-icon" />
            <div className="ext-article-publish-date ops-ms-2">
              {formatDate(publishDate)}
            </div>
            <div className="ext-article-info-divider" />
            <div className="ext-article-source-name">
              {getCapitalizeData(publishSource)}
            </div>
          </div>
          <div>
          {summary && getmodifiedContent(summary && summary)}
          </div>
          <div className="ext-article-tags">
            <>
              {tagList != undefined && tagList.map((item: any, index: any) => {
                return (
                <Tag
                  code={item.ticker}
                  key={index}
                  name={item.name}
                  type={item.type}
                  canClick={item.canClick}
                  onClick={item.onClick}
                  canFilter={item.canFilter}
                  onFilter={item.onFilter}
                  isFilteredOnTag={item.isFilteredOnTag}
                  showMinusIcon={false}
                  showAmgenBlueColor={false}
                />
                )})}
            </>
          </div>
        </div>
        {trashFlag ? (
          <div
            className="ext-trash-bin"
            onClick={() => handleDelete?.(articleId, title)}
          >
            <Trash fill="#797979" classname="ext-tag-icon" />
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default ArticleCard;
