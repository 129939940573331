interface Props {
  fill: string;
  classname?: string;
}
const Capsule = ({ fill, classname }: Props) => {
  return (
    <svg
      className={classname}
      xmlns="http://www.w3.org/2000/svg"
      width="15"
      height="15"
      fill="currentColor"
      viewBox="0 0 15 15"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.58666 0.000549316C10.4918 0.000549316 11.3599 0.360067 12 1.00003C12.64 1.64014 12.9995 2.50822 12.9995 3.41337C12.9995 4.31851 12.64 5.1866 12 5.8267L6.82666 11C6.51145 11.3246 6.13485 11.5833 5.71877 11.761C5.30269 11.9387 4.85543 12.0319 4.403 12.0352C3.95057 12.0385 3.50199 11.9519 3.08336 11.7802C2.66473 11.6086 2.28439 11.3555 1.96446 11.0356C1.64454 10.7156 1.39141 10.3353 1.21979 9.91667C1.04818 9.49804 0.961506 9.04946 0.964813 8.59703C0.968119 8.1446 1.06134 7.69734 1.23905 7.28126C1.41677 6.86518 1.67543 6.48858 2 6.17337L7.17333 1.00003C7.81343 0.360067 8.68152 0.000549316 9.58666 0.000549316ZM8.94 7.00003L11.06 4.88003C11.4291 4.48602 11.6306 3.96391 11.6218 3.42408C11.613 2.88424 11.3946 2.36898 11.0128 1.98721C10.631 1.60544 10.1158 1.38707 9.57596 1.37827C9.03612 1.36946 8.51401 1.57091 8.12 1.94003L6 4.06003L8.94 7.00003Z"
        fill={fill}
      />
    </svg>
  );
};

export default Capsule;
