import React from "react";
import "./index.scss";

const RequestPage: React.FC = (props: any) => {
  return (
    <div className="ext-request-container">
      <div className="ext-request-content">
        The page you are trying to view has restricted access. Please contact
        the Real-Time Sensing Team to submit an access request.
      </div>
    </div>
  );
};

export default RequestPage;
