import React from "react";
import "./index.scss";
import CompaniesLogo from "../../assets/images/CompaniesLogo";
import Topics from "../../assets/icons/topic";
import Location from "../../assets/icons/location";
import Kiq from "../../assets/icons/kiq";
import Capsule from "../../assets/icons/capsule";
import { formatKiqName } from "../../utils/commonMethods";
import PlusIcon from "../../assets/icons/plus-icon";
import MinusCircleIcon from "../../assets/icons/minus-circle-icon";
import ExperimentalLogo from "../../assets/images/Experimental";
import MinusIcon from "../../assets/icons/minus";

type TagsProps = {
  name: any;
  code?: string; //unused
  key?: string;  //unused
  type: string;
  canClick: boolean;
  canFilter: boolean;
  isFilteredOnTag: boolean;
  onClick: () => void;
  onFilter: () => void;
  showMinusIcon: boolean;
  showAmgenBlueColor: boolean;
};

const Tag: React.FC<TagsProps> = (props: TagsProps) => {
  const {
    name,
    type,
    canClick,
    canFilter,
    isFilteredOnTag,
    onClick,
    onFilter,
    showMinusIcon,
    showAmgenBlueColor
  } = props;

  const selectedTagClass = isFilteredOnTag
    ? "tag-selected"
    : "tag-not-selected";
  
  return (
    <div className={`ext-tag ${selectedTagClass}`}>
      <div
        className={`ext-tag-name ${isFilteredOnTag && 'tag-selected-colored'}`}
        onClick={() => {
          if (canClick) {
            onClick();
          }
        }}
      >
        {(type === "company" || type === "institution") && (
          <CompaniesLogo fill="#797979" classname="ext-tag-icon ops-mb-1" />
        )}
        {(type === "topic" || type === "modality" || type === "disease_area" || type === "target" || type === "corexTopic" || type === "phase") && (
          <Topics fill="none" classname="ext-tag-icon ops-p-0" />
        )}
        {type === "experiment"  && (
          <ExperimentalLogo fill="#555555" classname="ext-tag-icon ops-p-0" />
        )}
        {(type === "product" || type === "apoc_product") && (
          <Capsule fill="#797979" classname="ext-tag-icon" />
        )}
        {type === "location" && (
          <Location fill="#797970" classname="ext-tag-icon ops-mb-1" />
        )}
        {(type === "ventureCompany" || type === "av_company") && (
          <CompaniesLogo fill="#797970" classname="ext-tag-icon mb-1" />
        )}
        {type === "kiq" && <Kiq fill="#797970" classname="ext-tag-icon" />}
        {type === "kiq" ? (
          <span className="ops-mx-1">{formatKiqName(name)}</span>
        ) : (
          <span className="ops-mx-1">{name == 'Lumakras' || name == 'Tezspire' ? name.toUpperCase() : name}</span>
        )}
      </div>
      {canFilter && (
        <div
          className={`ext-tag-action ${isFilteredOnTag && showAmgenBlueColor && showMinusIcon  && 'ext-amgen-bg-color'} `}
          onClick={() => {
            onFilter();
          }}
        >
          {!isFilteredOnTag && (
            <span className="ops-px-2">
              <PlusIcon classname="ext-vertical-align" fill="#757575" />
            </span>
          )}
          {isFilteredOnTag &&  !showMinusIcon && (
            <span className="ops-px-1">
              <MinusCircleIcon classname="ext-vertical-align" />
            </span>
          )}
           {isFilteredOnTag && showMinusIcon && (
            <span className="ops-px-1">
              <MinusIcon classname="ext-vertical-align" />
            </span>
          )}
        </div>
      )}
    </div>
  );
};

export default Tag;
