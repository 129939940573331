import './index.scss';
import { useState, useEffect, useMemo } from 'react'
import { useNavigate } from "react-router-dom";
import { BASE_ROUTE } from "../../../../utils/routing";
import { Button, Card } from '@opsdti-global-component-library/amgen-design-system';
import { NewsArticle, NewsTabs, NewsTab } from '../../consts';
import { NewsList } from './news-list';
import axios from "axios";
import { getApiUrl } from "../../../../api/api-config";
import { getOktaToken } from "../../../../utils/commonMethods";

import { apiErrorMessage, noDataMessage } from '../../../../assets/errorMessageConstants';

type Props = {
  title: string;
  tabs: NewsTabs;
}


export const GPTNews = ({title, tabs}: Props) => {
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState<NewsTab | undefined>(tabs[0]);
  const [newsList, setNewsList] = useState<NewsArticle[]>([]);
  const [isNewsListLoading, setIsNewsListLoading] = useState<boolean>(false);
  const [isNewsListError, setIsNewsListError] = useState<string>('');
  const oktaToken = getOktaToken();

  const tabsWithChildren = useMemo(() => {
    return tabs.map((tab, index) => {
      return {
        ...tab,
        children: <NewsList key={index} news={newsList} isNewsError={isNewsListError} isNewsLoading={isNewsListLoading} />
      }
    })
  }, [newsList,isNewsListLoading])

  const getNews = async () => {
      try {
        const endpoint = activeTab?.key === 'Oncology' ? 'get-homepage-apoc-news' : 'get-homepage-news-with-tags';
        const queryParams = activeTab?.key !== 'Oncology' ? `?filter_name=${activeTab?.filterName}`:"";
        const res = await axios({
          method: "get",
          url: `${getApiUrl(endpoint)}${queryParams}`,
          headers: {
              Authorization: `Bearer ${oktaToken}`,
          },
      });
        if (res.data.status === "SUCCESS" && res.data.data.response && res.data.data.count !== 0) {
          setNewsList(res.data.data.response);
        } else {
          setIsNewsListError(noDataMessage)
          setNewsList([]);
        }
        setIsNewsListLoading(false);
      } catch (error) {
        console.log('error fetching news for', error, activeTab?.filters)
        setNewsList([]);
        setIsNewsListError(apiErrorMessage);
        setIsNewsListLoading(false);
      }
  }

  useEffect(() => {
    if(activeTab?.filters){
      setIsNewsListLoading(true)
      getNews();
    }
  }, [activeTab])

  return (
      <div className="ext-gpt-news">
        <Card 
          divider={false}
          title={{
            aiIndicator: 'generated',
            title: title
          }}
          tabList={tabsWithChildren} 
          defaultActiveTabKey={activeTab ? activeTab.key : tabs[0].key}
          onTabChange={(key: string) => setActiveTab(tabs.find(o => o.key === key))}
          footer={
            <div className='gpt-news-view-more-btn-container'>
              <Button
              text="View More News Articles"
              type="secondary"
              className='gpt-news-view-more-btn'
              onClick={() => {
                navigate(`${BASE_ROUTE}/news-search`);
              }}
            />
            </div>
          }
          />
    </div>
  );
};
